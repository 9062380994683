import moment from "moment";
import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Grid, Typography } from "@mui/material";
import { getLeaveObject } from "../../utils";
import Button from "../Button";

const LeavesCalendar = ({ eventsList, handleEventClick }) => {
  const localizer = momentLocalizer(moment);

  const renderTitleDetails = (data) => {
    return (
      <Grid
        sx={{ backgroundColor: getLeaveObject(data.leaveType).backgroundColor }}
      >
        <Typography color={"white"}>{data.title}</Typography>
      </Grid>
    );
  };

  const CustomToolbar = (toolbar) => {
    const date = moment(toolbar.date);
    let currentDate = new Date(date);
    let nextDate = new Date(date);
    let prevDate = new Date(date);
    prevDate.setMonth(currentDate.getMonth() - 1);
    nextDate.setMonth(currentDate.getMonth() + 1);

    const goToBack = () => {
      toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
      toolbar.onNavigate("NEXT");
    };

    const label = () => {
      return (
        <Typography>
          {date.format("MMMM")} {date.format("YYYY")}
        </Typography>
      );
    };

    return (
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Button onClick={goToBack} variant="text">
          {/* <i>{ICONS.LeftArrow}</i> */}
          <Typography>{moment(prevDate).format("MMMM")}</Typography>
        </Button>
        {label()}
        <Button onClick={goToNext} variant="text">
          <Typography>{moment(nextDate).format("MMMM")}</Typography>
          {/* <i>{ICONS.RightArrow}</i> */}
        </Button>
      </Grid>
    );
  };

  return (
    <Calendar
      localizer={localizer}
      events={eventsList}
      startAccessor="start"
      endAccessor="end"
      style={{
        height: "90vh",
        padding: "10px 0px",
        background: "#F8F8F8",
      }}
      views={["month"]}
      defaultView={"month"}
      components={{
        toolbar: CustomToolbar,
      }}
      titleAccessor={(data) => renderTitleDetails(data)}
      tooltipAccessor={(e) => { }}
      popup={true}
      step={7.5}
      selectable={false}
      onSelectEvent={handleEventClick}
    />
  );
};

export default LeavesCalendar;
