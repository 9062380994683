/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SIDEBAR_SETTINGS from "../../constants/side-bar-settings";
import { useLocation, useNavigate } from "react-router-dom";
import ICONS from "../../constants/icons";
import URLS from "../../routes/urls";

const SideBar = ({
  isOpen,
  currentUser,
  setMenuOpen,
  isToggle,
  setIsApply,
  isHideSideBar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState("");

  useEffect(() => {
    setOpen(handleCheckSelectedPath());
    return () => {
      setOpen("");
    };
  }, []);

  const handleClick = (type) => {
    if (type === open) {
      setOpen("");
    } else {
      setOpen(type);
    }
    // setMenuOpen(false);
  };

  const handleCheckSelectedPath = () => {
    let isRequestOpen = [URLS.Leaves, URLS.Inventory, URLS.WorkFromHome];
    let isAdministratorOpen = [
      URLS.AdministratorOrganisation,
      URLS.AdministratorHolidays,
      URLS.AdministratorLeaves,
      URLS.AdministratorRoles,
      URLS.AdministratorYears,
      URLS.AdministratorHolidaysCreate,
      URLS.AdministratorActivity,
      URLS.AdministratorCreateActivity,
      URLS.AdministratorUpdateActivity
    ];
    if (isRequestOpen.some(data => location.pathname.toLowerCase()?.includes(data))) {
      return "Request";
    } else if (isAdministratorOpen.some(data => location.pathname.toLowerCase()?.includes(data))) {
      return "Settings";
    } else {
      return "";
    }
  };

  let isAdministrator = ['hr', 'administrator'].includes(currentUser?.user_role?.toLowerCase());
  let updatedSideBarSettings = isAdministrator
    ? SIDEBAR_SETTINGS
    : SIDEBAR_SETTINGS?.filter((value) => !["Settings", "Users", "Reports"].includes(value?.name));

  const handleClickSideItem = (href) => {
    navigate(href);
    setIsApply(false);
    if (isToggle) {
      setMenuOpen(false);
    }
    setTimeout(() => {
      setIsApply(true);
    }, 2000);
  };

  return (
    <Box
      className={`sidebar ${isHideSideBar ? "sidebar-hide" : ""}`}
      sx={{
        // width: 256,
        // minWidth: 256,
        bgcolor: "background.paper",
        transition: "all 0.25s ease-in-out",
        borderRight: "1px solid",
        borderColor: "border.main",
        overflowY: "auto",
        width: { lg: isOpen ? 256 : 60, xs: isOpen ? 256 : 256 },
        // minWidth: (isOpen ? 256 : 60)
        position: { lg: "absolute" },
        zIndex: { lg: 100 },
        background: "#ffffff",
        height: "100%",
        overflow: "auto",
        // overflow: { lg: "hidden" },

        ".sidebar-hide&:hover": {
          width: 256,
          boxShadow: isOpen ? "" : "10px 0px 20px rgba(0,0,0,0.25)",
          ".menu-text": {
            display: "block !important",
          },
          ".submenu-item-box": {
            display: "block !important",
          },
        },
      }}
    >
      {/* width: (isOpen ? 256 : 50) */}
      <nav aria-label="main mailbox folders">
        <List sx={{ padding: isOpen ? "16px" : "16px 10px" }}>
          {updatedSideBarSettings?.map((listItem, index) =>
            (isAdministrator
              ? ["Settings", "Request"]
              : ["Request"]
            ).includes(listItem.name) ? (
              <React.Fragment key={index}>
                <Grid mb={1}>
                  <ListItemButton
                    onClick={() => handleClick(listItem.name)}
                    className={
                      handleCheckSelectedPath() === listItem.name
                        ? "active"
                        : ""
                    }
                    sx={[
                      handleCheckSelectedPath() === listItem.name
                        ? {
                          bgcolor: "primary.main",
                          color: "white",
                          "&:hover": {
                            bgcolor: "primary.main",
                            color: "white",
                          },
                        }
                        : { color: "secondary.main" },
                      {
                        borderRadius: "4px",
                        padding: isOpen ? "4px 12px 5px" : "4px 10px 5px",
                        transition: "all 0.25s ease-in-out",
                        height: "38px",
                        ".MuiListItemText-primary": {
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                      },
                    ]}
                  >
                    <ListItemIcon
                      sx={{
                        marginRight: "11px",
                        maxWidth: "18px",
                        height: "18px",
                        minWidth: "18px",
                        color: "inherit",
                      }}
                    >
                      {listItem.icon}
                    </ListItemIcon>
                    {/* {isOpen ? <ListItemText primary={listItem.name} /> : null } */}
                    <ListItemText
                      className="menu-text"
                      primary={listItem.name}
                      sx={{
                        display: { lg: isOpen ? "block" : "none" },
                        whiteSpace: "nowrap",
                      }}
                    />
                    <i
                      style={
                        open === listItem.name
                          ? { transform: "rotate(90deg)" }
                          : {}
                      }
                    >
                      {/* {open ? ICONS.DownArrow : ICONS.RightArrow} */}
                      {ICONS.RightArrow}
                    </i>
                  </ListItemButton>
                  <Collapse
                    in={open === listItem.name}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      padding: "8px 0px 8px 26px",
                      display: { lg: isOpen ? "block" : "none" },
                    }}
                    className="submenu-item-box"
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        "> div:not(:last-child)": {
                          marginBottom: "3px",
                        },
                      }}
                    >
                      {listItem.subMenu.map((subItem, index) => (
                        <>
                          {((subItem?.name !== "Activity" && (currentUser?.user_role?.toLowerCase() !== 'administrator')) || currentUser?.user_role?.toLowerCase() === 'administrator') ?
                            <ListItemButton
                              key={index}
                              // onClick={() => {navigate(subItem.href); setMenuOpen(false)}}
                              onClick={() => handleClickSideItem(subItem.href)}
                              className={
                                location?.pathname?.toLowerCase().includes(subItem.href)
                                  ? "active"
                                  : ""
                              }
                              sx={[
                                location?.pathname?.toLowerCase().includes(subItem.href)
                                  ? {
                                    color: "primary.main",
                                    "&:hover": { color: "primary.main" },
                                  }
                                  : { color: "secondary.main" },
                                {
                                  borderRadius: "4px",
                                  padding: "2px 15px",
                                  ".MuiListItemText-primary": {
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  },
                                },
                              ]}
                            >
                              {/* {isOpen ? <ListItemText primary={subItem.name} /> : null} */}
                              <ListItemText
                                className="menu-text"
                                primary={subItem.name}
                              />
                            </ListItemButton> : null}
                        </>
                      ))}
                    </List>
                  </Collapse>
                </Grid>
              </React.Fragment>
            ) : (
              <ListItem key={index} disablePadding sx={{ marginBottom: "8px" }}>
                <ListItemButton
                  // onClick={() => {navigate(listItem.href); setMenuOpen(false)}}
                  onClick={() => handleClickSideItem(listItem.href)}
                  className={
                    listItem?.href?.startsWith(location?.pathname?.split("/")[1], 1)
                      ? "active"
                      : ""
                  }
                  sx={[
                    listItem?.href?.startsWith(location?.pathname?.split("/")[1], 1)
                      ? {
                        bgcolor: "primary.main",
                        color: "white",
                        "&:hover": {
                          bgcolor: "primary.main",
                          color: "white",
                        },
                      }
                      : { color: "secondary.main" },
                    {
                      borderRadius: "4px",
                      padding: isOpen ? "4px 12px 5px" : "4px 10px 5px",
                      height: "38px",
                      transition: "all 0.25s ease-in-out",
                      ".MuiListItemText-primary": {
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                    },
                  ]}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: "11px",
                      maxWidth: "18px",
                      height: "18px",
                      minWidth: "18px",
                      color: "inherit",
                    }}
                  >
                    {listItem.icon}
                  </ListItemIcon>
                  {/* {isOpen ? <ListItemText primary={listItem.name} /> : null} */}
                  <ListItemText
                    className="menu-text"
                    primary={listItem.name}
                    sx={{
                      display: { lg: isOpen ? "block" : "none" },
                      whiteSpace: "nowrap",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </nav>
    </Box>
  );
};

export default SideBar;
