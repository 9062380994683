import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import "./App.css";
import useAuthentication from "./hook/useAuthentication";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Dashboard from "./pages/Client/Dashboard";
import URLS from "./routes/urls";
import AddOrganisation from "./pages/Admin/AddOrganisation";
import OrganisationWrapper from "./pages/Admin/OrganisationWrapper";
import OnBoardingStep from "./pages/Client/OnBoardingStep";
import OnBoardingWrapper from "./pages/Client/OnBoardingStep/OnBoardingWrapper";
import EditOrganisation from "./pages/Admin/EditOrganisation";
import ClientWrapper from "./pages/Client/ClientWrapper";
import Users from "./pages/Client/Users";
import Holidays from "./pages/Client/Holidays/Holidays";
import Projects from "./pages/Client/Projects";
import Leaves from "./pages/Client/Request/Leaves";
import AdministratorOrganisation from "./pages/Client/Administrator/Organisation";
import AdministratorHolidays from "./pages/Client/Administrator/Holidays";
import AdministratorLeaves from "./pages/Client/Administrator/Leaves";
import AdministratorRoles from "./pages/Client/Administrator/Roles";
import Profile from "./pages/Client/Profile";
import AdminProfile from "./pages/Admin/Profile";
import WorkFromHome from "./pages/Client/Request/WorkFromHome";
import Inventory from "./pages/Client/Request/Inventory";
import CreateHoliday from "./pages/Client/Administrator/Holidays/CreateHoliday";
import InCompleteOnBoardingProcess from "./pages/Client/OnBoardingStep/NoBoardingProcess";
import CreateUser from "./pages/Client/Users/CreateUser";
import ViewMyLeaveApplication from "./pages/Client/Request/Leaves/ViewMyLeaveApplication";
import EditMyLeaveApplication from "./pages/Client/Request/Leaves/EditMyLeaveApplication";
import AdministratorYears from "./pages/Client/Administrator/Years";
import ViewWfhApplication from "./pages/Client/Request/WorkFromHome/ViewWfhApplication";
import EditWfhApplication from "./pages/Client/Request/WorkFromHome/EditWfhApplication";
import OthersWfhApplicationDetails from "./pages/Client/Request/WorkFromHome/OthersWfhApplicationDetails";
import OthersLeaveDetails from "./pages/Client/Request/Leaves/OthersLeaveDetails";
import ApplyLeavePage from "./pages/Client/Request/Leaves/ApplyLeavePage";
import ApplyWFHRequest from "./pages/Client/Request/WorkFromHome/ApplyWFHRequestPage";
import { ON_BOARDING_STATUS } from "./constants/default-values";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CAPrivacyNotice from "./pages/CAPrivacyNotice";
import UserDetails from "./pages/Client/Users/UserDetails";
import AdministratorActivity from "./pages/Client/Administrator/Activity";
import CreateActivity from "./pages/Client/Administrator/Activity/CreateActivity";
import UpdateActivity from "./pages/Client/Administrator/Activity/UpdateActivity";
import Reports from "./pages/Client/Reports";
import NotificationsPage from "./pages/NotificationsPage";
import AccessDenied from "./pages/AccessDenied";
import InOutReport from "./pages/Client/Reports/InOutReport";
import ChangePassword from "./pages/Client/ChangePassword";

const App = () => {
  const { isAuthenticated, defaultRoute, getCurrentUser, isHaveAccess } = useAuthentication();
  const DefaultPath = defaultRoute();
  const currentUser = getCurrentUser();

  const PrivateRoute = ({ element }) => {
    let hasAccess = isHaveAccess(window.location);
    const currentUser = isAuthenticated() && getCurrentUser();

    return isAuthenticated() ? (hasAccess ? element : <Navigate to={currentUser?.user_type === "super_admin" ? URLS.AdminDashboard : URLS.Dashboard} />) : <Navigate to={URLS.Login} />;
  };

  const PublicRoute = ({ element }) => {
    return !isAuthenticated() ? element : <Navigate to={defaultRoute()} />;
  };

  const redirectPaths = [URLS.InCompleteOnBoarding, `${URLS.onBoarding}/${ON_BOARDING_STATUS[currentUser?.organization_status]}`]
  if (redirectPaths.includes(DefaultPath) && currentUser?.organization_status !== "completed") {
    window.history.replaceState({}, "", DefaultPath)
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Outlet />}>
          {/* public routes */}
          <Route
            path={URLS.Login}
            element={<PublicRoute element={<Login />} />}
          />
          <Route
            path={URLS.ForgotPassword}
            element={<PublicRoute element={<ForgotPassword />} />}
          />
          <Route
            path={URLS.ResetPassword}
            element={<PublicRoute element={<ResetPassword />} />}
          />
          <Route
            path={URLS.TermsAndConditions}
            element={<PublicRoute element={<TermsAndConditions />} />}
          />
          <Route
            path={URLS.PrivacyPolicy}
            element={<PublicRoute element={<PrivacyPolicy />} />}
          />
          <Route
            path={URLS.CAPrivacyNotice}
            element={<PublicRoute element={<CAPrivacyNotice />} />}
          />

          {/* private routes  - Admin pages*/}
          <Route
            path={URLS.AdminProfile}
            element={
              <PrivateRoute
                element={
                  <OrganisationWrapper>
                    <AdminProfile />
                  </OrganisationWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdminDashboard}
            element={
              <PrivateRoute
                element={
                  <OrganisationWrapper>
                    <AdminDashboard />
                  </OrganisationWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AddOrganisation}
            element={
              <PrivateRoute
                element={
                  <OrganisationWrapper>
                    <AddOrganisation />
                  </OrganisationWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.EditOrganisation}/:orgId`}
            element={
              <PrivateRoute
                element={
                  <OrganisationWrapper>
                    <EditOrganisation />
                  </OrganisationWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.onBoarding}/:step`}
            element={
              <PrivateRoute
                element={
                  <OnBoardingWrapper>
                    <OnBoardingStep />
                  </OnBoardingWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.InCompleteOnBoarding}`}
            element={
              <PrivateRoute
                element={
                  <OnBoardingWrapper>
                    <InCompleteOnBoardingProcess />
                  </OnBoardingWrapper>
                }
              />
            }
          />

          {/* private routes  - Client pages*/}

          <Route
            path={URLS.AccessDenied}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AccessDenied />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Profile}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Profile />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.ChangePassword}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <ChangePassword />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Dashboard}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Dashboard />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Projects}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Projects />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Leaves}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Leaves />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.ApplyLeave}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <ApplyLeavePage />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.OtherLeaveDetails}/:userId/:appId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <OthersLeaveDetails />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.OtherWfhApplicationDetails}/:userId/:appId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <OthersWfhApplicationDetails />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.ViewMyLeaveApplication}/:userId/:appId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <ViewMyLeaveApplication />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.EditMyLeaveApplication}/:userId/:appId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <EditMyLeaveApplication />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.ViewMyWfhApplication}/:userId/:appId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <ViewWfhApplication />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.EditMyWfhApplication}/:userId/:appId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <EditWfhApplication />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.WorkFromHome}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <WorkFromHome />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.ApplyWorkFromHome}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <ApplyWFHRequest />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Inventory}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Inventory />
                  </ClientWrapper>
                }
              />
            }
          />
          <Route
            path={URLS.Users}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Users />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Reports}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Reports />
                  </ClientWrapper>
                }
              />
            }
          />
          <Route
            path={URLS.InOutReport}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <InOutReport />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.UserDetails}/:userId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <UserDetails />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.CreateUser}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <CreateUser />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.Holidays}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <Holidays />
                  </ClientWrapper>
                }
              />
            }
          />
          <Route
            path={URLS.AdministratorOrganisation}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AdministratorOrganisation header={URLS.AdministratorOrganisation} title={'Organisation'} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorHolidays}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AdministratorHolidays header={URLS.AdministratorHolidays} title={'Holidays Settings'} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorHolidaysCreate}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <CreateHoliday header={URLS.AdministratorHolidaysCreate} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorLeaves}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AdministratorLeaves header={URLS.AdministratorLeaves} title={'Leaves Settings'} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorRoles}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AdministratorRoles header={URLS.AdministratorRoles} title={'Roles'} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorActivity}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AdministratorActivity header={URLS.AdministratorActivity} title={'Activity'} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorCreateActivity}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <CreateActivity header={URLS.AdministratorCreateActivity} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={`${URLS.AdministratorUpdateActivity}/:activityId`}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <UpdateActivity header={URLS.AdministratorUpdateActivity} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AdministratorYears}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper>
                    <AdministratorYears header={URLS.AdministratorYears} title={'Years'} />
                  </ClientWrapper>
                }
              />
            }
          />

          <Route
            path={URLS.AllNotifications}
            element={
              <PrivateRoute
                element={
                  <ClientWrapper sx={{ overflow: "hidden" }}>
                    <NotificationsPage header={URLS.AllNotifications} />
                  </ClientWrapper>
                }
              />
            }
          />

          {/* Check redirection using auth value */}
          <Route path="/" element={<Navigate to={DefaultPath} />} />
          <Route path="/*" element={<Navigate to={URLS.Login} />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
