import React, { useRef, useState } from "react";
import BasicInfoStep from "./BasicInfoStep";
import LeaveSettingStep from "./LeaveSettingStep";
import HolidaySettingStep from "./HolidaySettingStep";
import UserRolesStep from "./UserRolesStep";
import UserSettingStep from "./UserSettingStep";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  ON_BOARDING_STEPS,
} from "../../../constants/default-values";
import useAuthentication from "../../../hook/useAuthentication";
import URLS from "../../../routes/urls";
import { Box, Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import Button from "../../../components/Button";
import { getPreviousOnBoardingStep } from "../../../utils";
import { useAlert } from "../../../hook/useAlert";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { setUserOnBoardingStep } from "../../../redux/slices/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

const stepSubmitFunctions = {
  'leave-settings': 'handleSubmitLeaveSetting',
  'holiday-settings': 'handleSubmitHolidaySettings',
  'user-roles': 'handleSubmitUserRoles',
  'user-settings': 'handleSubmitUserSettings'
};

const OnBoardingStep = () => {
  const { step } = useParams();
  const showAlert = useAlert();
  const { getCurrentUser } = useAuthentication();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const childSubmitButtonRef = useRef(null);
  const [onBoardingStep, setOnBoardingStep] = useState(step);
  const [isEditField, setIsEditField] = useState(false);
  const [isShowSaveModal, setIsShowSaveModal] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleShowSaveModal = () => {
    setIsShowSaveModal(true);
  };

  const handleCloseSaveModal = () => {
    setIsShowSaveModal(false);
  };

  const onCancelSaveData = () => {
    const previousStep = getPreviousOnBoardingStep(onBoardingStep);
    if (previousStep) {
      handleCloseSaveModal();
      navigate(`${URLS.onBoarding}/${previousStep}`, {
        replace: true,
      });
      setOnBoardingStep(previousStep);
      dispatch(setUserOnBoardingStep(previousStep))
      setIsEditField(false);
    }
  };

  const onConfirmSaveData = async () => {
    if (childSubmitButtonRef.current) {
      setIsButtonLoading(true);
      const submitFunctionName = stepSubmitFunctions[step];
      if (submitFunctionName && typeof childSubmitButtonRef.current[submitFunctionName] === 'function') {
        const isFormValid = await childSubmitButtonRef.current[submitFunctionName]();
        if (isFormValid) {
          onCancelSaveData();
          setIsEditField(false);
          setIsButtonLoading(false);
        } else {
          handleCloseSaveModal();
          showAlert("Please fill in the required fields to save and go back.", "error");
          setIsButtonLoading(false);
        }
      }
      setIsButtonLoading(false);
    }
  };

  if (currentUser?.organization_status === "completed") {
    return <Navigate replace={true} to={URLS.Dashboard} />
  }

  const renderStepComponent = (currentStep) => {
    switch (currentStep) {
      case ON_BOARDING_STEPS.step1:
        return (
          <BasicInfoStep
            setOnBoardingStep={setOnBoardingStep}
            currentUser={currentUser}
          />
        );
      case ON_BOARDING_STEPS.step2:
        return (
          <LeaveSettingStep
            setOnBoardingStep={setOnBoardingStep}
            currentUser={currentUser}
            isEditField={isEditField}
            setIsEditField={setIsEditField}
            handleShowSaveModal={handleShowSaveModal}
            ref={childSubmitButtonRef}
          />
        );
      case ON_BOARDING_STEPS.step3:
        return (
          <HolidaySettingStep
            setOnBoardingStep={setOnBoardingStep}
            currentUser={currentUser}
            isEditField={isEditField}
            setIsEditField={setIsEditField}
            handleShowSaveModal={handleShowSaveModal}
            ref={childSubmitButtonRef}
          />
        );
      case ON_BOARDING_STEPS.step4:
        return (
          <UserRolesStep
            setOnBoardingStep={setOnBoardingStep}
            currentUser={currentUser}
            isEditField={isEditField}
            setIsEditField={setIsEditField}
            handleShowSaveModal={handleShowSaveModal}
            ref={childSubmitButtonRef}
          />
        );
      case ON_BOARDING_STEPS.step5:
        return (
          <UserSettingStep
            setOnBoardingStep={setOnBoardingStep}
            currentUser={currentUser}
            isEditField={isEditField}
            setIsEditField={setIsEditField}
            handleShowSaveModal={handleShowSaveModal}
            ref={childSubmitButtonRef}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderStepComponent(onBoardingStep)}
      <Modal
        open={isShowSaveModal}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <Grid
            Item
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            py={2} px={3}
            borderBottom="1px solid #E2E4EC"
          >
            <Typography variant="h6">Save Changes?</Typography>
            <IconButton
              onClick={() => setIsShowSaveModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid Item p={3}>
            <Stack spacing={2}>
              <Typography
                color="dark.800"
                fontSize={16}
                fontWeight={400}
                sx={{ lineHeight: "26px" }}
              >
                Do you want to save the changes you have made on this screen?
              </Typography>
            </Stack>
          </Grid>
          <Grid Item py={2} px={3} borderTop="1px solid #E2E4EC">
            <Stack spacing={1} direction="row">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                fontWeight="500"
                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                onClick={onConfirmSaveData}
                isButtonLoading={isButtonLoading}
                disabled={isButtonLoading}
              >
                Save Changes
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={onCancelSaveData}
                disabled={isButtonLoading}
              >
                Discard Changes
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default OnBoardingStep;
