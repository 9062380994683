/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import useAuthentication from '../../hook/useAuthentication';
import { useAlert } from '../../hook/useAlert';
import { titleCase } from '../../utils';
import { Box, IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationModal from '../ConfirmationModal';

const filter = createFilterOptions();

function AutocompleteWithAddOption({
    id,
    label,
    variant,
    sx,
    value,
    onChange,
    disabledItems = [],
    setHolidayTitles,
    holidayTitles,
}) {
    const { getCurrentUser } = useAuthentication();
    const currentUser = useMemo(() => getCurrentUser(), [getCurrentUser]);
    const showAlert = useAlert();
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState(value || '');
    const [isOpen, setIsOpen] = useState(false);
    const [deletableHolidayOption, setDeletableHolidayOption] = useState("")
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    useEffect(() => {
        if (!value?.length) {
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(API.getAllHolidayTitle);
                if (response.status === 200) {
                    const names = response?.data?.data?.map(item => item.name);
                    setOptions(names);
                    setHolidayTitles(response?.data?.data || [])
                } else {
                    setHolidayTitles([])
                    setOptions([]);
                }
            } catch (error) {
                console.error('Error fetching data', error);
                // showAlert('Error fetching data');
            }
        };
        fetchData();
    }, [showAlert]);

    const handleAddOption = useCallback(async (newValue) => {
        try {
            if (disabledItems?.some((value) => value?.name?.toLowerCase() === newValue?.toLowerCase())) {
                showAlert("Holiday title already exist", "error")
            } else {
                const body = {
                    name: newValue,
                    organization_id: currentUser?.organization_id
                };
                const response = await axiosInstance.post(API.createOfficeHolidayTitle, body);
                if (response.status === 200) {
                    setOptions((prevOptions) => [...prevOptions, body.name]);
                    setHolidayTitles((prev) => [...prev, response?.data?.data])
                    setInputValue(body.name);
                    showAlert(response.data.message);
                } else {
                    showAlert('Error adding new option');
                }
            }
        } catch (error) {
            console.error('Error adding new option', error);
            showAlert('Error adding new option');
        }
    }, [currentUser, showAlert]);

    const handleChange = useCallback((event, newValue) => {
        let updatedValue = newValue;

        if (typeof newValue === 'string') {
            updatedValue = newValue;
        } else if (newValue && newValue.inputValue) {
            handleAddOption(newValue.inputValue);
            updatedValue = newValue.inputValue;
        }

        setInputValue(updatedValue);
        onChange && onChange(event, updatedValue);
    }, [handleAddOption, onChange]);

    const filterOptions = useCallback((options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue?.toLowerCase() === option?.toLowerCase());
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                title: `Add "${titleCase(inputValue)}"`,
            });
        }
        return filtered;
    }, []);

    const getOptionLabel = useCallback((option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return titleCase(option.inputValue);
        }
        return option;
    }, []);

    const handleSubmitDelete = async () => {
        try {
            setIsButtonLoading(true);
            const deletedOption = holidayTitles?.find(item => item?.name?.toLowerCase() === deletableHolidayOption?.toLowerCase());
            if (inputValue?.toLowerCase() === deletedOption?.name?.toLowerCase()) {
                setInputValue("")
            }

            if (deletedOption?.id) {
                const response = await axiosInstance.delete(API.deleteOfficeHolidayTitle(deletedOption?.id));
                if (response?.status === 200) {
                    showAlert(response.data.message);
                    setOptions((prev) => prev?.filter((name) => name?.toLowerCase() !== deletedOption?.name?.toLowerCase()));
                } else {
                    showAlert(response.data.message);
                }
            }
            setIsButtonLoading(false);
            setIsOpen(false);
        } catch (error) {
            console.error(error);
            showAlert(error.response.data.message || "Something went wrong.");
            setIsButtonLoading(false);
        }
    }

    const handleDeleteHoliday = async (holiday) => {
        setDeletableHolidayOption(holiday)
        setIsOpen(true);
    }

    const renderOption = useCallback((props, option) => {
        const isDisabled = disabledItems.some(item => titleCase(item.name) === titleCase(option));
        const titleArray = option?.title?.split(" ");
        let isShowDeleteButton = true;
        if (titleArray?.length && titleArray?.includes("Add")) {
            isShowDeleteButton = false;
        }
        return (
            <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
            >
                <li {...props} style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto', width: "100%" }}>
                    <span>
                        {titleCase(option.title ? option.title : option)}
                    </span>
                </li>
                {isShowDeleteButton ?
                    <IconButton
                        size="small"
                        sx={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "0px",
                        }}
                        onClick={() => handleDeleteHoliday(option)}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    : null}
            </Box>
        );
    }, [disabledItems]);

    return (
        <>
            <Autocomplete
                id={id}
                value={inputValue}
                onChange={handleChange}
                filterOptions={filterOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={options}
                getOptionLabel={getOptionLabel}
                renderOption={renderOption}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, maxLength: 30 }}
                        variant={variant || "standard"}
                        label={label || "Search or add option"}
                        sx={sx}
                    />
                )}
            />
            <ConfirmationModal
                isOpen={isOpen}
                title={'Festival'}
                deletableDataName={deletableHolidayOption}
                dataContentName={'Festival'}
                handleClose={() => setIsOpen(false)}
                onConfirm={() => handleSubmitDelete()}
                onCancel={() => { setIsOpen(false); setDeletableHolidayOption("") }}
                isButtonLoading={isButtonLoading}
                subList={<span>This will remove <b>{deletableHolidayOption}</b> from your organization's common festival list.</span>}
            />
        </>
    );
}

export default AutocompleteWithAddOption;
