import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultipleDatePicker from "../../../../../components/MultipleDatePicker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import Button from "../../../../../components/Button";
import { convertDateFromFormat, findDeletedDataByDate, getFormattedDate, isFutureYearDate, sortArrayByKey, titleCase } from "../../../../../utils";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";
import ICONS from "../../../../../constants/icons";
import { getDefaultInOutTime } from "../../../../../constants/default-values";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const EditWFHRequest = ({
  setIsAppliedLeave,
  setLeaveData,
  leaveData,
  userLeaveTypes,
  organizationUserList,
  setErrors,
  errors,
  setDeletedDays,
  setRemainingCount,
  optionalLeaveData,
  renewalSettingData
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
  });
  const [selectedDatesData, setSelectedDatesData] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLeaveData(prev => {
      let _value = null;
      if (name === "informed_authorities") {
        _value = [value?.name];
      } else {
        _value = value;
      }
      return { ...prev, [name]: _value };
    });
    handleBlur(event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    let updatedErrors = { ...errors };
    if (['WfhDays'].includes(name)) {
      if (!!value?.length) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    } else {
      if (!!value) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    }
    setErrors(updatedErrors)
  };

  const getLeaveDates = (isGetType = false) => {
    if (isGetType) {
      return leaveData?.WfhDays?.map((leaveData) => ({
        date: convertDateFromFormat(leaveData?.date),
        leave_duration: leaveData?.leave_duration || "full",
        paid: leaveData?.paid || 0,
        unpaid: leaveData?.unpaid || 0,
        id: leaveData?.id || null,
        in_time: leaveData?.in_time || null,
        out_time: leaveData?.out_time || null,
        rules: leaveData?.rules || [],
        specialRequest: leaveData?.specialRequest || [],
        is_changed: leaveData?.is_changed || false
      }))
    }
    else {
      return leaveData?.WfhDays?.map((leaveData) => !!leaveData?.date ? leaveData?.date : leaveData);
    }
  }

  const handleSelectMultipleDate = (selectedDates) => {
    const dates = selectedDates?.map((date) => {
      let convertedDate = typeof date === "string" ? date : getFormattedDate(new Date(date));
      let data = leaveData?.WfhDays?.find(item => item?.date === convertedDate);
      let { inTime, outTime } = getDefaultInOutTime();
      if (!!data) {
        return data;
      } else {
        return {
          date: convertedDate,
          leave_duration: "full",
          paid: 0,
          unpaid: 0,
          rules: [],
          specialRequest: [],
          is_changed: false,
          in_time: moment(inTime).format("HH:mm:ss"),
          out_time: moment(outTime).format("HH:mm:ss"),
        }
      }
    });

    setSelectedDatesData(dates);

    let updatedErrors = { ...errors };
    if (!!dates?.length) {
      updatedErrors['WfhDays'] = "";
    } else {
      updatedErrors['WfhDays'] = "Required";
    }
    setErrors(updatedErrors)
  };

  const validateData = (data) => {
    let updatedErrors = {};
    if (!data?.informed_authorities?.length) {
      updatedErrors['informed_authorities'] = "Required";
    }

    if (!data?.static_id) {
      updatedErrors['static_id'] = "Required";
    }

    if (!data?.WfhDays?.length) {
      updatedErrors['WfhDays'] = "Required";
    }

    setErrors((prev) => ({ ...prev, ...updatedErrors }));
    return Object.keys(updatedErrors)?.length;
  }

  const handleSubmit = async (e) => {
    setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
    e.preventDefault();
    if (!validateData(leaveData)) {
      let updatedDataList = []
      if (selectedDatesData?.length) {
        updatedDataList = sortArrayByKey(selectedDatesData, "desc", "date")?.map(leave => {
          return {
            ...leave,
            credit_used: isFutureYearDate(renewalSettingData, leave?.date) ? 0 : 1,
            is_changed: leaveData?.static_id !== optionalLeaveData?.static_id,
          }
        });
        const deletedData = findDeletedDataByDate(leaveData?.WfhDays, sortArrayByKey(selectedDatesData, "asc", "date"), 'date');
        setDeletedDays((prev) => ([...prev, ...deletedData]))
        let prevRequestDataLength = leaveData?.WfhDays?.length;
        setRemainingCount((prev) => {
          let alreadyRemainingRequest = prev;
          if (prevRequestDataLength < selectedDatesData?.length) {
            return alreadyRemainingRequest - (selectedDatesData?.length - prevRequestDataLength);
          } else {
            return alreadyRemainingRequest + (prevRequestDataLength - selectedDatesData?.length);
          }
        })
      } else {
        updatedDataList = sortArrayByKey(leaveData?.WfhDays, "desc", "date")?.map(leave => {
          return {
            ...leave,
            credit_used: isFutureYearDate(renewalSettingData, leave?.date) ? 0 : 1,
            is_changed: leaveData?.static_id !== optionalLeaveData?.static_id,
          }
        });
      }
      setLeaveData((prev) => ({
        ...prev,
        WfhDays: sortArrayByKey(updatedDataList, "asc", "date"),
      }));
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      setIsAppliedLeave(true);
    } else {
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  }

  const handleBackClick = () => {
    navigate(-1);
  }

  return (
    <>
      <Grid width={'100%'}>
        <Box display="flex" borderBottom="1px solid #E2E4EC" alignItems="center" py={2} px={{ xs: 2, lg: 3, xl: 4 }}>
          <Box display="flex">
            <IconButton
              aria-label="back"
              variant="outlined"
              color="secondary.100"
              size="small"
              sx={{
                borderRadius: "4px",
                background: "rgba(63, 92, 118, 0.1)",
                marginRight: "10px",
              }}
              onClick={handleBackClick}
            >
              <i
                style={{
                  height: "20px",
                  width: "20px",
                  transform: "rotate(90deg)",
                }}
              >
                {ICONS.ChevronLineSmall}
              </i>
            </IconButton>
            <Typography
              variant="h6"
              color="secondary"
              fontSize={20}
              fontWeight={600}
              lineHeight="28px"
              letterSpacing="0.15px"
              sx={{ textTransform: "capitalize" }}
            >
              Edit A WFH Request
            </Typography>
          </Box>
        </Box>
        <Box p={{ xs: 2, lg: 3, xl: 4 }}>
          <form onSubmit={handleSubmit}>
            <Box width={768} sx={{ maxWidth: "100%" }}>
              <Stack spacing={3} mb={4}>
                <Stack
                  spacing={4}
                  direction="row"
                  alignItems="flex-start"
                  width="100%"
                >
                  <Grid Item sx={{ flex: '0 0 calc(50% - 16px)' }}>
                    <FormControl variant="standard" sx={{ m: 0, width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="static_id"
                        onChange={handleChange}
                        value={parseInt(leaveData?.static_id)}
                        onBlur={handleBlur}
                        label="Select Type"
                        disabled={true}
                      >
                        {userLeaveTypes?.map(
                          ({ name, id }) => (
                            <MenuItem value={id}>
                              {name === "Come late" ? "Late Coming" : titleCase(name)}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {errors?.static_id ? <FormHelperText sx={{ color: "red" }}>
                        {errors?.static_id}
                      </FormHelperText> : null}
                    </FormControl>
                  </Grid>
                  <Grid Item sx={{ flex: '0 0 calc(50% - 16px)', maxWidth: 'calc(50% - 16px)' }}>
                    <FormControl
                      className={classes.margin}
                      variant="standard"
                      sx={{
                        margin: "0px !important",
                        width: "100%",
                        // "div ": { marginTop: "2px !important" },
                      }}
                    >
                      <AutocompleteSelect
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={sortArrayByKey(organizationUserList, 'asc', 'name')}
                        value={organizationUserList?.find(user => user?.name === leaveData?.informed_authorities[0])}
                        label={"Request To"}
                        name="informed_authorities"
                        id="informed_authorities"
                      />
                      <FormHelperText>
                        Along with this user, Leave application will be sent to HR and
                        Admin users of your organisation.
                      </FormHelperText>
                      {errors?.informed_authorities ? <FormHelperText sx={{ color: "red" }}>
                        {errors?.informed_authorities}
                      </FormHelperText> : null}
                    </FormControl>
                  </Grid>
                </Stack>
                {/* <Alert width="100%" severity="info">
                Text here for Casual Leave and it will change as per leave type.
              </Alert> */}
                <Grid Item flex={1}>
                  <Typography fontWeight={400} fontSize={14} color="dark.800" sx={{ lineHeight: '21px', letterSpacing: '0.15px' }}>Leave Dates</Typography>
                  <MultipleDatePicker
                    extraDisableDates={leaveData?.WfhDays?.map(leaveDetail => leaveDetail?.date)}
                    name={"WfhDays"}
                    value={getLeaveDates()}
                    onBlur={handleBlur}
                    onChange={(date) => handleSelectMultipleDate(date)}
                    plugins={[
                      <DatePanel markFocused />,
                      <Toolbar
                        position="bottom"
                        sort={["deselect", "today", "close"]}
                        names={{
                          deselect: "Deselect",
                          today: "Today",
                          close: "Select",
                        }}
                      />,
                    ]}
                  />
                  {errors?.WfhDays ? <FormHelperText sx={{ color: "red" }}>
                    {errors?.WfhDays}
                  </FormHelperText> : null}
                </Grid>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "20px",
                  boxShadow: "none",
                }}
                type="submit"
                disabled={!!Object.values(errors).filter(item => !!item)?.length}
                isButtonLoading={isLoading.formSubmitting}
              >
                Proceed
              </Button>
              <Button
                color="secondary"
                size="large"
                variant="text"
                sx={{
                  textTransform: "none",
                }}
                type="reset"
                onClick={() => navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`)}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </>
  );
};

export default EditWFHRequest;
