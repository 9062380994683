import React from "react";
import LeavesCalendar from "../../../../../components/LeavesCalendar";
// import { titleCase } from "../../../../../utils";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../../routes/urls";

const WFHCalendarView = ({ leaveDataList, userId }) => {
    const navigate = useNavigate();

    // const getFormattedEventDate = (date) => {
    //     let [year, month, day] = date.split("-");
    //     let _date = new Date();
    //     _date.setFullYear(year, month, day);
    //     _date.setMonth(month);
    //     _date.setDate(day);
    //     _date.setHours(0, 0, 0, 0);
    //     _date.setMinutes(0, 0, 0);
    //     _date.setSeconds(0, 0);
    //     _date.setMilliseconds(0);
    //     return _date;
    // };

    // const createEventData = (leaveData) => {
    //     if (!!leaveData && Object.keys(leaveData)?.length > 0) {
    //         if (leaveData?.leaveDays?.length > 1 || leaveData?.leave_days?.length > 1) {
    //             return (leaveData?.leaveDays || leaveData?.leave_days)?.map(leaveDay => ({
    //                 title: `${titleCase(leaveData?.leave_type)}`,
    //                 start: getFormattedEventDate(leaveDay?.leave_date),
    //                 end: getFormattedEventDate(leaveDay?.leave_date),
    //                 allDay: leaveDay?.leave_duration === "full" ? true : false,
    //                 leaveDuration: leaveDay?.leave_duration,
    //                 leaveType: leaveData?.leave_type,
    //                 leaveStatus: leaveData?.status,
    //                 userId: userId || leaveData?.user_id,
    //                 id: leaveData?.id,
    //                 isMyLeave: !!userId,
    //             }));
    //         } else {
    //             return {
    //                 title: `${titleCase(leaveData?.leave_type)}`,
    //                 start: getFormattedEventDate((leaveData?.leaveDays || leaveData?.leave_days)[0]?.leave_date),
    //                 end: getFormattedEventDate((leaveData?.leaveDays || leaveData?.leave_days)[0]?.leave_date),
    //                 allDay: (leaveData?.leaveDays || leaveData?.leave_days)[0]?.leave_duration === "full" ? true : false,
    //                 leaveDuration: (leaveData?.leaveDays || leaveData?.leave_days)[0]?.leave_duration,
    //                 leaveType: leaveData?.leave_type,
    //                 leaveStatus: leaveData?.status,
    //                 userId: userId || leaveData?.user_id,
    //                 id: leaveData?.id,
    //                 isMyLeave: !!userId,
    //             }
    //         }
    //     }
    // };

    // to create event
    // let events = leaveDataList?.map((leave) => createEventData(leave)).flat();

    const handleEventClick = (event) => {
        navigate(`${event?.isMyLeave ? URLS.ViewMyLeaveApplication : URLS.OtherLeaveDetails}/${event?.userId}/${event?.id}`)
    };

    return (
        <LeavesCalendar eventsList={[]} handleEventClick={handleEventClick} />
    );
};

export default WFHCalendarView;
