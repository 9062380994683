/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../hook/useAlert";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import URLS from "../../../routes/urls";
import { ON_BOARDING_STEPS } from "../../../constants/default-values";
import Input from "../../../components/Input";
import { generateUniqueId, getFullName, getMissingKeys } from "../../../utils";
import { titleCase, validateEmail } from "../../../utils/validation";
import CircularLoader from "../../../components/CircularLoader";
import { handleCheckIsEmailUnique } from "../../../axios/service";
import { usePageTitle } from "../../../hook/useTitle";
import { setUserOnBoardingStep } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";

const initialUserObjectData = {
  // id: null,
  email: "",
  name: "",
  active: true,
  current_user_role_id: null,
  current_user_type_id: null,
  org_work_type_setting_id: null,
  is_admin: true,
  unique_id: generateUniqueId(),
};

const initialErrorObject = {
  email: "",
  name: "",
  current_user_role_id: "",
  current_user_type_id: "",
  org_work_type_setting_id: "",
};

const UserSettingStep = forwardRef(({ setOnBoardingStep, currentUser, isEditField, setIsEditField, handleShowSaveModal }, ref) => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
  });
  const [errors, setErrors] = useState([]);
  const [constantValues, setConstantValues] = useState({
    userRolesData: [],
    workTypeData: [],
    userTypeData: [],
  });

  setPageTitle("Add your Users");
  const findIndexWithUid = (id) =>
    formData.findIndex((data) => data.unique_id === id);

  const fetchData = async () => {
    try {
      const [
        userRolesResponse,
        workTypesResponse,
        userTypesResponse,
        userSettingResponse
      ] = await Promise.all([
        axiosInstance.get(API.organisationUserRoles(currentUser?.organization_id)),
        axiosInstance.get(API.getWorkTypesByOrganisationId(currentUser?.organization_id)),
        axiosInstance.get(API.getUserTypes),
        axiosInstance.get(API.organisationUserSetting(currentUser?.organization_id))
      ]);

      if (userRolesResponse.status === 200) {
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: userRolesResponse.data.data.map(item => ({
            ...item,
            isVisible: true,
            isSelected: true,
          })),
        }));
      }

      if (workTypesResponse.status === 200) {
        setConstantValues((prev) => ({
          ...prev,
          workTypeData: workTypesResponse.data.data,
        }));
      }

      if (userTypesResponse.status === 200) {
        setConstantValues((prev) => ({
          ...prev,
          userTypeData: userTypesResponse.data.data.map(item => ({
            ...item,
            isVisible: true,
          })),
        }));
      }

      if (userSettingResponse.status === 200) {
        let newResponse = userSettingResponse.data.data.map((userData, index) => ({
          ...userData,
          name: getFullName(userData).trim(),
          unique_id: generateUniqueId(),
        }));
        newResponse.sort((a, b) => {
          if (a.is_primary_admin === b.is_primary_admin) {
            return 0;
          }
          return a.is_primary_admin ? -1 : 1;
        });
        setFormData([...newResponse, initialUserObjectData]);
      }

      setLoading((prev) => ({ ...prev, pageLoading: false }));

    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  useImperativeHandle(ref, () => ({
    handleSubmitUserSettings: () => handleSubmitUserSettings(ON_BOARDING_STEPS.step4)
  }));

  const handleChangeFunction = (unique_id, event, index) => {
    setIsEditField(true);
    // handleBlurFunction(event, index);
    const { name, value } = event.target;
    const findIndex = findIndexWithUid(unique_id);
    const updatedUserData = [...formData];
    const updatedObject = {
      ...updatedUserData[findIndex],
      [name]: value,
    };
    if (name === "current_user_type_id") {
      let adminType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin");
      let generalType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "general");
      let adminRole = constantValues?.userRolesData?.find((value) => value?.name?.toLowerCase() === "administrator");
      if (adminType?.id === value) {
        updatedObject['current_user_role_id'] = adminRole?.id;
        updatedObject['is_admin'] = true;
        updatedObject['is_admin_user'] = true;
        updatedObject['is_general_user'] = false;
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: prev?.userRolesData?.map(item => item?.id === adminRole?.id ? { ...item, isVisible: true, isSelected: true, } : { ...item, isVisible: false })
        }));
        let updatedError = [...errors];
        updatedError[index] = { ...updatedError[index], current_user_role_id: "" };
        setErrors(updatedError);
      } else if (generalType?.id === value) {
        updatedObject['is_admin'] = false;
        updatedObject['is_admin_user'] = false;
        updatedObject['is_general_user'] = true;
        updatedObject['current_user_role_id'] = null;
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
        }));
      } else {
        setConstantValues((prev) => ({
          ...prev,
          userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
        }));
        updatedObject['is_admin'] = true;
        updatedObject['is_admin_user'] = false;
        updatedObject['is_general_user'] = false;
        updatedObject['current_user_role_id'] = null;;
      }
    }
    updatedUserData[findIndex] = updatedObject;

    if (value !== "" && index === updatedUserData.length - 1) {
      initialUserObjectData.unique_id = generateUniqueId();
      updatedUserData.push(initialUserObjectData);
    }

    setFormData(updatedUserData);
  };

  const handleBlurFunction = async (e, index) => {
    let { name, value } = e.target;
    let updatedErrors = [...errors];
    const error = { ...(updatedErrors[index] || initialErrorObject) };
    if (!value) {
      error[name] = "Required";
    } else if (name === "email") {
      const isEmailUnique = await handleCheckIsEmailUnique(value);
      const checkExistUser = formData.filter(
        (obj) => value && obj.email && obj.email === value
      );
      const findExistEmail =
        index === 0
          ? formData.slice(1).find((data) => data.email === value)
            ?.unique_id
          : formData[index]?.unique_id;

      const isInvalidEmail =
        value?.trim() && !validateEmail(value?.trim());

      if (isInvalidEmail) {
        error[name] = "Invalid email address";
      } else if (isEmailUnique || checkExistUser.length > 1) {
        error[name] = "Email is already exist";
      } else if (!findExistEmail) {
        error[name] = null;
      } else {
        error[name] = null;
      }
    } else {
      error[name] = null;
    }
    let isShowError =
      Object?.values(getMissingKeys(formData[index]))?.filter(
        (value) => !!value
      )?.length !==
      [
        "email",
        "name",
        "current_user_role_id",
        "current_user_type_id",
        "org_work_type_setting_id",
      ].length;
    updatedErrors[index] = isShowError ? error : initialErrorObject;
    setErrors(updatedErrors);
  };

  const handleRemoveUser = (userData, index) => {
    const updatedUserData = [...formData];
    if (userData?.id) {
      const changeDeleteStatus = updatedUserData?.map((user) => {
        return user?.unique_id === userData?.unique_id
          ? { ...user, active: false }
          : { ...user };
      });
      setFormData(changeDeleteStatus);
    } else {
      const removedObject = updatedUserData?.filter(
        (data) => data.unique_id !== userData?.unique_id
      );
      setFormData(removedObject);
    }
    setErrors((prev) => prev?.filter((_, _index) => _index !== index));
  };

  const validateFormData = (formData) => {
    let validationMessage = "Required";
    let isValid = true;
    const newErrors = [];

    formData.forEach((userData, index, arrayData) => {
      const errors = {};
      const checkExistEmail = arrayData?.filter(
        (data) => !!data?.email && data?.email === userData?.email
      );

      const isInvalidEmail =
        userData?.email?.trim() && !validateEmail(userData?.email?.trim());
      if (
        userData?.current_user_role_id ||
        userData?.name?.trim() ||
        userData?.email?.trim() ||
        userData?.org_work_type_setting_id ||
        userData?.current_user_type_id
      ) {
        if (!userData?.current_user_role_id) {
          errors.current_user_role_id = validationMessage;
          isValid = false;
        }

        if (!userData?.name?.trim()) {
          errors.name = validationMessage;
          isValid = false;
        }

        if (!userData?.email?.trim()) {
          errors.email = validationMessage;
          isValid = false;
        } else if (isInvalidEmail) {
          errors.email = "Invalid email address";
        } else if (checkExistEmail.length > 1) {
          if (index !== 0) {
            errors.email = "Email is already exist";
          }
        }

        if (!userData?.org_work_type_setting_id) {
          errors.org_work_type_setting_id = validationMessage;
          isValid = false;
        }

        if (!userData?.current_user_type_id) {
          errors.current_user_type_id = validationMessage;
          isValid = false;
        }
      }
      newErrors[index] = errors;
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmitUserSettings = async (redirectStep) => {
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: true }));
      if (validateFormData(formData)) {
        let filterData = formData?.filter(
          (data) => !!data?.current_user_type_id
        );
        let body = { org_users: filterData, is_previous: redirectStep === ON_BOARDING_STEPS.step4 };
        const response = await axiosInstance.put(
          API.organisationUserSetting(currentUser?.organization_id),
          body
        );
        if (response.status === 200) {
          if (redirectStep === ON_BOARDING_STEPS.step4) {
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
            showAlert(response.data?.message);
            navigate(`${URLS.onBoarding}/${redirectStep}`, {
              replace: true,
            });
            setOnBoardingStep(redirectStep);
            dispatch(setUserOnBoardingStep(redirectStep));
            return true;
          } else {
            showAlert("Onboarding completed successfully, please login again to start using the app.");
            secureLocalStorage.clear()
            navigate(URLS.Login, {
              replace: true,
            });
          }
        }
        setLoading((prev) => ({ ...prev, formSubmitting: false }));
        return false;
      }
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  const handlePreviousStep = () => {
    if (isEditField) {
      handleShowSaveModal();
    } else {
      navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step4}`, {
        replace: true,
      });
      setOnBoardingStep(ON_BOARDING_STEPS.step4);
    }
  };
  return (
    <Box display="flex" flexDirection="column" width={"100%"}>
      <Stack spacing={4} sx={{ flexBasis: "100%" }}>
        <Typography color="secondary.800" fontSize={20}>
          Create a Organisation (Step 5 of 5)
        </Typography>
        <Grid>
          <Typography
            color="secondary"
            fontSize={32}
            lineHeight={"44px"}
            mb={2}
          >
            Add your Users
          </Typography>
          <Typography
            variant="body2"
            color="dark.800"
            width={600}
            maxWidth={"100%"}
          >
            Your User will have access based on respective roles. Users will be
            invited to the organisation. You can still add/manage from the User
            settings later.
          </Typography>
        </Grid>
        {loading.pageLoading ? (
          <CircularLoader />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Stack spacing={1}>
              {formData?.map((userData, index, array) =>
                userData?.active ? (
                  <Card key={index} sx={{ boxShadow: "none", display: "flex" }}>
                    <Typography
                      textAlign={"center"}
                      fontSize={14}
                      sx={{ minWidth: "30px", paddingTop: "10px" }}
                    >
                      {index + 1}
                    </Typography>
                    <Card
                      sx={{
                        padding: "24px 24px 0px 24px",
                        bgcolor: "offWhite",
                        boxShadow: "none",
                        flex: "1",
                      }}
                    >
                      <Grid container flexWrap={"nowrap"}>
                        <Grid Item container>
                          <Grid
                            Item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              name={"current_user_type_id"}
                              select
                              type="select"
                              label="User Type"
                              defaultValue="Admin"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.current_user_type_id}
                              error={!!errors[index]?.current_user_type_id}
                              helperText={errors[index]?.current_user_type_id}
                              required={index !== array?.length - 1}
                              disabled={userData?.is_primary_admin}
                            >
                              {constantValues?.userTypeData?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {titleCase(option.name)}
                                </MenuItem>
                              ))}
                            </Input>
                          </Grid>
                          <Grid
                            Item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              name={"name"}
                              label="Full Name"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={getFullName(userData)}
                              error={!!errors[index]?.name}
                              helperText={errors[index]?.name}
                              required={index !== array?.length - 1}
                            />
                          </Grid>
                          <Grid
                            Item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              name={"email"}
                              type="email"
                              label="Email Address"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.email}
                              error={!!errors[index]?.email}
                              helperText={errors[index]?.email}
                              required={index !== array?.length - 1}
                              disabled={userData?.is_primary_admin}
                            />
                          </Grid>
                          <Grid
                            Item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              name={"org_work_type_setting_id"}
                              select
                              type="select"
                              label="Work Type"
                              defaultValue="In-Office"
                              variant="standard"
                              sx={{ width: "100%" }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.org_work_type_setting_id}
                              error={!!errors[index]?.org_work_type_setting_id}
                              helperText={
                                errors[index]?.org_work_type_setting_id
                              }
                              required={index !== array?.length - 1}
                            >
                              {constantValues?.workTypeData?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Input>
                          </Grid>
                          <Grid
                            Item
                            pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <Input
                              select
                              type="select"
                              label="User Role"
                              variant="standard"
                              name={"current_user_role_id"}
                              sx={{
                                width: "100%",
                                "& .MuiPopover-paper": {
                                  boxShadow:
                                    "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                                },
                              }}
                              onChange={(event) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  event,
                                  index
                                )
                              }
                              onBlur={(e) => handleBlurFunction(e, index)}
                              value={userData?.current_user_role_id}
                              error={!!errors[index]?.current_user_role_id}
                              helperText={errors[index]?.current_user_role_id}
                              required={index !== array?.length - 1}
                              disabled={(userData?.is_admin_user || userData?.is_primary_admin) || false}
                              InputLabelProps={{ shrink: userData?.is_admin_user || !!userData?.current_user_role_id }}
                            >
                              {constantValues?.userRolesData?.map((option) => (
                                <MenuItem
                                  selected={option?.isSelected || false}
                                  disabled={option.id === userData?.current_user_role_id}
                                  key={option.id}
                                  value={option.id}
                                  sx={{
                                    display: !option?.isVisible ? "none" : "block",
                                    "&:hover": {
                                      background: "rgba(4, 127, 224, 0.1)",
                                    },
                                  }}
                                >
                                  {titleCase(option.name)}
                                </MenuItem>
                              ))}
                            </Input>
                          </Grid>
                          <Grid
                            Item
                            // pr={3}
                            xs={6}
                            md={4}
                            sx={{
                              // flex: "0 0 33.33%",
                              // maxWidth: "33.33%",
                              marginBottom: "30px",
                            }}
                          >
                            <FormControlLabel
                              value="end"
                              control={
                                <Checkbox checked={userData?.is_admin} />
                              }
                              label="Access to Admin Panel"
                              labelPlacement="end"
                              sx={{
                                padding: "0px 9px 9px",
                                '.MuiCheckbox-sizeMedium': {
                                  minWidth: '42px'
                                }
                              }}
                              disabled={(userData?.is_primary_admin || userData?.is_general_user || userData?.is_admin_user) || false}
                              onChange={(event, checked) =>
                                handleChangeFunction(
                                  userData?.unique_id,
                                  {
                                    target: {
                                      name: "is_admin",
                                      value: checked,
                                    },
                                  },
                                  index
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid Item>
                          <Box sx={{ minWidth: "40px" }}>
                            {index !== formData.length - 1 ? (
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                onClick={() =>
                                  handleRemoveUser(userData, index)
                                }
                                disabled={
                                  userData?.is_primary_admin ||
                                  userData?.id === currentUser?.id
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Card>
                ) : null
              )}
            </Stack>
          </Box>
        )}
      </Stack>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Button
          variant="text"
          color="secondary"
          sx={{ fontSize: "15px", textTransform: "none" }}
          onClick={handlePreviousStep}
        >
          Previous
        </Button>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          flexBasis="100%"
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="button"
            sx={{
              boxShadow: "0",
              color: "white",
              fontSize: "15px",
              textTransform: "none",
              marginLeft: "20px",
              "&:hover": { boxShadow: "0" },
            }}
            onClick={() => handleSubmitUserSettings(ON_BOARDING_STEPS.completed)}
            isButtonLoading={loading?.formSubmitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
});

export default UserSettingStep;
