import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Stack,
  Chip,
  List,
  ListItem,
} from "@mui/material";
import { LEAVE_ACTION, LEAVE_STATUS } from "../../constants/default-values";
import { getFullName, titleCase } from "../../utils";
import Button from "../Button";
import Input from "../Input";
import BackgroundLetterAvatars from "../BackgroundLetterAvatars";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};
const CustomModal = ({
  actionType,
  handleClose,
  title,
  leaveType,
  date,
  userData,
  applyDays,
  comment,
  commentError = "",
  onApprove,
  onCancel,
  handleComment,
  isButtonLoading = false,
  isWfh = false,
  submitButtonContent = ""
}) => {
  return (
    <>
      <Modal
        open={!!actionType}
        onClose={() => { }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid Item py={2} px={3} borderBottom="1px solid #E2E4EC">
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid Item p={3}>
            <Stack spacing={2}>
              {typeof date === 'object' ?
                <>
                  <Typography
                    color="dark.800"
                    fontSize={16}
                    fontWeight={400}
                    sx={{ lineHeight: "26px" }}
                  >
                    Are you sure you want to{" "}
                    <span
                      style={{
                        color:
                          actionType === LEAVE_STATUS.Approved
                            ? "#047FE0"
                            : actionType === LEAVE_STATUS.Rejected
                              ? "#D32F2F" :
                              actionType === LEAVE_STATUS.Delete ? "rgba(0, 0, 0, 0.87)"
                                : "#3F5C76",
                        fontWeight: "700",
                      }}
                    >
                      {actionType === LEAVE_STATUS.Approved ? "Approve" : actionType === LEAVE_STATUS.Rejected ? "Reject" : actionType === LEAVE_STATUS.Delete ? "Delete" : "Cancel"}
                    </span>{" "}
                    the <b>{leaveType}</b> request on {date?.length > 1 ? 'below dates' : <b>{date}</b>}?
                  </Typography>
                  {date?.length > 1 ?
                    <List sx={{
                      p: 0,
                      'li': {
                        padding: '0px',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: 'dark.800',
                        lineHeight: '26px',
                        letterSpacing: '0.15px'
                      }
                    }}>
                      {date?.map((value) => (
                        <ListItem>{value}</ListItem>
                      ))}
                    </List>
                    : null}
                </>
                :
                <Typography
                  color="dark.800"
                  fontSize={16}
                  fontWeight={400}
                  sx={{ lineHeight: "26px" }}
                >
                  Are you sure you want to{" "}
                  <span
                    style={{
                      color:
                        actionType === LEAVE_STATUS.Approved
                          ? "#047FE0"
                          : actionType === LEAVE_STATUS.Rejected
                            ? "#D32F2F" :
                            actionType === LEAVE_STATUS.Delete ? "rgba(0, 0, 0, 0.87)"
                              : "#3F5C76",
                      fontWeight: "700",
                    }}
                  >
                    {actionType === LEAVE_STATUS.Approved ? "Approve" : actionType === LEAVE_STATUS.Rejected ? "Reject" : actionType === LEAVE_STATUS.Delete ? "Delete" : "Cancel"}
                  </span>{" "}
                  the <b>{leaveType}</b> request on <b>{date}</b>?
                </Typography>}
              <Box
                py={1.5}
                px={2}
                border="1px solid rgba(0, 0, 0, 0.08)"
                display="flex"
                justifyContent="space-between"
                sx={{ borderRadius: "5px", background: "#EBEFF3" }}
              >
                <Grid Item display="flex" alignItems="center">
                  <BackgroundLetterAvatars
                    user={userData}
                    sx={{ width: 32, height: 32 }}
                  />
                  <Box ml={1} display="flex" flexWrap="wrap">
                    <Typography
                      variant="body1"
                      color="dark.800"
                      fontSize={14}
                      fontWeight={700}
                      sx={{
                        lineHeight: "24px",
                        marginBottom: "2px",
                        letterSpacing: "0.17px",
                        display: "block",
                        width: "100%",
                      }}
                    >
                      {getFullName(userData)}
                    </Typography>
                    {userData?.is_dedicated_developer ?
                      <Typography
                        color="#9747FF"
                        fontSize={12}
                        fontWeight={500}
                        sx={{
                          display: "inline-block",
                          mr: 0.5,
                          letterSpacing: "0.15px",
                        }}
                      >
                        Dedicated
                      </Typography> : null}
                  </Box>
                </Grid>
                <Grid Item display="flex" alignItems="center">
                  <Typography
                    color="dark.800"
                    fontSize={14}
                    fontWeight={500}
                    sx={{ lineHeight: "24px", letterSpacing: "0.17px" }}
                  >
                    {titleCase(userData?.role)}
                  </Typography>
                  <Chip
                    label={`${applyDays} day`}
                    sx={{
                      marginLeft: "16px",
                      borderRadius: "3px",
                      color: "rgba(0, 0, 0, 0.87)",
                      fontWeight: "500",
                      span: { padding: "0px 9px" },
                    }}
                  />
                </Grid>
              </Box>
              {actionType !== LEAVE_STATUS.Delete ? <div style={{ width: "100%", paddingTop: "8px" }}>
                <Input
                  label="Add Comment"
                  variant="standard"
                  size="medium"
                  sx={{ mb: 1, width: "100%" }}
                  value={comment}
                  onChange={handleComment}
                  helperText={commentError}
                  error={!!commentError}
                />
                <Typography
                  color="dark.800"
                  fontSize={14}
                  fontWeight={400}
                  sx={{ lineHeight: "24px", letterSpacing: "0.15px" }}
                >
                  <b>Note:</b> Some text to inform that this will be visible to
                  XYZ person.
                </Typography>
              </div> : null}
            </Stack>
          </Grid>
          <Grid Item py={2} px={3} borderTop="1px solid #E2E4EC">
            <Stack spacing={1} direction="row">
              <Button
                variant="contained"
                color={LEAVE_ACTION[actionType]}
                size="medium"
                fontWeight="500"
                sx={{
                  bgcolor: actionType === LEAVE_STATUS.Delete ? 'text.primary' : LEAVE_ACTION[actionType], color: "white", textTransform: "none", boxShadow: 'none !important',
                  '&:hover': {
                    bgcolor: actionType === LEAVE_STATUS.Delete ? '#000000' : '',
                  }
                }}
                onClick={onApprove}
                isButtonLoading={isButtonLoading}
                disabled={isButtonLoading}
              >
                {submitButtonContent?.length ? submitButtonContent : actionType === LEAVE_STATUS.Approved ? "Approve" : 'Reject'}
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={onCancel}
                disabled={isButtonLoading}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal >
    </>
  );
};

export default CustomModal;
