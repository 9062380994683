/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import useAuthentication from '../../../../hook/useAuthentication';
import CircularLoader from '../../../../components/CircularLoader';
import Input from '../../../../components/Input';
import { sortArrayByKey, titleCase } from '../../../../utils';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import { useAlert } from '../../../../hook/useAlert';
import AutocompleteSelect from '../../../../components/AutocompleteSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const UserInformation = ({ userData: user }) => {
    const { userId } = useParams();
    const classes = useStyles();
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const [isEditable, setIsEditable] = useState(false);
    const [userData, setUserData] = useState({ ...user, });
    const [orgUserData, setOrgUserData] = useState({ ...user });
    const [constantValues, setConstantValues] = useState({
        userRolesData: [],
        workTypeData: [],
        userTypeData: [],
        organizationUserList: [],
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
    });

    const fetchData = async (userId) => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));

            const [userTypesResponse, workTypesResponse, userRolesResponse, organizationUsersResponse] = await Promise.all([
                axiosInstance.get(API.getUserTypes),
                axiosInstance.get(API.getWorkTypesByOrganisationId(currentUser?.organization_id)),
                axiosInstance.get(API.organisationUserRoles(currentUser?.organization_id)),
                axiosInstance.get(`${API.organisationUserSetting(currentUser?.organization_id)}`),
            ]);

            if (userTypesResponse.status === 200) {
                setConstantValues((prev) => ({ ...prev, userTypeData: userTypesResponse.data.data }));
            }

            if (workTypesResponse.status === 200) {
                setConstantValues((prev) => ({ ...prev, workTypeData: workTypesResponse.data.data }));
            }

            if (userRolesResponse.status === 200) {
                let adminType = userTypesResponse.data.data?.find(item => item?.name?.toLowerCase() === "admin");
                if (adminType?.id !== user?.current_user_type_id) {
                    setConstantValues((prev) => ({
                        ...prev,
                        userRolesData: userRolesResponse.data.data.map((item) => {
                            if (item?.name?.toLowerCase() === "administrator") {
                                return {
                                    ...item,
                                    isVisible: false,
                                    isSelected: false,
                                }
                            } else {
                                return {
                                    ...item,
                                    isVisible: true,
                                    isSelected: false,
                                }
                            }
                        }),
                    }));
                } else {
                    setConstantValues((prev) => ({
                        ...prev,
                        userRolesData: userRolesResponse.data.data.map((item) => ({
                            ...item,
                            isVisible: true,
                            isSelected: false,
                        })),
                    }));
                }

            }

            if (organizationUsersResponse.status === 200) {
                let sortedData = sortArrayByKey(organizationUsersResponse.data.data, 'asc', 'name')?.filter((value) => value?.id !== user?.id);
                setConstantValues((prev) => ({
                    ...prev,
                    organizationUserList: sortedData?.map((value) => ({
                        id: value?.id,
                        name: value?.name,
                        email: value?.email,
                        profile_img: value?.profile_img,
                        organization_id: value?.organization_id,
                    }))
                }));
            }

            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    useEffect(() => {
        if (userId) {
            fetchData(userId);
        }
    }, [userId]);


    const handleCancel = () => {
        toggleEdit(isEditable)
    }

    const validateFormData = (object) => {
        let errors = {};
        if (!object?.name) {
            errors['name'] = "Required";
        } else {
            errors['name'] = "";
        }
        if (!object?.current_user_type_id) {
            errors['current_user_type_id'] = "Required";
        } else {
            errors['current_user_type_id'] = "";
        }
        if (!object?.org_work_type_setting_id) {
            errors['org_work_type_setting_id'] = "Required";
        } else {
            errors['org_work_type_setting_id'] = "";
        }
        if (!object?.current_user_role_id) {
            errors['current_user_role_id'] = "Required";
        } else {
            errors['current_user_role_id'] = "";
        }
        setErrors((prev) => ({ ...prev, ...errors }));
        return Object.values(errors)?.filter((value) => !!value)?.length > 0;
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading((prev) => ({ ...prev, formSubmitting: true }))
            if (!validateFormData(userData)) {
                const body = {
                    "name": userData?.name,
                    "reporting_authorities": userData?.reporting_authorities?.length > 0 ? [userData?.reporting_authorities?.[0]?.id] : null,
                    "second_reporting_authorities": userData?.second_reporting_authorities?.length ? [userData?.second_reporting_authorities?.[0]?.id] : null,
                    "current_user_type_id": userData?.current_user_type_id,
                    "current_user_role_id": userData?.current_user_role_id,
                    "org_work_type_setting_id": userData?.org_work_type_setting_id,
                    "is_admin": userData?.is_admin,
                    "is_dedicated_developer": userData?.is_dedicated_developer,
                };
                let response = await axiosInstance.put(API.fetchUserDetails(userId), body);
                if (response.status === 200) {
                    setLoading((prev) => ({ ...prev, formSubmitting: false }));
                    setOrgUserData(userData);
                    showAlert(response.data.message);
                    setIsEditable((prev) => !prev);
                } else {
                    setLoading((prev) => ({ ...prev, formSubmitting: false }));
                    showAlert(response?.response?.data?.message, "error");
                    setIsEditable((prev) => !prev);
                }
            } else {
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        } catch (error) {
            console.error(error)
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }

    }

    const handleBlur = (e) => {
        let { name, value } = e.target;
        setErrors(prev => ({
            ...prev,
            [name]: !(value) ? "Required" : "",
        }))
    }

    const isDisabledCheckBox = (userData, type) => {
        if (constantValues?.userTypeData?.length > 0) {
            if (type === "is_admin") {
                let updatedData = {
                    ...userData,
                };
                let generalType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "general");
                if (generalType?.id === updatedData?.current_user_type_id) {
                    return true;
                }
            }
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        let updatedData = {
            ...userData,
            [name]: ['reporting_authorities', 'second_reporting_authorities'].includes(name) ? !!value ? [value] : null : value,
        }
        if (name === "current_user_type_id") {
            let adminType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin");
            let generalType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "general");
            let adminRole = constantValues?.userRolesData?.find((value) => value?.name?.toLowerCase() === "administrator");
            if (adminType?.id === value) {
                updatedData['current_user_role_id'] = adminRole?.id;
                updatedData['is_admin'] = true;
                updatedData['is_admin_user'] = true;
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id === adminRole?.id ? { ...item, isVisible: true, isSelected: true, } : { ...item, isVisible: false })
                }));
                setErrors((prev) => ({ ...prev, current_user_role_id: "" }));
                setUserData(updatedData)
            } else if (generalType?.id === value) {
                updatedData['is_admin'] = false;
                updatedData['is_admin_user'] = false;
                updatedData['is_general_user'] = true;
                updatedData['current_user_role_id'] = null;
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
                }));
            } else {
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
                }));
                updatedData['is_admin'] = true;
                updatedData['is_admin_user'] = false;
                updatedData['is_general_user'] = false;
                updatedData['current_user_role_id'] = null;
                setUserData(updatedData)
            }
        }
        if (['reporting_authorities', 'second_reporting_authorities'].includes(name)) {
            let reporting_authorities = updatedData?.reporting_authorities?.length > 0 ? updatedData?.reporting_authorities[0] : null;
            let second_reporting_authorities = updatedData?.second_reporting_authorities?.length > 0 ? updatedData?.second_reporting_authorities[0] : null;
            if (reporting_authorities && second_reporting_authorities === null) {
                setErrors(prev => ({
                    ...prev,
                    reporting_authorities: "",
                    second_reporting_authorities: "",
                }))
            } else if (reporting_authorities === null && second_reporting_authorities) {
                setErrors(prev => ({
                    ...prev,
                    reporting_authorities: "Please select reporting authorities",
                    second_reporting_authorities: "",
                }))
            } else if (reporting_authorities && second_reporting_authorities) {
                if (reporting_authorities?.id === second_reporting_authorities?.id) {
                    setErrors(prev => ({
                        ...prev,
                        [name]: "Please select another reporting authority",
                    }))
                } else {
                    setErrors(prev => ({
                        ...prev,
                        reporting_authorities: "",
                        second_reporting_authorities: "",
                    }))
                }
            } else {
                setErrors(prev => ({
                    ...prev,
                    reporting_authorities: "",
                    second_reporting_authorities: "",
                }))
            }
        } else {
            setErrors(prev => ({
                ...prev,
                [name]: !value ? "Required" : "",
            }))
        }
        setUserData(updatedData);
    }

    const toggleEdit = (isEdit) => {
        if (isEdit) {
            setUserData(orgUserData);
        }
        setIsEditable((prev) => !prev);
    };

    const getWorkType = (id) => {
        return constantValues?.workTypeData?.find((item) => item?.id === id)
    }

    const getUserType = (id) => {
        return constantValues?.userTypeData?.find((item) => item?.id === id)
    }

    const getUserRole = (id) => {
        return constantValues?.userRolesData?.find((item) => item?.id === id)
    }

    return (

        <Box container >
            {loading.pageLoading ? <CircularLoader height="500px" /> :
                <React.Fragment>
                    <Grid container display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
                        <Grid Item>
                            <Typography
                                variant="h6"
                                fontSize={20}
                                fontWeight={400}
                                color="dark.800"
                                sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                            >
                                User's Details
                            </Typography>
                        </Grid>
                        {!isEditable ? <Grid Item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                sx={{ color: "white", boxShadow: "none", textTransform: "none" }}
                                onClick={() => toggleEdit(isEditable)}
                            >
                                Edit
                            </Button>
                        </Grid> : null}
                    </Grid>
                    {!isEditable ?
                        <Grid container width={780} maxWidth="100%">
                            <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Name</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{titleCase(userData?.name)}</Typography>
                            </Grid>
                            <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>User Type</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{titleCase(getUserType(userData?.current_user_type_id)?.name)}</Typography>
                            </Grid>
                            <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>User Role</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{getUserRole(userData?.current_user_role_id)?.name}</Typography>
                            </Grid>
                            <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Work Type</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{getWorkType(userData?.org_work_type_setting_id)?.name}</Typography>
                            </Grid>
                            <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Dedicated Developer?</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{userData?.is_dedicated_developer ? "Yes" : "No"}</Typography>
                            </Grid>
                            <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Access to Admin Panel</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{userData?.is_admin ? "Yes" : "No"}</Typography>
                            </Grid>
                            {userData?.reporting_authorities?.length > 0 ? <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Reporting Authorities</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{titleCase(userData?.reporting_authorities?.[0]?.name)}</Typography>
                            </Grid> : null}
                            {userData?.second_reporting_authorities?.length > 0 ? <Grid Item pr={2} sm={4} my={4}>
                                <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Second Reporting Authorities</Typography>
                                <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{titleCase(userData?.second_reporting_authorities?.[0]?.name)}</Typography>
                            </Grid> : null}
                        </Grid> :
                        <form onSubmit={handleSubmit} className="step-form-1" style={{
                            marginTop: 30,
                        }}>
                            <Box direction="row" display="flex" flexWrap="wrap" width={780} maxWidth="100%">
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <Input
                                        id="name"
                                        label="Name *"
                                        variant="standard"
                                        placeholder="Name"
                                        type="text"
                                        name={"name"}
                                        fullWidth
                                        required={false}
                                        value={titleCase(userData?.name)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={errors?.name}
                                        helperText={errors?.name}
                                        disabled={!isEditable}
                                    />
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <Input
                                        name={"current_user_type_id"}
                                        select
                                        type="select"
                                        label="User Type"
                                        defaultValue="Admin"
                                        variant="standard"
                                        sx={{ width: "100%" }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={userData?.current_user_type_id}
                                        error={!!errors?.current_user_type_id}
                                        helperText={errors?.current_user_type_id}
                                        disabled={!isEditable || userData?.is_primary}
                                    >
                                        {constantValues?.userTypeData?.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {titleCase(option.name)}
                                            </MenuItem>
                                        ))}
                                    </Input>
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <Input
                                        select
                                        type="select"
                                        label="User Role"
                                        variant="standard"
                                        name={"current_user_role_id"}
                                        sx={{
                                            width: "100%",
                                            "& .MuiPopover-paper": {
                                                boxShadow:
                                                    "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                                            },
                                        }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={userData?.current_user_role_id}
                                        error={!!errors?.current_user_role_id}
                                        helperText={errors?.current_user_role_id}
                                        disabled={!isEditable || userData?.is_admin_user || userData?.is_primary || constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin")?.id === userData?.current_user_type_id || false}
                                        InputLabelProps={{ shrink: userData?.is_admin_user || userData?.current_user_role_id }}
                                    >
                                        {constantValues?.userRolesData?.map((option) => {
                                            return (
                                                <MenuItem
                                                    selected={option?.isSelected || false}
                                                    key={option.id}
                                                    value={option.id}
                                                    sx={{
                                                        display: (!option?.isVisible) ? "none" : "block",
                                                        "&:hover": {
                                                            background: "rgba(4, 127, 224, 0.1)",
                                                        },
                                                    }}
                                                >
                                                    {titleCase(option.name)}
                                                </MenuItem>
                                            )
                                        })}
                                    </Input>
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <Input
                                        name={"org_work_type_setting_id"}
                                        select
                                        type="select"
                                        label="Work Type"
                                        defaultValue="In-Office"
                                        variant="standard"
                                        sx={{ width: "100%" }}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={userData?.org_work_type_setting_id}
                                        error={!!errors?.org_work_type_setting_id}
                                        helperText={
                                            errors?.org_work_type_setting_id
                                        }
                                        disabled={!isEditable}
                                    >
                                        {constantValues?.workTypeData?.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Input>
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <FormControl
                                        className={classes.margin}
                                        variant="standard"
                                        sx={{
                                            margin: "0px !important",
                                            width: "100%",
                                            // "div ": { marginTop: "2px !important" },
                                        }}
                                    >
                                        <AutocompleteSelect
                                            onChange={handleChange}
                                            options={sortArrayByKey(constantValues?.organizationUserList, 'asc', 'name')}
                                            value={userData?.reporting_authorities?.length > 0 ? userData?.reporting_authorities?.[0] : null}
                                            label={"1st Reporting Person"}
                                            name="reporting_authorities"
                                            id="reporting_authorities"
                                        />
                                        {errors?.reporting_authorities ? <FormHelperText sx={{ color: "red" }}>
                                            {errors?.reporting_authorities}
                                        </FormHelperText> : null}
                                    </FormControl>
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <FormControl
                                        className={classes.margin}
                                        variant="standard"
                                        sx={{
                                            margin: "0px !important",
                                            width: "100%",
                                            // "div ": { marginTop: "2px !important" },
                                        }}
                                    >
                                        <AutocompleteSelect
                                            onChange={handleChange}
                                            options={sortArrayByKey(constantValues?.organizationUserList, 'asc', 'name')}
                                            value={userData?.second_reporting_authorities?.length > 0 ? userData?.second_reporting_authorities?.[0] : null}
                                            label={"2nd Reporting Person"}
                                            name="second_reporting_authorities"
                                            id="second_reporting_authorities"
                                        />
                                        {errors?.second_reporting_authorities ? <FormHelperText sx={{ color: "red" }}>
                                            {errors?.second_reporting_authorities}
                                        </FormHelperText> : null}
                                    </FormControl>
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox checked={userData?.is_dedicated_developer} />
                                        }
                                        label="Is Dedicated Developer"
                                        labelPlacement="end"
                                        sx={{
                                            '.MuiCheckbox-sizeMedium': {
                                                minWidth: '42px'
                                            }
                                        }}
                                        disabled={!isEditable || false}
                                        onChange={(event, checked) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                is_dedicated_developer: checked
                                            }))
                                        }
                                    />
                                </Grid>
                                <Grid width={"50%"} pr={4} pb={4} maxWidth={"100%"}>
                                    <FormControlLabel
                                        value="end"
                                        control={
                                            <Checkbox checked={userData?.is_admin} />
                                        }
                                        label="Access to Admin Panel"
                                        labelPlacement="end"
                                        sx={{
                                            '.MuiCheckbox-sizeMedium': {
                                                minWidth: '42px'
                                            }
                                        }}
                                        disabled={!isEditable || userData?.is_general_user || userData?.is_primary || isDisabledCheckBox(userData, "is_admin") || false}
                                        onChange={(event, checked) =>
                                            setUserData((prev) => ({
                                                ...prev,
                                                is_admin: checked
                                            }))
                                        }
                                    />
                                </Grid>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                mt={4}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    flexBasis="100%"
                                    gap={2}
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        type="submit"
                                        sx={{
                                            boxShadow: "0",
                                            fontSize: "15px",
                                            textTransform: "none",
                                            color: "white",
                                            "&:hover": { boxShadow: "0" },
                                        }}
                                        disabled={!isEditable || Boolean(Object.values(errors)?.filter(value => !!value).length)}
                                        isButtonLoading={loading.formSubmitting}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        size="large"
                                        sx={{
                                            boxShadow: "0",
                                            fontSize: "15px",
                                            textTransform: "none",
                                            "&:hover": { boxShadow: "0" },
                                        }}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </form>}
                </React.Fragment>
            }
        </Box>
    )
}

export default UserInformation;