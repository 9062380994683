import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import "./index.css";
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import customTheme from './assets/theme';
import { store } from './redux/store';
import { AlertProvider } from './hook/useAlert';
import secureLocalStorage from "react-secure-storage";
import Bugsnag from './bugsnag';
import { setUserDetails } from './redux/slices/userSlice';
import 'material-icons/iconfont/material-icons.css';

const user = JSON.parse(secureLocalStorage.getItem('currentUser'));
const token = secureLocalStorage.getItem('token');

if (user && token) {
  store.dispatch(setUserDetails({ user, token }));
}

const root = createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  root.render(
    <>
      <ErrorBoundary>
        <ThemeProvider theme={customTheme}>
          <Provider store={store}>
            <AlertProvider>
              <App />
            </AlertProvider>
          </Provider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
} else {
  root.render(
    <>
      <ThemeProvider theme={customTheme}>
        <Provider store={store}>
          <AlertProvider>
            <App />
          </AlertProvider>
        </Provider>
      </ThemeProvider>
    </>
  );
}
