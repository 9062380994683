import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import URLS from "../routes/urls";
import { toast } from "react-toastify";
import { API_ERROR_CODE, EXCLUDED_APIS } from "../constants/default-values";
import { store } from "../redux/store";
import { clearStorage } from "../redux/slices/userSlice";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    const token = secureLocalStorage.getItem("token");
    config.baseURL = process.env.REACT_APP_API_ENDPOINT;
    if (!!token && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

if (!window.previousPath) {
  window.previousPath = '';
}

const setPreviousPath = (path) => {
  window.previousPath = path;
};

const getPreviousPath = () => {
  return window.previousPath;
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    const token = response.config.headers["Authorization"];
    if (token) {
      const [, tokenValue] = token.split(" ");
      secureLocalStorage.setItem("token", tokenValue);
      // store.dispatch(setUserDetails({ token: tokenValue, }))
    }

    if (response?.data?.['access_denied']) {
      window.location.href = URLS.AccessDenied;
    }

    return response;
  },
  function (error) {
    // Initialize previous path on the window object
    const previousPath = getPreviousPath();
    if (EXCLUDED_APIS.includes(error?.config?.url)) {
      return error.response;
    }
    else {
      if (API_ERROR_CODE?.includes(error?.code)) {
        if (previousPath !== window.location.pathname) {
          setPreviousPath(window.location.pathname);
          toast.error("Something went wrong. Please try again later");
        }
      }
      else if (error.response.status === 401) {
        store.dispatch(clearStorage())
        window.location.href = URLS.Login;
      } else if (error.response.status === 403) {
        const currentUser = secureLocalStorage.getItem("currentUser");
        let user = JSON.parse(currentUser);
        if (['hr', 'developer', 'sales', 'administrator'].includes(user?.user_role?.toLowerCase())) {
          window.location.href = currentUser?.user_type === "super_admin" ? URLS.AdminDashboard : URLS.AccessDenied;
        }
      }
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;