import React from "react";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";
import MyWFHRequests from "./MyWFHRequests";
import OthersWFHRequests from "./OthersWFHRequests";
import useQuery from "../../../../hook/useQuery";
import { GROUP_FILTER, OTHERS_LEAVE_FILTER } from "../../../../constants/default-values";
import { usePageTitle } from "../../../../hook/useTitle";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const WorkFromHome = () => {
  let { getQueryParams, setQueryParams, deleteQueryParams } = useQuery();
  const { setPageTitle } = usePageTitle();

  setPageTitle("WFH / Timing Request");
  let initialTabValue = parseInt(getQueryParams("tabValue")) || 0;

  const handleChange = (event, newValue) => {
    setQueryParams("view", "list");
    setQueryParams("tabValue", newValue);
    setQueryParams("groupBy", Object.values(GROUP_FILTER)[0]);
    setQueryParams("status", Object.keys(OTHERS_LEAVE_FILTER)[0]);
    deleteQueryParams("filterStatus");
  };

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: 'white', position: 'sticky', top: 0, zIndex: 5 }}>
        <Tabs
          value={initialTabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Others’ WFH Requests" {...a11yProps(0)} />
          <Tab label="My WFH Requests" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box bgcolor="secondary"
        flex={1}
        overflow="auto"
        sx={{
          ".tabspanel > .MuiBox-root": {
            padding: "0px",
          }
        }}
      >
        <CustomTabPanel value={initialTabValue} index={0} className="tabspanel">
          <OthersWFHRequests />
        </CustomTabPanel>
        <CustomTabPanel value={initialTabValue} index={1} className="tabspanel">
          <MyWFHRequests />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default WorkFromHome;