/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ICONS from "../../../../constants/icons";
import { styled } from "@mui/styles";
// import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
// import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  GROUP_FILTER,
  OTHERS_LEAVE_FILTER,
  STATUS_BY_FILTER,
  REQUEST_TYPE
} from "../../../../constants/default-values";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import CircularLoader from "../../../../components/CircularLoader";
import { getAllLeaveDates, getApplicationNumber, getApplyDateTime, getFirstLetterOfString, getFullName, getLeaveDateArray, getLeaveDates, getLeaveStatus, isInPastDays, isMoreThan30Days, isWithinNext30Days, isWithinNext7Days, sortRequestArrayByDate, titleCase } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../routes/urls";
import useQuery from "../../../../hook/useQuery";
import CalendarView from "./LeaveListing/CalendarView";
import EmptyPage from "../../../../components/EmptyPage";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#3F5C76",
  },
}));

const OthersLeave = () => {
  const { getCurrentUser } = useAuthentication();
  const { setQueryParams, getQueryParams, deleteQueryParams } = useQuery();
  let view = getQueryParams("view") || "list";
  let groupBy = getQueryParams("groupBy") && Object.values(GROUP_FILTER)?.includes(getQueryParams("groupBy")) ? getQueryParams("groupBy") : Object.values(GROUP_FILTER)[0];
  let status = getQueryParams("status") && Object.keys(OTHERS_LEAVE_FILTER)?.includes(getQueryParams("status")) ? getQueryParams("status") : Object.keys(OTHERS_LEAVE_FILTER)[0];
  let filterStatus = getQueryParams("filterStatus") && Object.values(STATUS_BY_FILTER)?.includes(getQueryParams("filterStatus")) ? getQueryParams("filterStatus") : Object.values(STATUS_BY_FILTER)[0];
  let tabValue = parseInt(getQueryParams("tabValue")) || 0;
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [othersLeaveData, setOthersLeaveData] = useState({});
  const [othersLeaveDataForCalendar, setOthersLeaveDataForCalendar] = useState([]);
  const [optionalOthersLeaveData, setOptionalOthersLeaveData] = useState([]);
  const [isLoading, setIsLoading] = useState({
    dataLoading: true,
    pageLoading: true,
  });
  const [openAccordianList, setOpenAccordianList] = useState([]);

  useEffect(() => {
    setQueryParams("view", view);
    setQueryParams("tabValue", tabValue);
    setQueryParams("groupBy", groupBy);
    setQueryParams("status", status);
    if (groupBy !== GROUP_FILTER?.Status) setQueryParams("filterStatus", filterStatus);
  }, [])

  useEffect(() => {
    getDataAccordingToGroupByFilters(optionalOthersLeaveData);
  }, [groupBy, optionalOthersLeaveData, filterStatus]);

  useEffect(() => {
    if (status)
      getOthersLeaveData(status);
  }, [status])

  const getOthersLeaveData = async (status) => {
    try {
      setIsLoading((prev) => ({ ...prev, dataLoading: true }));
      let queryParams = {
        page: 1,
        limit: 100,
        status: status,
      };
      const response = await axiosInstance.get(
        API.getOthersLeaves(currentUser.id),
        {
          params: queryParams,
        }
      );
      if (response.status === 200) {
        if (response.data?.results?.length) {
          let sortedArray = sortRequestArrayByDate(response?.data?.results, "leave_days", "leave_date");
          setOthersLeaveDataForCalendar(sortedArray);
          setOptionalOthersLeaveData(sortedArray);
          getDataAccordingToGroupByFilters(sortedArray);
        } else {
          setOthersLeaveData({});
          setOthersLeaveDataForCalendar([]);
          setOptionalOthersLeaveData([]);
        }
      } else {
        setOthersLeaveData({});
        setOthersLeaveDataForCalendar([]);
        setOptionalOthersLeaveData([]);
      }
      setIsLoading((prev) => ({ ...prev, dataLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, dataLoading: false }));
      setOthersLeaveData({});
      setOthersLeaveDataForCalendar([]);
      setOptionalOthersLeaveData([]);
    }
  };

  const getDataAccordingToGroupByFilters = (data) => {
    if (data?.length) {
      if (groupBy === GROUP_FILTER["Status"]) {
        if (data?.length) {
          let statusData = REQUEST_TYPE?.reduce((result, type) => {
            let filteredTypeData = data?.filter((leave) => leave?.status === type);
            result[type] = filteredTypeData;
            return result;
          }, {});

          let accordian = []
          if (Object.values(statusData)?.some(data => data?.length)) {
            for (let key = 0; key < REQUEST_TYPE?.length; key++) {
              if (statusData[REQUEST_TYPE[key]]?.length) {
                accordian.push(REQUEST_TYPE[key])
                break;
              }
            }
          }
          setOpenAccordianList(accordian)
          setOthersLeaveData(statusData);

          setTimeout(() => {
            setIsLoading(prev => ({ ...prev, dataLoading: false }));
          }, 1000);
        } else {
          setOthersLeaveData({});
          setOpenAccordianList([]);
          setIsLoading(prev => ({ ...prev, dataLoading: false }));
        }
      } else if (groupBy === GROUP_FILTER["Leave Date"]) {
        let leaveDateArray = [];
        let organizedLeaves = {};

        if (status === Object.keys(OTHERS_LEAVE_FILTER)[0]) {
          leaveDateArray = [
            "Next 7 days",
            "Next 30 days",
            "More than 30 days",
          ];
          organizedLeaves = {
            "Next 7 days": [],
            "Next 30 days": [],
            "More than 30 days": [],
          };
        } else {
          leaveDateArray = [
            "Past Leaves",
          ];
          organizedLeaves = {
            "Past Leaves": [],
          };
        }

        let filteredData = [...optionalOthersLeaveData]
        if (filterStatus !== Object.values(STATUS_BY_FILTER)[0]) filteredData = optionalOthersLeaveData?.filter((leaveData) => leaveData?.status === filterStatus.toLocaleLowerCase())

        leaveDateArray?.map((key) => {
          filteredData?.map((userData) => {
            const filteredLeaves = userData?.leave_days?.filter((leave) => {
              if (key === "Past Leaves") {
                return isInPastDays(leave?.leave_date);
              } else if (key === "Next 7 days") {
                return isWithinNext7Days(leave?.leave_date);
              } else if (key === "Next 30 days") {
                return isWithinNext30Days(leave?.leave_date);
              } else if (key === "More than 30 days") {
                return isMoreThan30Days(leave?.leave_date);
              }
            });
            if (filteredLeaves?.length > 0) {
              organizedLeaves[key].push({
                ...userData,
                leaveData: filteredLeaves,
              });
            }
          });
        });
        if (Object.values(organizedLeaves)?.some(data => data?.length)) {
          let keyArray = [
            "Next 7 days",
            "Next 30 days",
            "More than 30 days",
          ];
          let accordian = [];
          if (status === Object.keys(OTHERS_LEAVE_FILTER)[0]) {
            for (let key = 0; key < keyArray?.length; key++) {
              if (!organizedLeaves[keyArray[key]]?.length) {
                accordian.push(keyArray[key])
              } else {
                accordian.push(keyArray[key])
                break;
              }
            }
          } else {
            accordian = ['Past Leaves'];
          }
          setOpenAccordianList(accordian);
        } else {
          setOpenAccordianList([]);
        }

        setOthersLeaveData(organizedLeaves);
        setTimeout(() => {
          setIsLoading(prev => ({ ...prev, dataLoading: false }));
        }, 1000);
      } else if (
        [
          GROUP_FILTER["Departments"],
          GROUP_FILTER["Leave Type"],
          GROUP_FILTER["Work Type"],
        ].includes(groupBy)
      ) {
        let keyToFilter =
          groupBy === "Departments"
            ? "user_role"
            : groupBy === "Leave Type"
              ? "leave_type"
              : "work_type";

        let filteredData = [...optionalOthersLeaveData]
        if (filterStatus !== Object.values(STATUS_BY_FILTER)[0])
          filteredData = optionalOthersLeaveData?.filter((leaveData) => leaveData?.status === filterStatus.toLocaleLowerCase())

        let departmentsData = filteredData?.reduce((result, item) => {
          const _key = item[keyToFilter];
          if (!result[_key]) {
            result[_key] = [];
          }
          result[_key].push(item);
          return result;
        }, {});
        if (Object.keys(departmentsData)?.length) {
          setOpenAccordianList([Object.keys(departmentsData)?.[0]])
        }
        setOthersLeaveData(departmentsData);
        setTimeout(() => {
          setIsLoading(prev => ({ ...prev, dataLoading: false }));
        }, 1000);
      }
    }
  };

  const handleChange = (event) => {
    setQueryParams("groupBy", event.target.value);
    if (event.target.value === GROUP_FILTER?.Status)
      deleteQueryParams("filterStatus")
    else
      setQueryParams("filterStatus", filterStatus);
  };

  const handleFilterChange = (e) => {
    setQueryParams("status", e.target.name);
  };

  const handleStatusChange = (e) => {
    setQueryParams("filterStatus", e.target.value);
  }

  const calculateLeaveCount = (leaveData) => {
    let total_leave = leaveData?.reduce((total, leave) => {
      if (leave.leave_duration === "full") {
        return total + 1;
      } else if (leave.leave_duration.includes("half")) {
        return total + 0.5;
      } else {
        return total;
      }
    }, 0);
    return total_leave;
  };

  const handleLeaveClick = (leave) => {
    let { user_id, id } = leave;
    navigate(`${URLS.OtherLeaveDetails}/${user_id}/${id}`);
  };

  const hasAttachmentInList = () => {
    if (optionalOthersLeaveData?.length) {
      return optionalOthersLeaveData?.some(leave => leave?.attachment_references?.length);
    }
  }

  return (
    <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}>
      <Stack spacing={{ xs: 2, lg: 3 }}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid Item display="flex" alignItems="flex-end">
            <FormControl
              variant="standard"
              sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Group By
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={groupBy || ""}
                onChange={(e) => handleChange(e)}
                label="Group By"
              >
                {Object.values(GROUP_FILTER)?.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {groupBy !== GROUP_FILTER?.Status ? <FormControl
              variant="standard"
              sx={{ mr: { xs: 2, lg: 3, xl: 4 }, minWidth: { xs: 160, md: 180 }, maxWidth: { xs: 160, md: 180 } }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Filter By Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={filterStatus || ""}
                onChange={(e) => handleStatusChange(e)}
                label="Filter By Status"
              >
                {Object.values(STATUS_BY_FILTER)?.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> : null}
          </Grid>
          <Grid Item display="flex" justifyContent={"flex-end"}>
            <ButtonGroup
              variant="outlined"
              aria-label="Basic button group"
              color="secondary"
              fontSize={14}
              sx={{ mr: { xs: 1, lg: 2, xl: 3 } }}
            >
              {Object.entries(OTHERS_LEAVE_FILTER)?.map((filter, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  color={status === filter[0] ? "primary" : "secondary"}
                  name={filter[0]}
                  sx={{
                    textTransform: "none",
                    background: `${status === filter[0] ? "rgba(4, 127, 224, 0.1)" : "none"
                      }`,
                    borderColor: `${status === filter[0] ? "#047FE0 !important" : "none"
                      }`,
                    position: "relative",
                    zIndex: `${status === filter[0] ? "1" : "none"}`,
                    paddingLeft: { xs: '10px', md: '15px' },
                    paddingRight: { xs: '10px', md: '15px' }
                  }}
                  onClick={(e) => handleFilterChange(e)}
                >
                  {filter[1]}
                </Button>
              ))}
            </ButtonGroup>
            {/* <Stack spacing={2} direction="row">
              <ButtonGroup
                variant="outlined"
                aria-label="Basic button group"
                color="secondary"
                fontSize={14}
              >
                <Button sx={{
                  textTransform: "none",
                  background: view === "list" ? "rgba(4, 127, 224, 0.1)" : "",
                  borderColor: view === "list" ? "#047FE0 !important" : "",
                  color: view === "list" ? "primary.main" : "",
                  paddingLeft: { xs: '8px', md: '15px' },
                  paddingRight: { xs: '8px', md: '15px' }
                }}
                  onClick={() => setQueryParams("view", "list")}
                >
                  <i style={{ height: 20, width: 20 }}>
                    <ListAltRoundedIcon sx={{ fontSize: 20 }} />
                  </i>
                </Button>
                <Button sx={{
                  textTransform: "none",
                  background: view === "calendar" ? "rgba(4, 127, 224, 0.1)" : "",
                  borderColor: view === "calendar" ? "#047FE0 !important" : "",
                  color: view === "calendar" ? "primary.main" : "",
                  paddingLeft: { xs: '8px', md: '15px' },
                  paddingRight: { xs: '8px', md: '15px' }
                }}
                  disabled={true}
                  onClick={() => setQueryParams("view", "calendar")}
                >
                  <i style={{ height: 20, width: 20 }}>
                    <CalendarTodayRoundedIcon sx={{ fontSize: 20 }} />
                  </i>
                </Button>
              </ButtonGroup>
            </Stack> */}
          </Grid>
        </Grid>
        {isLoading?.dataLoading ?
          <CircularLoader height="500px" /> :
          view === "calendar" ? (
            <CalendarView leaveDataList={othersLeaveDataForCalendar} />
          ) : (<Grid container>
            <Grid Item sx={{ width: "100%" }}>
              <Stack spacing={1}>
                {(!!othersLeaveData && Object?.keys(othersLeaveData)?.length > 0)
                  ? Object?.entries(othersLeaveData)?.map((otherLeave, index) => (
                    <Accordion
                      key={index}
                      expanded={openAccordianList?.includes(otherLeave[0])}
                      sx={{
                        width: "100%",
                        background: "#EBEFF3",
                        border: "1px solid rgba(0, 0, 0, 0.08)",
                        boxShadow: "none",
                        borderRadius: "5px",
                        margin: "8px 0px 0px 0px !important",
                        "&:first-child": {
                          margin: "0px !important",
                        },
                        "&:before": {
                          display: "none",
                        },
                        ".MuiAccordionSummary-root": {
                          minHeight: "42px",
                          flexDirection: "row-reverse",
                        },
                        ".MuiAccordionSummary-root.Mui-expanded": {
                          minHeight: "42px !important",
                        },
                        ".MuiAccordionSummary-content": {
                          margin: "13px 0px 13px 8px",
                        },
                        ".MuiAccordionSummary-content.Mui-expanded": {
                          margin: "8px 0px 0px 8px",
                        },
                        ".MuiAccordionDetails-root": {
                          paddingTop: "0px",
                        },
                        ".MuiAccordion-region> div": {
                          paddingBottom: "8px",
                        },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                          transform: 'rotate(-90deg)',
                        },
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                          transform: 'rotate(0deg)',
                        }
                      }}
                      elevation={1}
                    >
                      <AccordionSummary
                        expandIcon={
                          <i
                            style={{
                              height: "20px",
                              width: "20px",
                            }}
                          >
                            {ICONS.ChevronLineSmall}
                          </i>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                        onClick={() => setOpenAccordianList((prev) => prev?.includes(otherLeave[0]) ? prev?.filter(list => list !== otherLeave[0]) : [...prev, otherLeave[0]])}
                      >
                        <Typography
                          color="dark.800"
                          fontSize={16}
                          lineHeight="24px"
                          fontWeight={500}
                        >
                          {titleCase(otherLeave[0])}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer>
                          <Table
                            aria-label="simple table"
                            sx={{
                              borderCollapse: "separate",
                              borderSpacing: "0px 8px",
                            }}
                          >
                            <TableBody>
                              {(!!otherLeave?.length && otherLeave[1]?.length) ? otherLeave[1]?.map((userData, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    td: {
                                      border: 0,
                                      bgcolor: "white",
                                      padding: { xs: "8px 8px", md: "8px 16px" },
                                    },
                                    'td:first-child': {
                                      borderRadius: "4px 0px 0px 4px",
                                    },
                                    'td:last-child': {
                                      borderRadius: "0px 4px 4px 0px",
                                    },
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleLeaveClick(userData)}
                                >
                                  <TableCell
                                    sx={{
                                      width: "250px",
                                      minWidth: "250px",
                                      maxWidth: "250px",
                                    }}
                                  >
                                    <Grid
                                      Item
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <BackgroundLetterAvatars
                                        user={userData?.user_info}
                                        src={userData?.user_info?.profile_img}
                                        sx={{ width: 32, height: 32 }}
                                      />
                                      <Box
                                        ml={1}
                                        display="flex"
                                        flexWrap="wrap"
                                      >
                                        <Typography
                                          variant="body1"
                                          color="secondary"
                                          fontSize={14}
                                          fontWeight={500}
                                          sx={{
                                            lineHeight: "24px",
                                            letterSpacing: "0.17px",
                                            marginBottom: "2px",
                                            display: "block",
                                            width: "100%",
                                          }}
                                        >
                                          {getFullName(userData?.user_info)}
                                        </Typography>
                                        {userData?.user_info
                                          ?.is_dedicated_developer ? (
                                          <Typography
                                            color="#9747FF"
                                            fontSize={12}
                                            fontWeight={700}
                                            sx={{
                                              display: "inline-block",
                                              mr: 0.5,
                                            }}
                                          >
                                            Dedicated
                                          </Typography>
                                        ) : (
                                          <Typography
                                            color="primary"
                                            fontSize={12}
                                            fontWeight={700}
                                            sx={{
                                              display: "inline-block",
                                              mr: 0.5,
                                            }}
                                          >
                                            {userData?.work_type}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "150px",
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="dark.800"
                                      lineHeight="14px"
                                      letterSpacing="0.17px"
                                      fontWeight={500}
                                      mb="6px"
                                    >
                                      {getApplicationNumber(userData?.application_num)}
                                      <Chip
                                        label={getFirstLetterOfString(userData?.leave_type)}
                                        color="secondary"
                                        size="small"
                                        variant="filled"
                                        sx={{ marginLeft: '4px' }}
                                      />
                                    </Typography>
                                    <Typography
                                      color="dark.500"
                                      fontSize={10}
                                      lineHeight="15px"
                                      letterSpacing="0.17px"
                                      fontWeight={500}
                                    >
                                      {calculateLeaveCount(
                                        userData?.leave_days
                                      )}{" "}
                                      Days
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      minWidth: "240px",
                                    }}
                                  >
                                    <Typography
                                      color="dark.500"
                                      fontSize={10}
                                      lineHeight="15px"
                                      letterSpacing="0.17px"
                                      fontWeight={500}
                                      mb="6px"
                                    >
                                      Date
                                    </Typography>
                                    <Grid
                                      Item
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="body2"
                                        color="dark.800"
                                        lineHeight="14px"
                                        letterSpacing="0.17px"
                                        fontWeight={500}
                                        mr="6px"
                                        whiteSpace="nowrap"
                                      >
                                        {getLeaveDates(getLeaveDateArray(userData?.leave_days, 'leave_date'))}
                                      </Typography>
                                      <HtmlTooltip
                                        title={
                                          <React.Fragment>
                                            <Typography color="white" mb="10px">
                                              {calculateLeaveCount(
                                                userData?.leave_days
                                              )}{" "}
                                              Leaves on
                                            </Typography>
                                            <List
                                              sx={{
                                                padding: 0,
                                                li: { padding: 0 },
                                                "li span": {
                                                  color: "white",
                                                  fontSize: 12,
                                                  fontWeight: 500,
                                                  lineHeight: "19px",
                                                },
                                              }}
                                            >
                                              {getAllLeaveDates(
                                                userData?.leave_days,
                                                true
                                              )?.map((leaveDate, index) => (
                                                <ListItem key={index}>
                                                  <ListItemText>
                                                    {leaveDate}
                                                  </ListItemText>
                                                </ListItem>
                                              ))}
                                            </List>
                                          </React.Fragment>
                                        }
                                      >
                                        <i
                                          style={{
                                            height: 18,
                                            maxWidth: 18,
                                            flex: "0 0 18px",
                                          }}
                                        >
                                          <InfoRoundedIcon
                                            color="secondary"
                                            sx={{ fontSize: 18 }}
                                          />
                                        </i>
                                      </HtmlTooltip>
                                    </Grid>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "200px",
                                      minWidth: "200px",
                                      maxWidth: "200px",
                                    }}
                                  >
                                    <Typography
                                      color="dark.500"
                                      fontSize={10}
                                      lineHeight="15px"
                                      letterSpacing="0.17px"
                                      fontWeight={500}
                                      mb="6px"
                                    >
                                      Applied on{" "}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="dark.800"
                                      lineHeight="14px"
                                      letterSpacing="0.17px"
                                      fontWeight={500}
                                    >
                                      {getApplyDateTime(userData?.createdAt || userData?.apply_date)}
                                    </Typography>
                                  </TableCell>
                                  {hasAttachmentInList() ?
                                    <TableCell
                                      sx={{
                                        width: "70px",
                                        minWidth: "70px",
                                        maxWidth: "70px",
                                      }}
                                    >
                                      {userData?.attachment_references?.length ? <Box
                                        sx={{
                                          alignItems: 'center',
                                          color: "secondary",
                                          display: "flex",
                                        }}
                                      >
                                        <Box
                                          sx={{ alignItems: 'center' }}
                                        >
                                          <Typography
                                            variant="body1"
                                            color="secondary"
                                            fontSize={14}
                                            fontWeight={500}
                                            sx={{
                                              lineHeight: "24px",
                                              letterSpacing: "0.17px",
                                              marginBottom: "2px",
                                              display: "block",
                                              width: "100%",
                                            }}
                                          >
                                            <i
                                              style={{
                                                height: "18px",
                                                width: "18px",
                                                display: "flex",
                                              }}
                                            >
                                              {ICONS.Attachment}
                                            </i>
                                          </Typography>
                                        </Box>
                                        <Box
                                          sx={{ alignItems: 'center' }}
                                        >
                                          <Typography
                                            variant="body1"
                                            color="secondary"
                                            fontSize={14}
                                            fontWeight={500}
                                            sx={{
                                              lineHeight: "24px",
                                              letterSpacing: "0.17px",
                                              marginBottom: "2px",
                                              display: "block",
                                              width: "100%",
                                            }}
                                          >
                                            {userData?.attachment_references?.length}
                                          </Typography>
                                        </Box>
                                      </Box> : null}
                                    </TableCell>
                                    : null}
                                  <TableCell
                                    sx={{
                                      width: "120px",
                                      minWidth: "120px",
                                      maxWidth: "120px",
                                    }}
                                  >
                                    {getLeaveStatus(userData?.status, "chip")}
                                  </TableCell>
                                </TableRow>
                              )) :
                                <TableRow
                                  key={index}
                                  sx={{
                                    td: {
                                      border: 0,
                                      bgcolor: "white",
                                      textAlign: 'center',
                                      padding: { xs: "8px 8px", md: "8px 16px" },
                                    },
                                    'td:first-child': {
                                      borderRadius: "4px 4px 4px 4px",
                                    },
                                    cursor: 'default'
                                  }}
                                >
                                  <TableCell sx={{ height: '60px' }}>
                                    <Typography
                                      color="dark.800"
                                      fontSize={14}
                                      lineHeight="24px"
                                      fontWeight={500}
                                    >
                                      No Leaves Available
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  ))
                  :
                  (!isLoading?.dataLoading && Object?.keys(othersLeaveData)?.length === 0) ?
                    <EmptyPage
                      isButtonShow={false}
                      title="No leaves available"
                    />
                    : null
                }
              </Stack>
            </Grid>
          </Grid>)
        }
      </Stack>
    </Box>
  );
};

export default OthersLeave;
