import { useLocation, useNavigate } from "react-router-dom";


const useQuery = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const getAllQueryParams = () => {

        const params = {};
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        return params;
    };

    const getQueryParams = (key) => {
        return searchParams.get(key);
    }

    const setQueryParams = (key, value) => {
        searchParams.set(key, value);
        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, {
            replace: true
        });
    }

    const deleteQueryParams = (deletableKey) => {
        searchParams.delete(deletableKey);
        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        }, {
            replace: true
        });
    }

    return { getAllQueryParams, getQueryParams, setQueryParams, deleteQueryParams };
};

export default useQuery;
