import axiosInstance from ".";
import API from "./api";

export const checkOrganisationUniqueName = async (orgName, orgId = null) => {
  try {
    let params = {
      name: orgName,
      orgId: orgId,
    };
    if (!orgId) {
      delete params.orgId;
    }
    if (orgName?.toString()?.trim()?.length) {
      const response = await axiosInstance.get(
        API.checkOrganisationUniqueName,
        { params }
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

export const changeOrganisationStatus = async (orgId, status) => {
  try {
    if (orgId) {
      const url = API.changeOrganisationStatus(orgId, status);
      const response = await axiosInstance.put(url);
      return response;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

export const handleCheckIsEmailUnique = async (email, userId) => {
  try {
    if (email?.length) {
      const response = await axiosInstance.get(API.isEmailUnique(email, userId));
      return response?.data?.isExist;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    // throw error;
  }
};

export const checkActivityUniqueName = async (activityName, orgId, activityId = null) => {
  try {
    let APIROUTE;
    if (activityId) {
      APIROUTE = `${API.checkActivityUniqueName}/${activityName}/${orgId}/${activityId}`;
    } else {
      APIROUTE = `${API.checkActivityUniqueName}/${activityName}/${orgId}`;
    }
    if (activityName?.toString()?.trim()?.length) {
      const response = await axiosInstance.get(APIROUTE);
      return response.data;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};