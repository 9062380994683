import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultipleDatePicker from "../../../../../components/MultipleDatePicker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import Button from "../../../../../components/Button";
import { convertDateFromFormat, getFormattedDate, isFutureYearDate, sortArrayByKey, titleCase } from "../../../../../utils";
import moment from "moment";
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";
import URLS from "../../../../../routes/urls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Breadcrumb from "../../../../../components/Breadcrumb";
import { getDefaultInOutTime } from "../../../../../constants/default-values";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const ApplyWFHRequestSummary = ({
  setIsAppliedLeave,
  setLeaveData,
  leaveData,
  userLeaveTypes,
  organizationUserList,
  setErrors,
  errors,
  setIsAutoDeleted,
  isAutoDeleted,
  activeRequestType,
  renewalSettingData
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
  });

  const getIgnoreDates = () => {
    let array = [];
    if (!!ignoreLeaveDates?.leaves?.length) {
      array.push(...ignoreLeaveDates?.leaves);
    }
    if (!!ignoreLeaveDates?.custom_holidays?.length) {
      array.push(...ignoreLeaveDates?.custom_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.festival_holidays?.length) {
      array.push(...ignoreLeaveDates?.festival_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.saturdayHolidays?.length) {
      array.push(...ignoreLeaveDates?.saturdayHolidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.sundayHolidays?.length) {
      array.push(...ignoreLeaveDates?.sundayHolidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.inactive_holidays?.length) {
      array.push(...ignoreLeaveDates?.inactive_holidays?.map(value => value?.date));
    }
    if (!!ignoreLeaveDates?.disabledDates?.length) {
      array.push(...ignoreLeaveDates?.disabledDates?.map(value => convertDateFromFormat(value)));
    }

    return Array.from(new Set(array?.map(date => (getFormattedDate(date)))));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLeaveData(prev => ({ ...prev, [name]: name === "informed_authorities" ? [value?.id] : value }));
    handleBlur(event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    let updatedErrors = { ...errors };
    if (['wfh_days'].includes(name)) {
      if (!!value?.length) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    } else {
      if (!!value) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    }
    setErrors(updatedErrors)
  };

  const getLeaveDates = (isGetType = false) => {
    if (isGetType) {
      return leaveData?.wfh_days?.map((leaveData) => ({
        ...leaveData,
        date: moment(leaveData?.date || leaveData, "DD/MM/YYYY").format("YYYY-MM-DD"),
        leave_duration: leaveData?.leave_duration || "full",
      }))
    }
    else {
      return leaveData?.wfh_days?.map((leaveData) => !!leaveData?.date ? leaveData?.date : leaveData);
    }
  }

  const handleSelectMultipleDate = (selectedDates) => {
    const dates = selectedDates?.map((date) => {
      let convertedDate = typeof date === "string" ? date : getFormattedDate(new Date(date));
      let data = leaveData?.wfh_days?.find(item => item?.date === convertedDate);
      let { inTime, outTime } = getDefaultInOutTime();
      if (!!data) {
        return data;
      } else {
        return {
          date: convertedDate,
          leave_duration: "full",
          credit_used: 0,
          in_time: inTime,
          out_time: outTime,
        }
      }
    });
    setLeaveData((prev) => ({
      ...prev,
      wfh_days: sortArrayByKey(dates, "asc", "date")
    }))
    let updatedErrors = { ...errors };
    if (!!dates?.length) {
      updatedErrors['wfh_days'] = "";
    } else {
      updatedErrors['wfh_days'] = "Required";
    }
    setErrors(updatedErrors)
  };

  const validateData = (data) => {
    let updatedErrors = {};
    if (!data?.informed_authorities?.length) {
      updatedErrors['informed_authorities'] = "Required";
    }

    if (!data?.static_id) {
      updatedErrors['static_id'] = "Required";
    }

    if (!data?.wfh_days?.length) {
      updatedErrors['wfh_days'] = "Required";
    }

    setErrors((prev) => ({ ...prev, ...updatedErrors }));
    return Object.keys(updatedErrors)?.length;
  }

  const handleSubmit = async (e) => {
    setIsLoading((prev) => ({ ...prev, formSubmitting: true }))
    e.preventDefault();
    if (!validateData(leaveData)) {
      const updatedList = leaveData?.wfh_days?.map(leave => {
        let isFutureDate = isFutureYearDate(renewalSettingData, leave?.date)
        return { ...leave, credit_used: isFutureDate ? 0 : 1, }
      });
      setLeaveData((prev) => ({
        ...prev,
        wfh_days: sortArrayByKey(updatedList, "asc", "date"),
      }));
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      setIsAppliedLeave(true);
    } else {
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  const handleWfhTypeChange = (event) => {
    const { name, value } = event.target;
    handleBlur(event);
    let availabilityChange = userLeaveTypes?.find((value) => value?.name === "availability change");
    let isReqActive = activeRequestType?.find((activeReq) => activeReq?.spec_req_id === value);
    let currentDate = getFormattedDate(new Date())
    let isDateExist = leaveData?.wfh_days?.find(value => value?.date === currentDate)
    if (availabilityChange?.id === value && !!isDateExist) {
      let updatedLeaveData = {
        ...leaveData,
        [name]: value,
        wfh_days: leaveData?.wfh_days?.filter((value) => value?.date !== currentDate),
        user_spec_req_id: isReqActive ? value : null
      }
      setLeaveData(updatedLeaveData);
      setIsAutoDeleted(true);
    } else {
      setLeaveData((prev) => ({
        ...prev,
        [name]: value,
        user_spec_req_id: isReqActive ? value : null
      }));
    }
  }

  const handleClickBack = () => {
    navigate(-1);
  }

  return (
    <>
      <Breadcrumb isBack={true} title={"My WFH Request"}
        pageTitle={'New Request'}
        isPathShow={false}
        onBackClick={() => handleClickBack()} />
      <Box sx={{
        padding: "26px !important",
      }}>
        <form onSubmit={handleSubmit}>
          <Box width={768} sx={{ maxWidth: "100%" }}>
            <Stack spacing={3} mb={4}>
              <Stack
                spacing={4}
                direction="row"
                alignItems="flex-start"
                width="100%"
              >
                <Grid Item sx={{ flex: '0 0 calc(50% - 16px)' }}>
                  <FormControl variant="standard" sx={{ m: 0, width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="static_id"
                      onChange={handleWfhTypeChange}
                      value={leaveData?.static_id}
                      onBlur={handleBlur}
                      label="Select Type"
                    >
                      {userLeaveTypes?.map(
                        ({ name, id }, index) => (
                          <MenuItem value={id} key={index}>
                            {name === "Come late" ? "Late Coming" : titleCase(name)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {errors?.static_id ? <FormHelperText sx={{ color: "red" }}>
                      {errors?.static_id}
                    </FormHelperText> : null}
                  </FormControl>
                </Grid>
                <Grid Item sx={{ flex: '0 0 calc(50% - 16px)', maxWidth: 'calc(50% - 16px)' }}>
                  <FormControl
                    className={classes.margin}
                    variant="standard"
                    sx={{
                      margin: "0px !important",
                      width: "100%",
                      // "div ": { marginTop: "2px !important" },
                    }}
                  >
                    <AutocompleteSelect
                      onBlur={handleBlur}
                      onChange={handleChange}
                      options={sortArrayByKey(organizationUserList, 'asc', 'name')}
                      value={organizationUserList?.find(user => user?.id === leaveData?.informed_authorities[0]) || null}
                      label={"Request To"}
                      name="informed_authorities"
                      id="informed_authorities"
                    />
                    <FormHelperText>
                      Along with this user, WFH application will be sent to HR and Admin users of your organisation.
                    </FormHelperText>
                    {errors?.informed_authorities ? <FormHelperText sx={{ color: "red" }}>
                      {errors?.informed_authorities}
                    </FormHelperText> : null}
                  </FormControl>
                </Grid>
              </Stack>
              <Grid Item flex={1}>
                <Typography fontWeight={400} fontSize={14} color="dark.800" sx={{ lineHeight: '21px', letterSpacing: '0.15px' }}>Request Dates</Typography>
                <MultipleDatePicker
                  ignoreDates={getIgnoreDates()}
                  name={"wfh_days"}
                  value={getLeaveDates()}
                  onBlur={handleBlur}
                  onChange={(date) => handleSelectMultipleDate(date)}
                  plugins={[
                    <DatePanel markFocused />,
                    <Toolbar
                      position="bottom"
                      sort={["deselect", "today", "close"]}
                      names={{
                        deselect: "Deselect",
                        today: "Today",
                        close: "Select",
                      }}
                    />,
                  ]}
                  isAutoDeleted={isAutoDeleted}
                  setIsAutoDeleted={setIsAutoDeleted}
                />
                {errors?.wfh_days ? <FormHelperText sx={{ color: "red" }}>
                  {errors?.wfh_days}
                </FormHelperText> : null}
              </Grid>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                color: "white",
                textTransform: "none",
                marginRight: "20px",
                boxShadow: "none",
              }}
              type="submit"
              disabled={!!Object.values(errors).filter(item => !!item)?.length}
              isButtonLoading={isLoading.formSubmitting}
            >
              Proceed
            </Button>
            <Button
              color="secondary"
              size="large"
              variant="text"
              sx={{
                textTransform: "none",
              }}
              type="reset"
              onClick={() => navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`)}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ApplyWFHRequestSummary;
