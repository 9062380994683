import React from "react";
import Box from "@mui/material/Box";
import AppBar from "../../../components/AppBar";
import useAuthentication from "../../../hook/useAuthentication";
import URLS from "../../../routes/urls";
import { Navigate } from "react-router-dom";

const OrganisationWrapper = ({ children }) => {
  const { getCurrentUser } = useAuthentication();

  const currentUser = getCurrentUser();
  if (["admin"].includes(currentUser.user_type)) {
    return <Navigate to={URLS.Dashboard} />;
  }

  return (
    <Box sx={{ bgcolor: "bgColor", minHeight: "100vh" }}>
      <AppBar appBarType={"super-admin"} />
      <Box
        width="100%"
        position="relative"
        sx={{
          height: "320px",
          boxShadow: 0,
          borderRadius: "0px",
          backgroundColor: "#3F5C76",
          overflow: "hidden",
          "&:after": {
            content: '""',
            display: "block",
            height: "29px",
            position: "absolute",
            zIndex: "1",
            bottom: "0px",
            borderBottom: "25px solid #8A9BAD",
            borderRight: "99.5vw solid transparent",
          },
        }}
      ></Box>
      {children}
    </Box>
  );
};

export default OrganisationWrapper;
