import { FormControl, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { WEEKDAYS } from '../../constants/default-values';
import { getFormattedDate } from '../../utils';
import useAuthentication from '../../hook/useAuthentication';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getAllHolidayData } from '../../redux/slices/leaveDataSlice';

const DateRangePicker = ({ dateRange, onChange, onClose, maxDate = new DateObject().toDate(), error = "", width, isUseIgnoreDate = true, ...rest }) => {
    const { holidaySettingData, leaveLoading } = useSelector((state) => state.leaveData);
    const dispatch = useDispatch();
    const [disabledDates, setDisabledDates] = useState([]);
    const [activeYear, setActiveYear] = useState(null);
    const [loading, setLoading] = useState({
        holidayDataLoading: true,
    });
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();

    useEffect(() => {
        if (currentUser?.id) {
            getAllHolidayYearsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!!holidaySettingData && Object.keys(holidaySettingData)?.length) {
            let array = [];
            if (!!holidaySettingData?.leaves?.length) {
                array.push(...holidaySettingData?.leaves);
            }
            if (!!holidaySettingData?.custom_holidays?.length) {
                array.push(...holidaySettingData?.custom_holidays?.map(value => value?.date));
            }
            if (!!holidaySettingData?.festival_holidays?.length) {
                array.push(...holidaySettingData?.festival_holidays?.map(value => value?.date));
            }
            if (!!holidaySettingData?.saturdayHolidays?.length) {
                array.push(...holidaySettingData?.saturdayHolidays?.map(value => value?.date));
            }
            if (!!holidaySettingData?.sundayHolidays?.length) {
                array.push(...holidaySettingData?.sundayHolidays?.map(value => value?.date));
            }
            if (!!holidaySettingData?.inactive_holidays?.length) {
                array.push(...holidaySettingData?.inactive_holidays?.map(value => value?.date));
            }
            let dateArray = Array.from(new Set(array?.map(date => (getFormattedDate(date)))));

            setDisabledDates([...dateArray])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [holidaySettingData])

    const getAllHolidayYearsData = async () => {
        try {
            const config = {
                params: {
                    renewalMonth: true
                },
            };
            const response = await axiosInstance.get(API.getHolidayYears(currentUser?.organization_id), config);
            if (response.status === 200) {
                let sortedYear = response?.data?.result?.sort((a, b) =>
                    a?.year?.localeCompare(b?.year)
                );
                let activeYear = sortedYear?.find(yearData => yearData?.isActiveYear);
                setActiveYear(Number(activeYear?.year))
                dispatch(getAllHolidayData({
                    organization_id: currentUser?.organization_id,
                    year_Id: activeYear?.id,
                    user_id: currentUser?.id
                }))
            }
            setLoading(prev => ({ ...prev, holidayDataLoading: false }))
        } catch (error) {
            console.error(error);
            setLoading(prev => ({ ...prev, holidayDataLoading: false }))
        }
    };

    return (
        (!leaveLoading || !loading?.holidayDataLoading) ? <FormControl variant="standard">
            <DatePicker
                range
                value={dateRange}
                onChange={onChange}
                onClose={onClose}
                headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                format="DD/MM/YYYY"
                weekDays={WEEKDAYS}
                highlightToday
                minDate={new Date(currentUser?.organization_created_date || `01/01/${activeYear}`)}
                maxDate={maxDate}
                placeholder='Select Report Range'
                mapDays={({ date }) => {
                    let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization_created_date || `01/01/${activeYear}`);
                    let isIgnoredDate = disabledDates?.some((ignoreDate) => {
                        let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
                        let date2 = moment(new Date(date)).format("DD/MM/YYYY");
                        return date1 === date2;
                    })
                    if (isUseIgnoreDate) {
                        if (isPastDate || isIgnoredDate) {
                            return {
                                disabled: true,
                                style: { color: "#ccc" },
                            };
                        }
                    } else {
                        if (isPastDate) {
                            return {
                                disabled: true,
                                style: { color: "#ccc" },
                            };
                        }
                    }
                }}
                render={<TextField
                    label={'Select Report Range'}
                    autoComplete="off"
                    onKeyDown={(event) => {
                        event.preventDefault();
                    }}
                    id="tags-standard"
                    variant="standard"
                    sx={{
                        minHeight: "30px",
                        width: "250px",
                        marginTop: "10px",
                        "label": {
                            top: "-10px",
                        },
                        ".MuiInputBase-formControl": {
                            minHeight: "30px",
                            flexWrap: "wrap",
                            paddingTop: "0px",
                            marginTop: '8px'
                        },
                        "p": {
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "rgba(0, 0, 0, 0.87)",
                        },
                        ".MuiInputBase-input": {
                            opacity: 0,
                            height: "22px",
                            flex: '1',
                            border: "none",
                            top: 0,
                            bottom: "1px",
                            background: "transparent",
                            position: "absolute",
                        },
                        ".rmdp-input:focus": {
                            border: "none",
                            boxShadow: "none",
                        },
                        ".MuiChip-sizeSmall": {
                            marginLeft: '0px !important',
                            marginRight: '8px'
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <Stack spacing={1} direction="row" flexWrap="wrap">
                                {dateRange?.map((date, index, array) => (
                                    <>
                                        <Typography>{typeof date === "string" ? date : getFormattedDate(new Date(date))}</Typography>
                                        {array?.length !== 1 && index === 0 ? <Typography>{" - "}</Typography> : null}
                                    </>
                                ))}
                            </Stack>
                        ),
                        readOnly: true
                    }}
                    {...rest}
                />
                }
                {...rest}
            />
            {!!error ? <Typography
                sx={{
                    color: "red",
                    fontSize: "12px"
                }}
            >
                {error}
            </Typography> : null}
        </FormControl> : <Skeleton variant="rounded" animation="wave" height={50} width={width} />
    )
}

export default DateRangePicker;
