/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Chip,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import CircularLoader from "../../../components/CircularLoader";
import {
  getFullName,
} from "../../../utils/index";
import { useNavigate, useParams } from "react-router-dom";
import UserInformation from "./UserTabPages/UserInformation";
import UserProjects from "./UserTabPages/UserProjects";
import UserLeave from "./UserTabPages/UserLeave";
import UserRoles from "./UserTabPages/UserRoles";
import UserReports from "./UserTabPages/UserReports";
import useQuery from "../../../hook/useQuery";
import URLS from "../../../routes/urls";
import { usePageTitle } from "../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";
import UserRoleChip from "../../../components/UserRoleChip";
import Breadcrumb from "../../../components/Breadcrumb";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  let { getQueryParams, setQueryParams } = useQuery();
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
  });
  let initialTabValue = parseInt(getQueryParams("tabValue")) || 0;
  const [userData, setUserData] = useState(null);

  setPageTitle("User's Details");

  useEffect(() => {
    setQueryParams("tabValue", initialTabValue);
  }, [])

  useEffect(() => {
    getUserDetails(userId);
  }, [userId]);

  const getUserDetails = async (id) => {
    try {
      let response = await axiosInstance.get(
        API.fetchUserDetails(id)
      );
      if (response.status === 200) {
        setUserData(response.data.data);
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  }
  const handleChange = (event, newValue) => {
    setQueryParams("tabValue", newValue);
  };

  return isLoading?.pageLoading ? (
    <CircularLoader />
  ) : (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <Breadcrumb isBack={true} title={"Users"}
        pageTitle={'User Details'}
        isPathShow={false}
        onBackClick={() => navigate(URLS.Users)} />
      <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} flex={1} overflow="auto">
        <Grid container justifyContent="space-between" mb={4}>
          <Grid Item display="flex" alignItems="center">
            <BackgroundLetterAvatars
              user={userData}
              sx={{ width: 64, height: 64, fontSize: "135%", }}
            />
            <Box ml={2} display="flex" flexWrap="wrap">
              <div
                style={{
                  marginBottom: "2px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  color="secondary"
                  fontSize={18}
                  fontWeight={500}
                  mr={1}
                  sx={{
                    lineHeight: "27px",
                  }}
                >
                  {getFullName(userData)}
                </Typography>
                {!!userData?.is_dedicated_developer ? (
                  <Chip
                    label="Dedicated"
                    color="secondary"
                    size="small"
                    variant="filled"
                  />
                ) : null}
              </div>
              <Typography
                color="secondary"
                fontSize={12}
                fontWeight={500}
                display="block"
                sx={{ lineHeight: "18px", width: "100%", mb: 1, }}
              >
                {userData?.email || ""}
              </Typography>
              <UserRoleChip label={userData?.OrganizationUserRole?.name} />
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={initialTabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Information" {...a11yProps(0)} />
              <Tab label="Projects" {...a11yProps(1)} />
              <Tab label="Leave" {...a11yProps(2)} />
              <Tab label="Roles" {...a11yProps(3)} />
              <Tab label="Reports" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={initialTabValue} index={0}>
            <UserInformation userData={userData} />
          </CustomTabPanel>
          <CustomTabPanel value={initialTabValue} index={1}>
            <UserProjects />
          </CustomTabPanel>
          <CustomTabPanel value={initialTabValue} index={2}>
            <UserLeave getUserDetails={getUserDetails} />
          </CustomTabPanel>
          <CustomTabPanel value={initialTabValue} index={3}>
            <UserRoles />
          </CustomTabPanel>
          <CustomTabPanel value={initialTabValue} index={4}>
            <UserReports />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default UserDetails; 