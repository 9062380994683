/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import API from "../../../../../axios/api";
import axiosInstance from "../../../../../axios";
import useAuthentication from "../../../../../hook/useAuthentication";
import CircularLoader from "../../../../../components/CircularLoader";
import { useNavigate, useParams } from "react-router-dom";
import EditLeaveDetails from "./EditLeaveDetails";
import EditLeave from "./EditLeave";
import EmptyPage from "../../../../../components/EmptyPage";
import { usePageTitle } from "../../../../../hook/useTitle";
import { getAllHolidayData } from "../../../../../redux/slices/leaveDataSlice";
import { useDispatch } from "react-redux";
import URLS from "../../../../../routes/urls";
import { LEAVE_DURATION_TYPE } from "../../../../../constants/default-values";
import { convertTimeToDate } from "../../../../../utils";

const EditMyLeaveApplication = () => {
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const params = useParams();
  const { setPageTitle } = usePageTitle();

  setPageTitle("Edit Leave");
  let initialLeaveData = {
    user_leave_type_setting_id: null,
    informed_authorities: [],
    leave_dates: [],
    attachments: [],
    attachment_references: [],
    description: "",
    apply_date: new Date(),
    club_leave_count: 0,
    is_leave_type_changed: false,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAppliedLeave, setIsAppliedLeave] = useState(true);
  const [autoGeneratedLeaveDays, setAutoGeneratedLeaveDays] = useState([]);
  const [leaveData, setLeaveData] = useState(initialLeaveData);
  const [errors, setErrors] = useState({});
  const [userLeaveTypes, setUserLeaveTypes] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [leaveSnapshot, setLeaveSnapshot] = useState(null);
  const [deletedDays, setDeletedDays] = useState([]);
  const [isLoading, setIsLoading] = useState({
    leaveType: true,
    users: true,
    leaveDetails: true,
  })

  useEffect(() => {
    if (currentUser?.id) {
      if (Number(currentUser?.id) === Number(params?.userId)) {
        getUserLeaveTypeData();
        getUserSettingData();
        getYears();
        getUserLeaveBalance();
      } else {
        navigate(URLS.AccessDenied)
      }
    }
  }, []);

  const getUserLeaveBalance = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, spanLoading: true }))
      const response = await axiosInstance.get(API.getLeaveBalance(currentUser?.id));
      if (response.status === 200) {
        setLeaveSnapshot(response?.data?.result);
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, spanLoading: false }))
    }
  };

  const getUserLeave = async (user_id, id) => {
    try {
      const response = await axiosInstance.get(API.getLeaveById(user_id, id));
      if (response.status === 200) {
        setLeaveData({
          ...initialLeaveData,
          ...response?.data?.result,
          // leave_days: response?.data?.result?.leave_days?.map(value => ({
          //   ...value,
          //   is_changed: false
          // })),
          leave_days: response?.data?.result?.leave_days?.map(value => {
            let leave_duration_time = Object.keys(LEAVE_DURATION_TYPE)[2] === value?.leave_duration;
            if (leave_duration_time) {
              return {
                ...value,
                is_changed: false
              }
            } else {
              let leave_duration_time =
                Object.keys(LEAVE_DURATION_TYPE)[0] === value?.leave_duration
                  ? "in_time"
                  : "out_time"
              return {
                ...value,
                is_changed: false,
                [leave_duration_time]: convertTimeToDate(value?.[leave_duration_time])
              }
            }
          }),
        });
        let data = response?.data?.result?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(data)
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        setLeaveData({});
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error(error);
      setLeaveData({});
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
    }
  };

  const getUserLeaveTypeData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveType: true }))
      const response = await axiosInstance.get(
        API.getUserLeaveTypeData(currentUser?.id)
      );
      if (response.status === 200) {
        setUserLeaveTypes(response?.data.results);
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveType: false }))
    }
  };

  const getUserSettingData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, users: true }))
      const response = await axiosInstance.get(API.organisationUserWithInformationAuthority);
      if (response.status === 200) {
        let reporting_authorities = response?.data?.data?.reporting_authorities;
        let second_reporting_authorities = response?.data?.data?.second_reporting_authorities;
        let defaultUsers = response?.data?.data?.defaultUsers;
        let users = [];
        if (reporting_authorities && reporting_authorities?.length > 0) {
          users.push(...reporting_authorities);
        }
        if (second_reporting_authorities && second_reporting_authorities?.length > 0) {
          users.push(...second_reporting_authorities);
        }
        if (users?.length <= 0) {
          users.push(...defaultUsers);
        }
        setOrganizationUserList(users || []);
        getUserLeave(params?.userId, params?.appId);
        setIsLoading((prev) => ({ ...prev, users: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, users: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, users: false }))
    }
  };

  const getYears = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, holiday: true }))
      const response = await axiosInstance.get(
        `${API.getAllHolidaysYear(currentUser?.organization_id)}`
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.localeCompare(b?.year)
        );
        dispatch(getAllHolidayData({
          organization_id: currentUser?.organization_id,
          year_Id: sortedYear[0]?.id,
          user_id: currentUser?.id
        }))
        setIsLoading((prev) => ({ ...prev, holiday: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, holiday: false }))
    }
  }

  return ((isLoading?.leaveType || isLoading?.users || isLoading?.leaveDetails) ?
    <CircularLoader /> :
    leaveData && Object.keys(leaveData)?.length === 0 ?
      <EmptyPage
        title="No leaves application available"
        buttonStyle={{ color: "white" }}
        buttonColor="secondary"
        onClick={() => navigate(-1)}
      /> :
      isAppliedLeave ? (
        <EditLeaveDetails
          leaveSnapshot={leaveSnapshot}
          setIsAppliedLeave={setIsAppliedLeave}
          setLeaveData={setLeaveData}
          setAutoGeneratedLeaveDays={setAutoGeneratedLeaveDays}
          autoGeneratedLeaveDays={autoGeneratedLeaveDays}
          leaveData={leaveData}
          setErrors={setErrors}
          userLeaveTypes={userLeaveTypes}
          organizationUserList={organizationUserList}
          errors={errors}
          currentUser={currentUser}
          setDeletedDays={setDeletedDays}
          deletedDays={deletedDays}
          deletedImages={deletedImages}
          setDeletedImages={setDeletedImages}
        />
      ) : (
        <EditLeave
          leaveSnapshot={leaveSnapshot}
          setIsAppliedLeave={setIsAppliedLeave}
          setLeaveData={setLeaveData}
          setAutoGeneratedLeaveDays={setAutoGeneratedLeaveDays}
          leaveData={leaveData}
          setErrors={setErrors}
          errors={errors}
          userLeaveTypes={userLeaveTypes}
          organizationUserList={organizationUserList}
          currentUser={currentUser}
          setDeletedDays={setDeletedDays}
          deletedDays={deletedDays}
          setDeletedImages={setDeletedImages}
          deletedImages={deletedImages}
        />
      )
  )
};

export default EditMyLeaveApplication;
