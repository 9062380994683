import React from 'react'
import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import URLS from '../../routes/urls';
import { Link as RouteLink } from "react-router-dom";

const PublicFooter = () => {
    return (
        <Grid padding="40px 15px" maxWidth={"100vw"}>
            <Grid display="flex" justifyContent="space-between" sx={{ width: '1210px', maxWidth: '100%', margin: 'auto' }}>
                <Typography color="text.primary300" variant='subtitle2'>Copyright © 2024 TechCompose Solutions Pvt. Ltd. All rights reserved.</Typography>
                <Grid>
                    <List sx={{ display: 'flex', padding: '0px' }}>
                        <ListItem sx={{ width: 'autp', padding: '0px', marginLeft: '30px' }}>
                            <Link
                                component={RouteLink}
                                to={URLS.TermsAndConditions}
                                color="text.primary300"
                                variant='subtitle2'
                                sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                            >
                                Terms and Conditions
                            </Link>
                        </ListItem>
                        <ListItem sx={{ width: 'autp', padding: '0px', marginLeft: '30px' }}>
                            <Link
                                component={RouteLink}
                                to={URLS.PrivacyPolicy}
                                color="text.primary300"
                                variant='subtitle2'
                                sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                            >
                                Privacy Policy
                            </Link>
                        </ListItem>
                        <ListItem sx={{ width: 'autp', padding: '0px', marginLeft: '30px' }}>
                            <Link
                                component={RouteLink}
                                to={URLS.CAPrivacyNotice}
                                color="text.primary300"
                                variant='subtitle2'
                                sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                            >
                                CA Privacy Notice
                            </Link>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PublicFooter;