/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import Input from "../../../components/Input";
import { generateUniqueId, getMaxSize, isValidOpeningBalance, titleCase } from "../../../utils";
import CircularLoader from "../../../components/CircularLoader";
import useAuthentication from "../../../hook/useAuthentication";
import { useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import { useAlert } from "../../../hook/useAlert";
import { handleCheckIsEmailUnique } from "../../../axios/service";
import { validateEmail } from "../../../utils/validation";
import { usePageTitle } from "../../../hook/useTitle";
import Breadcrumb from "../../../components/Breadcrumb";
import styled from "@emotion/styled";
import { PROFILE_IMAGE_SIZE, VALID_IMAGE_TYPE } from "../../../constants/default-values";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const initialUserObjectData = {
    email: "",
    name: "",
    active: true,
    current_user_role_id: null,
    current_user_type_id: null,
    org_work_type_setting_id: null,
    is_admin: false,
    unique_id: generateUniqueId(),
    is_admin_user: false,
    leave_type_setting: [],
};

const CreateUser = () => {
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const { setPageTitle } = usePageTitle();
    const [previewImage, setPreviewImage] = useState(null);
    const [isChanged, setIsChanged] = useState({});
    const [userData, setUserData] = useState(initialUserObjectData);
    const [constantValues, setConstantValues] = useState({
        userRolesData: [],
        workTypeData: [],
        userTypeData: [],
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: false,
        leaveType: false,
    });

    setPageTitle("Add User");
    const getUserRolesData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(
                API.organisationUserRoles(currentUser?.organization_id)
            );
            if (response.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: response?.data?.data?.map(item => ({ ...item, isVisible: true, isSelected: true, })),
                }));
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const getLeaveTypesByOrganizationIdData = async (...rest) => {
        let target = rest?.[0]?.target;
        try {
            setLoading((prev) => ({ ...prev, leaveType: true }));
            const response = await axiosInstance.get(API.organizationLeaveTypes(target?.value));
            if (response.status === 200) {
                let initialLeaveTypeData = response?.data?.result?.map(item => ({
                    id: item?.id || null,
                    monthly_days: item?.monthly_days || 0,
                    opening_balance: item?.opening_balance || 0,
                    name: item?.LeaveType?.name || null,
                }));
                setUserData((prev) => ({ ...prev, leave_type_setting: initialLeaveTypeData }));
            } else {
                setUserData((prev) => ({ ...prev, leave_type_setting: [] }));
            }
            setLoading((prev) => ({ ...prev, leaveType: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const getWorkTypesByOrganizationIdData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(
                API.getWorkTypesByOrganisationId(currentUser?.organization_id)
            );
            if (response.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    workTypeData: response?.data?.data,
                }));
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    const getUserTypesData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const response = await axiosInstance.get(API.getUserTypes);
            if (response.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    userTypeData: response?.data?.data?.map(item => ({ ...item, isVisible: true })),
                }));
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (currentUser?.id) {
            getUserRolesData();
            getUserTypesData();
            getWorkTypesByOrganizationIdData();
        }
    }, []);

    const handleCancel = () => {
        navigate(URLS.Users)
    }

    const handleChangeProfileImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (VALID_IMAGE_TYPE.includes(file.type)) {
                if (file.size <= PROFILE_IMAGE_SIZE) {
                    const previewURL = URL.createObjectURL(file);
                    setPreviewImage(previewURL);

                    setUserData((prev) => ({
                        ...prev,
                        profile_img: file
                    }));

                    setIsChanged((prev) => ({
                        ...prev,
                        profile_img: true
                    }));
                } else {
                    showAlert(`File size exceeds ${getMaxSize(PROFILE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
                    setUserData((prev) => ({
                        ...prev,
                        profile_img: null
                    }));
                }
            } else {
                showAlert('Only JPEG and PNG file types are allowed.', "error");
                setUserData((prev) => ({
                    ...prev,
                    profile_img: null
                }));
            }
        }
    };

    const validateOpeningBalance = (event, currentIndex) => {
        const { value } = event.target;
        let updatedError = [...errors?.leave_type_setting || []]
        let error = value === "null" || value === null || value === "" || value < 0 ? "Required" : !isValidOpeningBalance(value) ? "Please enter valid opening balance" : "";
        updatedError[currentIndex] = {
            opening_balance: error,
        };
        setErrors((prev) => ({
            ...prev,
            leave_type_setting: updatedError,
        }));
    }

    const handleChangeOpeningBalance = (e, currentIndex) => {
        validateOpeningBalance(e, currentIndex);
        let updatedList = userData?.leave_type_setting?.map((value, index) => {
            if (index === currentIndex)
                return {
                    ...value,
                    opening_balance: e.target.value >= 0 ? parseFloat(e.target.value) : null,
                };
            else
                return value;
        });
        setUserData((prev) => ({ ...prev, leave_type_setting: updatedList }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            let formData = new FormData();
            formData.append("email", userData?.email);
            formData.append("name", userData?.name);
            formData.append("current_user_type_id", userData?.current_user_type_id);
            formData.append("current_user_role_id", userData?.current_user_role_id);
            formData.append("org_work_type_setting_id", userData?.org_work_type_setting_id);
            formData.append("organization_id", currentUser?.organization_id);
            formData.append("is_admin", userData?.is_admin_user);
            userData?.leave_type_setting?.forEach((value, index) => {
                formData.append(`leave_type_setting[${index}][id]`, value?.id);
                formData.append(`leave_type_setting[${index}][monthly_days]`, Number.parseFloat(value?.monthly_days));
                formData.append(`leave_type_setting[${index}][opening_balance]`, Number.parseFloat(value?.opening_balance));
            });
            if (isChanged?.profile_img && userData?.profile_img?.size > 0) {
                formData.append("profile_img", userData?.profile_img);
            }
            const response = await axiosInstance.post(API.addUser, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.status === 200) {
                showAlert(response.data?.message);
                navigate(URLS.Users, {
                    replace: true,
                });
            }
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    };

    const handleBlur = async (e) => {
        let { name, value } = e.target;
        if (name === 'email') {
            const isEmailNotUnique = await handleCheckIsEmailUnique(value);
            const isInvalidEmail = value?.trim() && !validateEmail(value?.trim());
            let errorMessage = isInvalidEmail ? "Invalid email address" : isEmailNotUnique ? "Email is already exist" : "";
            setErrors(prev => ({
                ...prev,
                [name]: !value ? "Required" : errorMessage,
            }))
        } else {
            setErrors(prev => ({
                ...prev,
                [name]: !value ? "Required" : "",
            }))
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        let updatedData = {
            ...userData,
            [name]: value,
        }
        if (name === "current_user_type_id") {
            let adminType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "admin");
            let generalType = constantValues?.userTypeData?.find(item => item?.name?.toLowerCase() === "general");
            let adminRole = constantValues?.userRolesData?.find((value) => value?.name?.toLowerCase() === "administrator");
            if (adminType?.id === value) {
                updatedData['current_user_role_id'] = adminRole?.id;
                updatedData['is_admin'] = true;
                updatedData['is_admin_user'] = true;
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id === adminRole?.id ? { ...item, isVisible: true, isSelected: true, } : { ...item, isVisible: false })
                }));
                setErrors((prev) => ({ ...prev, current_user_role_id: "" }));
                setUserData(updatedData)
            } else if (generalType?.id === value) {
                updatedData['is_admin'] = false;
                updatedData['is_admin_user'] = false;
                updatedData['is_general_user'] = true;
                updatedData['current_user_role_id'] = null;
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
                }));
            } else {
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: prev?.userRolesData?.map(item => item?.id !== adminRole?.id ? { ...item, isVisible: true } : { ...item, isVisible: false })
                }));
                updatedData['is_admin'] = true;
                updatedData['is_admin_user'] = false;
                updatedData['is_general_user'] = false;
                updatedData['current_user_role_id'] = null;
                setUserData(updatedData)
            }
        }
        setUserData(updatedData);
        setErrors(prev => ({
            ...prev,
            [name]: !value ? "Required" : "",
        }))
    }

    let isError = () => {
        function areAllValuesEmpty(obj) {
            if (typeof obj !== 'object' || obj === null) {
                return false;
            }

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    if (Array.isArray(value)) {
                        if (!value.every(item => areAllValuesEmpty(item))) {
                            return false;
                        }
                    } else if (typeof value === 'object') {
                        if (!areAllValuesEmpty(value)) {
                            return false;
                        }
                    } else if (value !== "") {
                        return false;
                    }
                }
            }

            return true;
        }
        let isError = areAllValuesEmpty(errors);

        function validateObject(obj) {
            const errors = [];
            const requiredKeys = [
                'email',
                'name',
                'active',
                'current_user_role_id',
                'current_user_type_id',
                'org_work_type_setting_id',
                'is_admin',
                'unique_id',
                'is_admin_user',
                'leave_type_setting'
            ];

            function checkString(fieldName, value, allowEmpty = false) {
                if (typeof value !== 'string' || (!allowEmpty && value.trim() === '')) {
                    errors.push(`${fieldName} must be a non-empty string.`);
                }
            }

            function checkBoolean(fieldName, value) {
                if (typeof value !== 'boolean') {
                    errors.push(`${fieldName} must be a boolean.`);
                }
            }

            function checkNullableString(fieldName, value) {
                if (value !== null && typeof value !== 'number' && typeof value !== 'string') {
                    errors.push(`${fieldName} must be null or a string.`);
                }
            }

            function checkArray(fieldName, value) {
                if (!Array.isArray(value)) {
                    errors.push(`${fieldName} must be an array.`);
                }
            }

            function checkValue(fieldName, value) {
                if (value === null || value === undefined || (typeof value === 'string' && value.trim() === '')) {
                    errors.push(`${fieldName} must have a value.`);
                }
            }


            for (const key of requiredKeys) {
                if (obj.hasOwnProperty(key)) {
                    checkValue(key, obj[key]);
                } else {
                    errors.push(`${key} is missing.`);
                }
            }

            checkString('email', obj.email, true);
            checkString('name', obj.name, true);
            checkBoolean('active', obj.active);
            checkNullableString('current_user_role_id', obj.current_user_role_id);
            checkNullableString('current_user_type_id', obj.current_user_type_id);
            checkNullableString('org_work_type_setting_id', obj.org_work_type_setting_id);
            checkBoolean('is_admin', obj.is_admin);
            checkString('unique_id', obj.unique_id);
            checkBoolean('is_admin_user', obj.is_admin_user);
            checkArray('leave_type_setting', obj.leave_type_setting);

            return errors.length === 0 ? { isValid: true } : { isValid: false, errors };
        }

        let isErrorInUser = validateObject(userData);

        return !isError || !isErrorInUser?.isValid;
    }

    return (
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            <Breadcrumb isBack={true} onBackClick={() => navigate(URLS.Users)} pageTitle={"Add User"} title={"Users"} />
            <Box width="100%" sx={{ overflowY: 'auto' }}>
                <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}>
                    <Stack spacing={3}>
                        <Typography color="dark.800" fontWeight={400} fontSize={20} lineHeight="30px" letterSpacing="0.15px" variant="h4">Create User</Typography>
                        <Box container justifyContent="space-between" alignItems="center">
                            {loading.pageLoading ? <CircularLoader height="500px" /> :
                                <form onSubmit={handleSubmit} className="step-form-1">
                                    <Stack spacing={4} sx={{ flexBasis: "100%" }}>
                                        <Box display="flex" alignItems="center">
                                            <Box width={90} height={90} borderRadius="50%" overflow="hidden" mr={2}>
                                                {!previewImage ?
                                                    <Avatar
                                                        src="/broken-image.jpg"
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: 0,
                                                            fontSize: "50px",
                                                        }}
                                                    /> :
                                                    <BackgroundLetterAvatars
                                                        user={userData}
                                                        src={previewImage}
                                                        sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: 0,
                                                            fontSize: "50px",
                                                        }}
                                                    />}
                                            </Box>
                                            <Button
                                                component="label"
                                                variant="text"
                                                color="primary"
                                                fontSize={13}
                                                sx={{ textTransform: "none" }}
                                            >
                                                Change
                                                <VisuallyHiddenInput
                                                    name="profile_img"
                                                    type="file"
                                                    onChange={handleChangeProfileImage}
                                                    accept="image/*"
                                                />
                                            </Button>
                                        </Box>
                                        <Box width={780} maxWidth="100%">
                                            <Grid container spacing={4}>
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <Input
                                                        id="name"
                                                        label="Name *"
                                                        variant="standard"
                                                        placeholder="Name"
                                                        type="text"
                                                        name={"name"}
                                                        fullWidth
                                                        required={false}
                                                        value={titleCase(userData?.name)}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={errors?.name}
                                                        helperText={errors?.name}
                                                    />
                                                </Grid>
                                                <Grid item md={6} m={0}></Grid>
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <Input
                                                        id="email"
                                                        label="Email Address *"
                                                        variant="standard"
                                                        placeholder="Email"
                                                        type="email"
                                                        name={"email"}
                                                        fullWidth
                                                        required={false}
                                                        value={userData?.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={errors?.email}
                                                        helperText={errors?.email}
                                                    />
                                                </Grid>
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <Input
                                                        name={"current_user_type_id"}
                                                        select
                                                        type="select"
                                                        label="User Type"
                                                        defaultValue="Admin"
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={userData?.current_user_type_id}
                                                        error={!!errors?.current_user_type_id}
                                                        helperText={errors?.current_user_type_id}
                                                    >
                                                        {constantValues?.userTypeData?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {titleCase(option.name)}
                                                            </MenuItem>
                                                        ))}
                                                    </Input>
                                                </Grid>
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <Input
                                                        select
                                                        type="select"
                                                        label="User Role"
                                                        variant="standard"
                                                        name={"current_user_role_id"}
                                                        sx={{
                                                            width: "100%",
                                                            "& .MuiPopover-paper": {
                                                                boxShadow:
                                                                    "0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                                                            },
                                                        }}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={userData?.current_user_role_id}
                                                        error={!!errors?.current_user_role_id}
                                                        helperText={errors?.current_user_role_id}
                                                        disabled={userData?.is_admin_user || false}
                                                        InputLabelProps={{ shrink: userData?.is_admin_user || userData?.current_user_role_id }}
                                                    >
                                                        {constantValues?.userRolesData?.map((option) => (
                                                            <MenuItem
                                                                selected={option?.isSelected || false}
                                                                key={option.id}
                                                                value={option.id}
                                                                sx={{
                                                                    display: !option?.isVisible ? "none" : "block",
                                                                    "&:hover": {
                                                                        background: "rgba(4, 127, 224, 0.1)",
                                                                    },
                                                                }}
                                                            >
                                                                {titleCase(option.name)}
                                                            </MenuItem>
                                                        ))}
                                                    </Input>
                                                </Grid>
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <Input
                                                        name={"org_work_type_setting_id"}
                                                        select
                                                        type="select"
                                                        label="Work Type"
                                                        defaultValue="In-Office"
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        onChange={(...rest) => {
                                                            getLeaveTypesByOrganizationIdData(...rest);
                                                            handleChange(...rest);
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={userData?.org_work_type_setting_id}
                                                        error={!!errors?.org_work_type_setting_id}
                                                        helperText={
                                                            errors?.org_work_type_setting_id
                                                        }
                                                    >
                                                        {constantValues?.workTypeData?.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Input>
                                                </Grid>
                                                {loading?.leaveType ?
                                                    <React.Fragment>
                                                        <Grid item md={6} maxWidth={"100%"} >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                sx={{ borderRadius: "5px" }}
                                                                width={"100%"}
                                                                height={50}
                                                            />
                                                        </Grid>
                                                        <Grid item md={6} maxWidth={"100%"} >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                animation="wave"
                                                                sx={{ borderRadius: "5px" }}
                                                                width={"100%"}
                                                                height={50}
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                    : (userData?.leave_type_setting?.map((leaveData, mainIndex) => (
                                                        <Grid item md={6} maxWidth={"100%"} key={mainIndex}>
                                                            <Input
                                                                type="number"
                                                                label={`Opening Balance for ${leaveData?.name} (Days)`}
                                                                variant="standard"
                                                                sx={{ width: "100%" }}
                                                                parseFunction={Number.parseFloat}
                                                                name={"opening_balance"}
                                                                id={"opening_balance"}
                                                                onChange={(e) => handleChangeOpeningBalance(e, mainIndex)}
                                                                onBlur={(e) => validateOpeningBalance(e, mainIndex)}
                                                                value={leaveData?.opening_balance || 0}
                                                                error={errors?.leave_type_setting && !!errors?.leave_type_setting[mainIndex]?.opening_balance}
                                                                helperText={errors?.leave_type_setting && errors?.leave_type_setting[mainIndex]?.opening_balance}
                                                            />
                                                        </Grid>
                                                    )
                                                    ))}
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <FormControlLabel control={<Checkbox />} label="Is Dedicated Developer?" />
                                                </Grid>
                                                <Grid item md={6} maxWidth={"100%"}>
                                                    <FormControlLabel
                                                        value="end"
                                                        control={
                                                            <Checkbox checked={userData?.is_admin} />
                                                        }
                                                        label="Access to Admin Panel"
                                                        labelPlacement="end"
                                                        sx={{
                                                            padding: "0px 9px 9px",
                                                            '.MuiCheckbox-sizeMedium': {
                                                                minWidth: '42px'
                                                            }
                                                        }}
                                                        disabled={userData?.is_general_user || false}
                                                        onChange={(event, checked) =>
                                                            setUserData((prev) => ({
                                                                ...prev,
                                                                is_admin: checked
                                                            }))
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={4}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            flexBasis="100%"
                                            gap={2}
                                        >
                                            <Button
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                sx={{
                                                    boxShadow: "0",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    color: "white",
                                                    "&:hover": { boxShadow: "0" },
                                                }}
                                                disabled={isError()}
                                                isButtonLoading={loading.formSubmitting}
                                            >
                                                Create
                                            </Button>
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                size="large"
                                                sx={{
                                                    boxShadow: "0",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    "&:hover": { boxShadow: "0" },
                                                }}
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            }
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Box>
    )
}

export default CreateUser;