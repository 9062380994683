import secureLocalStorage from "react-secure-storage";
import URLS from "../routes/urls";
import { titleCase } from "../utils";
import useAuthentication from "./useAuthentication";
import { ON_BOARDING_STATUS } from "../constants/default-values";

export const usePageTitle = () => {
    const { getCurrentUser } = useAuthentication()
    const currentUser = getCurrentUser();

    let isIncompleteOnboarding = currentUser ? currentUser?.organization_status !== ON_BOARDING_STATUS.completed : false;
    let viewPages = [
        URLS.ViewMyLeaveApplication,
        URLS.ViewMyWfhApplication,
        URLS.OtherLeaveDetails,
        URLS.OtherWfhApplicationDetails
    ];
    let isViewPageInclude = viewPages.some(data => window.location.pathname.toLowerCase()?.includes(data))
    if ((window.location.pathname !== URLS.Login && !isIncompleteOnboarding && isViewPageInclude)) {
        secureLocalStorage.setItem("redirectPath", window.location.pathname)
    } else {
        if (window.location.pathname !== URLS.Login)
            secureLocalStorage.removeItem("redirectPath")
    }
    const setPageTitle = (pageName) => {
        document.title = `NextLogin | ${titleCase(pageName)}`
    }

    const setCustomPageTitle = (title) => {
        document.title = title;
    }

    return { setPageTitle, setCustomPageTitle };
};
