import { Box, Card, CardActionArea, FormControl, FormControlLabel, FormHelperText, Grid, Modal, Radio, RadioGroup, Skeleton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { capitalOnlyFirstLatter, sortArrayByKey, titleCase } from '../../../utils';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import API from '../../../axios/api';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../hook/useAlert';
import URLS from '../../../routes/urls';
import axiosInstance from '../../../axios';
import useAuthentication from '../../../hook/useAuthentication';
import secureLocalStorage from 'react-secure-storage';
import moment from 'moment';
import LogoutModal from '../../../components/LogoutModal';
import { createDefaultObject, validateLogoutModelData } from '../../../components/LogoutModal/utils-functions';
import { toast } from 'react-toastify';
import * as Muicon from "@mui/icons-material";
import EmptyPage from '../../../components/EmptyPage';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    bgcolor: "background.paper",
    boxShadow: "0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    outline: 'none',
};

const ActionCard = ({ icon, text, onClick, disabled, key }) => {
    const Icon = Muicon?.[icon];
    return (
        <Card
            key={key}
            sx={{
                boxShadow: "none !important",
                // minWidth: 120,
                minHeight: '100%',
                maxWidth: '170px',
                flex: '170px',
                border: "1px solid #E0E0E0"
            }}>
            <CardActionArea sx={{ minWidth: 120, minHeight: 115, textAlign: "center", px: 2, py: "30px" }} onClick={onClick} disabled={disabled}>
                {Icon ? <Icon color="secondary" sx={{ fontSize: 48 }} /> : null}
                <Typography color="secondary.main" fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"} mt={1.5}>{titleCase(text)}</Typography>
            </CardActionArea>
        </Card>
    );
};

const ActionCardWithCount = ({ icon, text, onClick, disabled, count = 0, key }) => {
    const Icon = Muicon?.[icon];
    return (
        <Card
            key={key}
            sx={{
                boxShadow: "none !important",
                // minWidth: 120,
                minHeight: '100%',
                maxWidth: '170px',
                flex: '170px',
                overflow: "visible",
                position: "relative",
                border: "1px solid #E0E0E0",
            }}>
            <Typography top={4} right={4} minHeight={24} minWidth={24} borderRadius={50} zIndex={2} fontSize={14} lineHeight={"16px"} position='absolute' bgcolor='primary.main' color="white" textAlign="center" display="flex" alignItems="center" justifyContent="center" sx={{
                "&:after": {
                    content: `'${count > 99 ? "99+" : count}'`,
                },
            }}>
            </Typography>
            <CardActionArea sx={{ minWidth: 120, minHeight: 115, textAlign: "center", px: 2, py: "30px" }} onClick={onClick} disabled={disabled}>
                {Icon ? <Icon color="secondary" sx={{ fontSize: 48 }} /> : null}
                <Typography color="secondary.main" fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"} mt={1.5}>{titleCase(text)}</Typography>
            </CardActionArea>
        </Card>
    )
}

const QuickActions = ({ actionsCount }) => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const showAlert = useAlert();
    let initialModalDetails = {
        isShow: false,
        isButtonLoading: false,
        title: "",
        message: "",
        project: "",
        actionType: "",
        error: {
            message: "",
            project: "",
        },
        notion: [],
        have_notes: false,
        activity_id: null,
    }
    const [modalDetails, setModalDetails] = useState(initialModalDetails);
    let initialModalValues = {
        isShow: false,
        isButtonLoading: false,
        date: null,
        msg: null,
        time: null,
        email: null,
        activity_id: null,
    }
    const [isLoading, setIsLoading] = useState({
        activity: true,
    });
    const [logoutModelDetails, setLogoutModelDetails] = useState(initialModalValues);
    const [errors, setErrors] = useState({});
    const [allActivity, setAllActivity] = useState([]);

    useEffect(() => {
        if (currentUser?.org_work_type_setting_id) {
            getAllActivityData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        setModalDetails(initialModalDetails);
    }

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        setModalDetails((prev) => ({ ...prev, [name]: value }));
        setModalDetails((prev) => ({
            ...prev,
            error: {
                ...prev?.error,
                [name]: !!value ? "" : "Required",
            }
        }));
    }

    const handleOnBlur = (e) => {
        let { name, value } = e.target;
        setModalDetails((prev) => ({
            ...prev,
            error: {
                ...prev?.error,
                [name]: !!value ? "" : "Required",
            }
        }));
    }

    const getAllActivityData = async () => {
        try {
            setIsLoading(prev => ({ ...prev, activity: true }));
            const response = await axiosInstance.get(
                API.getAllActivityByWorkType(currentUser?.org_work_type_setting_id)
            );
            if (response.status === 200) {
                if (response?.data?.data?.length > 0) {
                    let sortedActivity = sortArrayByKey(response?.data?.data, 'asc', 'title')
                    setAllActivity(sortedActivity);
                } else {
                    setAllActivity([]);
                }
            } else {
                toast.error(response?.data?.message);
                setAllActivity([]);
            }
            setIsLoading(prev => ({ ...prev, activity: false }));
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, activity: false }));
        }
    };

    const handleActionClick = (activityData) => {
        if (activityData?.have_notes) {
            setModalDetails((prev) => ({
                ...prev,
                activity_id: activityData?.id,
                actionType: activityData?.title,
                isShow: true,
                have_notes: activityData?.have_notes,
                notion: activityData?.notion,
                title: `${titleCase(activityData?.title)}`
            }))
        } else {
            setModalDetails((prev) => ({
                ...prev,
                activity_id: activityData?.id,
                actionType: activityData?.title,
                isShow: true,
                have_notes: activityData?.have_notes,
                title: `Are you sure you want to take ${titleCase(activityData?.title)}?`
            }))
        }
    }

    const handleOnSave = () => {
        try {
            if (modalDetails?.have_notes) {
                if (!modalDetails?.message?.length) {
                    setModalDetails((prev) => ({
                        ...prev,
                        error: {
                            ...prev?.error,
                            message: "Please enter message",
                        }
                    }));
                }
                // if (!modalDetails?.project) {
                //     setModalDetails((prev) => ({
                //         ...prev,
                //         error: {
                //             ...prev?.error,
                //             project: "Please select project",
                //         }
                //     }));
                // }
                // if (!!modalDetails?.message && !!modalDetails?.project) {
                if (modalDetails?.message?.length) {
                    setModalDetails((prev) => ({ ...prev, isButtonLoading: true }));
                    setModalDetails((prev) => ({
                        ...prev,
                        error: {
                            ...prev?.error,
                            message: "",
                            project: "",
                        }
                    }));
                    handleSubmitAction(modalDetails?.activity_id, modalDetails?.actionType, modalDetails?.message);
                }
            } else {
                handleSubmitAction(modalDetails?.activity_id, modalDetails?.actionType);
            }
        } catch (error) {
            console.error(error);
            handleClose()
        }
    }

    const handleOnSubmit = async () => {
        try {
            setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: true }))
            let payload = {
                email: logoutModelDetails?.email,
                activity: logoutModelDetails?.activity,
                activity_id: logoutModelDetails?.activity_id,
                msg: logoutModelDetails?.message,
                ask_login_details: { ...logoutModelDetails },
            };
            delete payload.ask_login_details.email;
            delete payload.ask_login_details.activity;
            delete payload.ask_login_details.activity_id;
            delete payload.ask_login_details.msg;
            delete payload.ask_login_details.message;
            delete payload.ask_login_details.last_activity;
            delete payload.ask_login_details.isShow;
            delete payload.ask_login_details.isButtonLoading;
            delete payload.ask_login_details.time;

            let errors = validateLogoutModelData(payload);
            setErrors(errors?.ask_login_details);
            if (!Object.values(errors)?.filter((value) => !!value)?.length > 0) {
                const response = await axiosInstance.post(API.addOverTime, payload);
                if (response.status === 200) {
                    showAlert(response.data.message);
                    setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
                    secureLocalStorage.clear()
                    navigate(URLS.Login, {
                        replace: true,
                    });
                } else {
                    showAlert(response.data.message, "error");
                    setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
                }
            } else {
                setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
            }
        } catch (error) {
            console.error(error)
            showAlert(error?.response?.data?.message, "error");
            setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
        }
    }

    const handleSubmitAction = async (activity_id, actionType, message = null, project = null) => {
        try {
            setModalDetails((prev) => ({ ...prev, isButtonLoading: true }));
            let payload = {
                activity: actionType,
                activity_id: activity_id,
            };
            if (!!message) {
                payload['message'] = message;
            }
            if (!!project) {
                payload['project'] = project;
            }
            const response = await axiosInstance.post(API.checkOutTimeTracker, payload);
            if (response.status === 200) {
                let ask_login_details = response.data?.ask_login_details;
                if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
                    const defaultObject = {
                        ...createDefaultObject(response.data?.ask_login_details),
                        date: response.data?.ask_login_details?.date,
                        time: moment(response.data?.ask_login_details?.date).format("hh:mm a"),
                    };
                    setLogoutModelDetails({
                        isShow: true,
                        ...defaultObject,
                        email: currentUser?.email,
                        activity_id: activity_id,
                        activity: actionType,
                        message: message || "",
                        last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
                    });
                } else {
                    secureLocalStorage.clear()
                    navigate(URLS.Login, {
                        replace: true,
                    });
                    showAlert(response?.data?.message);
                    handleClose();
                }
            } else {
                handleClose();
                showAlert(response?.data?.message, "error");
            }
            setModalDetails((prev) => ({ ...prev, isButtonLoading: false }));
        } catch (error) {
            console.error(error);
            setModalDetails(initialModalDetails);
            setModalDetails((prev) => ({ ...prev, isButtonLoading: false }));
        }
    }

    const handleChangeMessage = (e) => {
        if (e?.target?.value !== 'Other') {
            setModalDetails((prev) => ({
                ...prev, message: e?.target?.value, message_notion: '', error: {
                    ...prev?.error,
                    message: ''
                }
            }));
        } else {
            setModalDetails((prev) => ({ ...prev, message_notion: e?.target?.value, message: '' }))
        }
    }

    const getCountOfActions = (actionType) => {
        return actionsCount?.find((actionData) => actionData?.name === actionType)?.count ?? 0;
    }

    return (
        <Box sx={{ width: "100%", }}>
            <Typography color="secondary.main" fontSize={18} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} mb={1}>
                Quick Actions
            </Typography>
            {isLoading?.activity ?
                <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                    {Array.from({ length: 5 }, (v, i) => i).map((_, index) => <Skeleton variant="rounded" animation="wave" height={144} width={168} key={index} />)}
                </Box>
                :
                <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={3}>
                    {allActivity?.length ? allActivity?.map((activityData, index) => {
                        return getCountOfActions(activityData?.title) < 1 ?
                            <ActionCard icon={activityData?.activity_logo} text={activityData?.title} onClick={() => handleActionClick(activityData)} key={index} />
                            :
                            <ActionCardWithCount icon={activityData?.activity_logo} text={activityData?.title} onClick={() => handleActionClick(activityData)} count={getCountOfActions(activityData?.title)} key={index} />
                    }) : <EmptyPage isButtonShow={false} title={"No quick actions found."} />}
                </Box>}
            <LogoutModal
                actionType={"ask_login_details"}
                onApprove={handleOnSubmit}
                title={`Logout time for ${moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")}`}
                isShow={logoutModelDetails.isShow}
                isButtonLoading={logoutModelDetails.isButtonLoading}
                isShowCancelButton={false}
                errors={errors}
                setErrors={setErrors}
                logoutModelDetails={logoutModelDetails}
                setLogoutModelDetails={setLogoutModelDetails}
            />
            <Modal
                open={!!modalDetails?.isShow}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} width={600}>
                    <Grid Item py={2} px={3} borderBottom={modalDetails?.have_notes ? "1px solid #E2E4EC" : null}>
                        <Typography color="dark.800" fontSize={20} fontWeight={500} lineHeight={"32px"} letterSpacing={"0.15px"} variant="h6">{capitalOnlyFirstLatter(modalDetails?.title)}</Typography>
                    </Grid>
                    {modalDetails?.have_notes ?
                        <Grid Item p={3}>
                            <Stack spacing={2}>
                                {/* {modalDetails?.actionType === QUICK_ACTIONS.meeting ?
                                    <div style={{ width: "100%", paddingTop: "8px" }}>
                                        <Input
                                            id="project"
                                            select
                                            type="select"
                                            label="Project"
                                            name="project"
                                            variant="standard"
                                            sx={{ width: "100%" }}
                                            onChange={handleOnChange}
                                            onBlur={handleOnBlur}
                                            value={modalDetails?.project}
                                            error={Boolean(modalDetails?.error?.project)}
                                            helperText={modalDetails?.error?.project}
                                        >
                                            {projects?.map(
                                                (project, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        value={project?.id}
                                                    >
                                                        {project?.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Input>
                                    </div>
                                    : null} */}
                                {modalDetails?.notion?.length ?
                                    <React.Fragment>
                                        <Typography fontSize={16} fontWeight={400} lineHeight={'26px'} letterSpacing={'0.15px'}>
                                            {`Choose ${modalDetails?.title}`}
                                        </Typography>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                column
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="message_notion"
                                                value={modalDetails?.message_notion}
                                                onChange={handleChangeMessage}
                                            >
                                                {modalDetails?.notion?.map((notionData, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={notionData?.title}
                                                        control={<Radio />}
                                                        label={notionData?.title}
                                                        sx={{ color: "dark.800" }}
                                                    />
                                                ))}
                                                <FormControlLabel
                                                    value={'Other'}
                                                    control={<Radio />}
                                                    label={'Other'}
                                                    sx={{ color: "dark.800" }}
                                                />
                                            </RadioGroup>
                                            <FormHelperText error={false} sx={{ p: 0, m: 0 }}></FormHelperText>
                                        </FormControl>
                                    </React.Fragment>
                                    : null}

                                {(modalDetails?.message_notion === "Other" || !modalDetails?.notion?.length) ?
                                    <div style={{ width: "100%", paddingTop: "8px" }}>
                                        <Input
                                            label="Add Comment"
                                            variant="standard"
                                            size="medium"
                                            sx={{
                                                width: "100%",
                                                'input': {
                                                    fontSize: 14,
                                                    fontWeight: 400,
                                                    color: 'dark.800',
                                                    lineHeight: '24px',
                                                    letterSpacing: '0.15px'
                                                }
                                            }}
                                            value={modalDetails?.message}
                                            onChange={handleOnChange}
                                            onBlur={handleOnBlur}
                                            name="message"
                                            placeholder={"Write your message here..."}
                                            error={Boolean(modalDetails?.error?.message)}
                                            helperText={modalDetails?.error?.message}
                                        />
                                    </div> : null}
                            </Stack>
                        </Grid> : null}
                    <Grid Item py={2} px={3} borderTop="1px solid #E2E4EC">
                        <Stack spacing={1} direction="row">
                            <Button
                                variant="contained"
                                size="medium"
                                fontWeight="500"
                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                onClick={handleOnSave}
                                isButtonLoading={modalDetails?.isButtonLoading}
                                disabled={modalDetails?.isButtonLoading}
                            >
                                Confirm
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                fontWeight="500"
                                onClick={handleClose}
                                disabled={modalDetails?.isButtonLoading}
                            >
                                Close
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}

export default QuickActions;
