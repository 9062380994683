import React from 'react'
import { Box, Typography } from '@mui/material'

const UserRoles = () => {
    return (
        <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ background: "#FAFBFF" }}
        >
            <Typography
                variant="body1"
                fontWeight={400}
                sx={{ lineHeight: "24px", letterSpacing: "0.15px" }}
            >
                Coming soon...
            </Typography>
        </Box>
    )
}

export default UserRoles