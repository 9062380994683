/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Box,
    Card,
    Container,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import styled from "@emotion/styled";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import Input from "../../../components/Input";
import { getMaxSize, titleCase } from "../../../utils";
import CircularLoader from "../../../components/CircularLoader";
import useAuthentication from "../../../hook/useAuthentication";
import URLS from "../../../routes/urls";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { usePageTitle } from "../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";
import { useAlert } from "../../../hook/useAlert";
import { setUserDetails } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { PROFILE_IMAGE_SIZE, VALID_IMAGE_TYPE } from "../../../constants/default-values";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const AdminProfile = () => {
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setPageTitle } = usePageTitle();
    const showAlert = useAlert();
    const [userData, setUserData] = useState({});
    const [user, setUser] = useState({});
    const [errors, setErrors] = useState({});
    const [isChanged, setIsChanged] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
    });
    const [optionalImage, setOptionalImage] = useState(null);

    setPageTitle("My Profile");
    const getUserData = async (isShowLoading = true) => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: isShowLoading ? true : false }));
            const response = await axiosInstance.get(
                API.fetchUserDetails(currentUser?.id)
            );
            if (response.status === 200) {
                setUserData(response.data.data);
                setUser(response.data.data);
                const previewProfileImage = response.data?.data?.profile_img;
                if (previewProfileImage) {
                    setPreviewImage(previewProfileImage);
                    setOptionalImage(previewProfileImage)
                }
            }
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const handleChangeProfileImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (VALID_IMAGE_TYPE.includes(file.type)) {
                if (file.size <= PROFILE_IMAGE_SIZE) {
                    const previewURL = URL.createObjectURL(file);
                    setPreviewImage(previewURL);

                    setUserData((prev) => ({
                        ...prev,
                        profile_img: file
                    }));

                    setIsChanged((prev) => ({
                        ...prev,
                        profile_img: true
                    }));
                } else {
                    showAlert(`File size exceeds ${getMaxSize(PROFILE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
                    setUserData((prev) => ({
                        ...prev,
                        profile_img: optionalImage
                    }));
                }
            } else {
                showAlert('Only JPEG and PNG file types are allowed.', "error");
                setUserData((prev) => ({
                    ...prev,
                    profile_img: optionalImage
                }));
            }
        }
    };

    const handleRemoveProfileImage = () => {
        setUserData((prev) => ({
            ...prev,
            profile_img: null
        }));
        setIsChanged((prev) => ({ ...prev, is_remove_profile: true }));
        setPreviewImage(null);
    }

    useEffect(() => {
        if (currentUser?.id) {
            getUserData();
        }
    }, []);

    const handleCancel = () => {
        navigate(URLS.AdminDashboard)
    }

    const validateFormData = (formData) => {
        if (!formData?.name) {
            setErrors((prev) => ({ ...prev, name: "Required" }));
        } else {
            setErrors((prev) => ({ ...prev, name: "" }));
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (!validateFormData(userData)) {
                setLoading((prev) => ({ ...prev, formSubmitting: true }));
                let formData = new FormData();
                const config = {
                    headers: { "content-type": "multipart/form-data" },
                };
                formData.append("name", userData?.name);
                if (isChanged?.profile_img && userData?.profile_img?.size > 0) {
                    formData.append("profile_img", userData?.profile_img);
                }
                if (isChanged?.is_remove_profile) {
                    formData.append("is_changed", user?.profile_img);
                }
                if (userData?.profile_img?.size > 0 && !!user?.profile_img && user?.profile_img !== userData?.profile_img) {
                    formData.append("is_changed", user?.profile_img);
                }
                const response = await axiosInstance.put(API.updateUserProfile(currentUser?.id), formData, config);
                if (response.status === 200) {
                    setLoading((prev) => ({ ...prev, formSubmitting: false }));
                    showAlert(response.data.message);
                    let updatedUserData = {
                        ...currentUser,
                        name: userData?.name
                    };
                    if (!!response?.data?.profile_img) {
                        updatedUserData['profile_img'] = response?.data?.profile_img;
                    } else if (response?.data?.profile_img === null) {
                        updatedUserData['profile_img'] = null;
                    }
                    setOptionalImage(previewImage);
                    setUserData(updatedUserData);
                    setUser(updatedUserData);
                    dispatch(setUserDetails({ user: updatedUserData }))
                    setIsChanged({});
                } else {
                    setLoading((prev) => ({ ...prev, formSubmitting: false }));
                    showAlert(response?.response?.data?.message, "error");
                    setIsChanged({});
                }
            }
        } catch (error) {
            console.error(error);
            showAlert(error?.response?.data?.message || "Something went wrong", "error");
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }

    }

    const handleBlur = (e) => {
        let { name, value } = e.target;
        setErrors(prev => ({
            ...prev,
            [name]: !value ? "Required" : "",
        }))
    }

    const handleChange = (e) => {
        handleBlur(e);
        let { name, value } = e.target;
        if (userData[name] !== value) {
            setIsChanged((prev) => ({ ...prev, [name]: true }))
        }
        setUserData(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleCancelProfileImageChange = () => {
        setUserData((prev) => ({
            ...prev,
            profile_img: optionalImage
        }));
        setIsChanged((prev) => ({ ...prev, is_remove_profile: false, profile_img: false }));
        setPreviewImage(optionalImage);
    }

    let isActiveButton = Object.values(isChanged)?.some((value) => value === true);

    return (
        <Box sx={{ marginTop: "-190px" }} position="relative" zIndex={1}>
            <Container>
                <Link
                    component={RouteLink}
                    to={URLS.AdminDashboard}
                    display="inline-flex"
                    alignItems="center"
                    sx={{ textDecoration: "none", color: "white" }}
                    fontFamily={"fontFamily"}
                    p={0.5}
                    mb={1.5}
                >
                    <ChevronLeftIcon fontSize="small" sx={{ mr: 1 }} />
                    Back to Organisations
                </Link>
                <Typography variant="h6" color="white" marginBottom="12px">
                    My Profile
                </Typography>
                <Card
                    sx={{ bgcolor: "bgColor", p: { xs: 2, sm: 3, md: 4 }, boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)' }}
                    boxshadow={1}
                >
                    <Stack spacing={3}>
                        <Box container justifyContent="space-between" alignItems="center">
                            {loading.pageLoading ? <CircularLoader height="500px" /> :
                                <form onSubmit={handleSubmit} className="step-form-1">
                                    <Stack spacing={4} sx={{ flexBasis: "100%" }}>
                                        <Grid>
                                            <Grid display="flex" alignItems="center">
                                                <Box
                                                    mr={2.5}
                                                    sx={{
                                                        height: "90px",
                                                        width: "150px",
                                                        background: "rgba(63, 92, 118, 0.09)",
                                                        borderRadius: "6px",
                                                        overflow: "hidden",
                                                    }}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        alignItems={"center"}
                                                        justifyContent={"center"}
                                                        sx={{ height: "100%", width: "100%" }}
                                                    >
                                                        <BackgroundLetterAvatars
                                                            user={userData}
                                                            src={previewImage}
                                                            sx={{
                                                                width: "100%",
                                                                height: "100%",
                                                                borderRadius: 0,
                                                                fontSize: "35px",
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: 1,
                                                    flexDirection: "column"
                                                }}>
                                                    {(previewImage === optionalImage) ? <Button
                                                        component="label"
                                                        variant="text"
                                                        color="primary"
                                                        fontSize={13}
                                                        sx={{ textTransform: "none" }}
                                                    >
                                                        Change
                                                        <VisuallyHiddenInput
                                                            name="profile_img"
                                                            type="file"
                                                            onChange={handleChangeProfileImage}
                                                            accept="image/*"
                                                        />
                                                    </Button> : null}
                                                    {(isChanged?.profile_img || isChanged?.is_remove_profile) ? <Button
                                                        component="label"
                                                        variant="text"
                                                        color="error"
                                                        fontSize={13}
                                                        sx={{ textTransform: "none" }}
                                                        onClick={handleCancelProfileImageChange}
                                                    >
                                                        Cancel
                                                    </Button> : previewImage
                                                        ? <Button
                                                            component="label"
                                                            variant="text"
                                                            color="error"
                                                            fontSize={13}
                                                            sx={{ textTransform: "none" }}
                                                            onClick={handleRemoveProfileImage}
                                                        >
                                                            Remove
                                                        </Button> : null}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid width={"398px"} maxWidth={"100%"}>
                                            <Input
                                                id="name"
                                                label="Name*"
                                                variant="standard"
                                                placeholder="Name"
                                                type="text"
                                                name={"name"}
                                                fullWidth
                                                required={false}
                                                value={titleCase(userData?.name)}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={errors?.name}
                                                helperText={errors?.name}
                                            // isUpperCase={true}
                                            />
                                        </Grid>
                                        <Grid width={"398px"} maxWidth={"100%"}>
                                            <Input
                                                id="email"
                                                label="Email Address"
                                                variant="standard"
                                                placeholder="Email"
                                                type="email"
                                                name={"email"}
                                                fullWidth
                                                required={false}
                                                value={(userData?.email)}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                error={errors?.email}
                                                helperText={errors?.email}
                                                disabled={true}
                                            // isUpperCase={true}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        mt={4}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            flexBasis="100%"
                                            gap={2}
                                        >
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                size="large"
                                                sx={{
                                                    boxShadow: "0",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    "&:hover": { boxShadow: "0" },
                                                }}
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                sx={{
                                                    boxShadow: "0",
                                                    fontSize: "15px",
                                                    textTransform: "none",
                                                    color: "white",
                                                    "&:hover": { boxShadow: "0" },
                                                }}
                                                disabled={!isActiveButton || Boolean(Object.values(errors)?.filter(value => !!value).length)}
                                                isButtonLoading={loading.formSubmitting}
                                            >
                                                Update
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            }
                        </Box>
                    </Stack>
                </Card>
            </Container>
        </Box>

    );
};

export default AdminProfile;
