import { findAll } from "highlight-words-core";

const Highlighter = ({
    highlightClassName = "bg-yellow",
    highlightStyle,
    value,
    term
}) => {
    if (!term) {
        return <>{value}</>;
    }

    const chunks = findAll({
        searchWords: [term],
        textToHighlight: value
    });

    return (
        <>
            {chunks.map((chunk, index) => {
                const text = value.slice(chunk.start, chunk.end);

                if (chunk.highlight) {
                    return (
                        <span
                            key={index}
                            className={highlightClassName}
                            style={highlightStyle}
                        >
                            {text}
                        </span>
                    );
                }

                return <span key={index}>{text}</span>;
            })}
        </>
    );
}

export default Highlighter;