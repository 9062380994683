/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BackgroundLetterAvatars from '../../components/BackgroundLetterAvatars';
import { capitalOnlyFirstLatter, getApplicationNumber, titleCase } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotifications, markNotificationAsRead, readAllNotification } from '../../redux/slices/notificationSlice';
import URLS from '../../routes/urls';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularLoader from '../../components/CircularLoader';
import moment from 'moment';

const NotificationsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state?.user);
  const { notifications, totalUnReadCount, notificationLoading } = useSelector((state) => state?.notification);
  const [params, setParams] = useState({
    limit: 15,
    offSet: 0,
  });
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    dispatch(getAllNotifications({ userId: currentUser?.id, limit: params.limit, offSet: params.offSet }))
      .then((res) => {
        if (res.payload.count <= notifications?.length) {
          setHasMore(false);
        }
      });
  }, []);

  const fetchMoreNotifications = () => {
    let updatedOffset = params.limit + params.offSet
    setParams((prev) => ({
      ...prev,
      offSet: prev.offSet + prev.limit,
    }));
    dispatch(getAllNotifications({ userId: currentUser?.id, limit: params.limit, offSet: updatedOffset }))
      .then((res) => {
        if (res.payload.count <= notifications?.length) {
          setHasMore(false);
        }
      });
  };

  const handleViewNotification = (data) => {
    dispatch(markNotificationAsRead(data?.id));
    // if (!data?.msg?.includes("deleted")) {
    if (data?.category === "leaves" && data?.sub_category === "my_leave") {
      navigate(`${URLS.ViewMyLeaveApplication}/${data?.application_user_id}/${data?.application_id}`);
    } else if (data?.category === "WFH" && data?.sub_category === "my_wfh") {
      navigate(`${URLS.ViewMyWfhApplication}/${data?.application_user_id}/${data?.application_id}`);
    } else if (data?.category === "availability_change" && data?.sub_category === "my_wfh") {
      navigate(`${URLS.ViewMyWfhApplication}/${data?.application_user_id}/${data?.application_id}`);
    } else if (data?.category === "availability_change" && data?.sub_category === "other_wfh") {
      navigate(`${URLS.OtherWfhApplicationDetails}/${data?.application_user_id}/${data?.application_id}`);
    }
    else if (data?.category === "leaves" && data?.sub_category === "other_leave") {
      navigate(`${URLS.OtherLeaveDetails}/${data?.application_user_id}/${data?.application_id}`);
    }
    else if (data?.category === "WFH" && data?.sub_category === "other_wfh") {
      navigate(`${URLS.OtherWfhApplicationDetails}/${data?.application_user_id}/${data?.application_id}`);
    }
    // }
  };

  const handleReadAll = () => {
    dispatch(readAllNotification());
  };

  const handleReadNotification = (data) => {
    dispatch(markNotificationAsRead(data?.id));
  };

  return (
    <Box sx={{
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      width: '100%'
    }}>
      <Box
        px={4}
        pb={1}
        pt={4}
        // borderBottom="1px solid #E0E0E0"
        bgcolor="white"
        display="flex"
        alignItems="center"
      >
        {/* <IconButton
          aria-label="back"
          variant="outlined"
          color="secondary.100"
          size="small"
          sx={{
            borderRadius: "4px",
            marginRight: "10px",
            padding: '0px',
            'svg': {
              height: '100%',
              width: '100%'
            }
          }}
          onClick={() => navigate(-1)}>
          <i
            style={{
              height: "28px",
              width: "28px",
              transform: "rotate(90deg)",
            }}
          >
            {ICONS.ChevronLineSmall}
          </i>
        </IconButton> */}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}>
          <Box>
            <Typography
              variant="h2"
              color="secondary.main"
              fontSize={20}
              fontWeight={600}
              sx={{ lineHeight: "28px", letterSpacing: "0.15px" }}
            >
              All Notifications ({totalUnReadCount || 0})
            </Typography>
          </Box>
          <Box>
            {notifications?.length ?
              <IconButton
                color='primary'
                sx={{
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  borderRadius: 1,
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: 14,
                  gap: 1,
                }}
                onClick={() => handleReadAll()}>
                {/* <DoneAllIcon sx={{ verticalAlign: 'top', width: '22px', height: '22px' }} /> */}
                {titleCase("Read All")}
              </IconButton>
              : null}
          </Box>
        </Box>
      </Box>
      <Box flex={1} overflow="hidden" display="flex" width="100%" sx={{
        '.infinite-scroll-component__outerdiv': {
          display: 'flex',
          overflow: 'hidden',
          flex: '1',
          width: '100%',
        },
        'ul': {
          border: "1px solid #E0E0E0",
          borderRadius: '3px',
          'li:last-child': {
            borderBottom: 'none',
          }
        }
      }}>
        <InfiniteScroll
          dataLength={notifications.length} // Total number of notifications loaded so far
          next={fetchMoreNotifications}
          hasMore={hasMore}
          style={{
            scrollBehavior: "smooth",
            width: '100%',
            flex: 1,
            overflow: 'auto',
            padding: '0px 32px 32px 32px',
          }}
          height={"100%"}
          endMessage={notifications.length === totalUnReadCount && notifications?.length > 0 ?
            <Typography style={{ textAlign: 'center', margin: "20px" }}>
              All notifications have been loaded.
            </Typography> : null}
        >
          {notificationLoading ?
            <CircularLoader height="500px" /> :
            <List sx={{ p: 0, }}>
              {notifications?.length ? notifications?.map((data, index) => (
                <React.Fragment>
                  <ListItem
                    sx={{
                      padding: "0px !important",
                      position: 'relative',
                      '.MuiListItemSecondaryAction-root': {
                        position: 'absolute',
                        right: 'auto',
                        left: 0,
                      }
                    }}
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments" onClick={() => handleReadNotification(data)}>
                        <Box sx={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "50px",
                          backgroundColor: "primary.main",
                          alignSelf: 'center'
                        }}></Box>
                      </IconButton>
                    }
                  >
                    <ListItemButton onClick={() => handleViewNotification(data)} alignItems="center" sx={{ padding: '10px 12px 10px 25px !important', }} >
                      <ListItemAvatar>
                        <BackgroundLetterAvatars user={{
                          name: data?.application_user?.name,
                          profile_img: data?.application_user?.profile_img
                        }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={capitalOnlyFirstLatter(data?.msg)}
                        primaryTypographyProps={{
                          fontSize: 14,
                          color: '#262626',
                          lineHeight: '21px',
                          fontWeight: 400,
                          marginBottom: '4px'
                        }}
                        secondary={moment(data?.createdAt).format("DD/MM/YYYY hh:mm A")}
                        secondaryTypographyProps={{
                          fontSize: 11,
                          color: 'secondary',
                          fontWeight: 500,
                          lineHeight: '14px'
                        }}
                        sx={{ margin: '0px !important' }}
                      />
                      {!!data?.application_num ?
                        <Chip
                          sx={{ alignSelf: 'center', marginRight: '5px', width: "auto" }}
                          label={getApplicationNumber(data?.application_num)}
                          color='secondary'
                          size="small"
                          variant='filled'
                        /> : null}
                    </ListItemButton>
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              )) : <Box sx={{
                textAlign: "center",
                m: 1,
              }}>
                <Typography>No notification found.</Typography>
              </Box>}
            </List>}
        </InfiniteScroll>
      </Box>
    </Box>
  )
}

export default NotificationsPage;