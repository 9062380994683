/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import TextEditor from "../../../../../components/TextEditor";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDropZone from "../../../../../components/FileDropZone";
import SingleDatePicker from "../../../../../components/SingleDatePicker";
import axiosInstance from "../../../../../axios";
import API from "../../../../../axios/api";
import { useAlert } from "../../../../../hook/useAlert";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { convertDateFromFormat, filterUniqueLeaveData, getApplyDateTime, getDayName, getDurationFromDate, getFormattedDate, getFullName, isFutureYearDate, sortArrayByKey, titleCase } from "../../../../../utils";
import Button from "../../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import MultipleDatePickerWithButton from "../../../../../components/MultipleDatePickerWithButton";
import Breadcrumb from "../../../../../components/Breadcrumb";
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from "moment";
import { getDefaultInOutTime } from "../../../../../constants/default-values";
import TimePickerClock from "../../../../../components/TimePickerClock";

const EditWFHRequestDetails = ({
  setIsAppliedLeave,
  setLeaveData,
  autoGeneratedLeaveDays,
  leaveData,
  organizationUserList,
  userLeaveTypes,
  deletedDays,
  setDeletedDays,
  setDeletedImages,
  deletedImages,
  remainingCount,
  setRemainingCount,
  activeRequestType,
  renewalSettingData
}) => {
  const RequestedToUserDetails = organizationUserList?.find((user) => user?.name === leaveData?.informed_authorities[0]);
  const RequestedLeaveType = userLeaveTypes?.find(value => value?.id === leaveData?.static_id);
  const showAlert = useAlert();
  const params = useParams();
  const navigate = useNavigate();
  const [tempAddNewLeaves, setTempAddNewLeaves] = useState([]);
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
    formButtonDisable: false,
    isTimeValid: false
  });
  const [sortType, setSortType] = useState("asc");

  const [errors, setErrors] = useState({
    description: '',
    leave_days: {}
  });

  useEffect(() => {
    if (!!leaveData) {
      let wfhDays = leaveData?.WfhDays;
      let updatedErrors = { ...errors.leave_days };

      let isOutTimeMore = false;
      wfhDays?.map((wfhDetails) => {
        let inTime = wfhDetails?.in_time;
        let outTime = wfhDetails?.out_time;
        if (inTime >= outTime) {
          let value = moment(new Date(inTime)).format("hh:mm A");
          updatedErrors[`${wfhDetails?.date}`] = { 'out_time': `Please Enter check-out time after ${value}` };
          isOutTimeMore = true;
        }
        return wfhDetails;
      })
      setIsLoading(prev => ({ ...prev, isTimeValid: isOutTimeMore }))
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    }
  }, [])

  useEffect(() => {
    let sortedArray = sortArrayByKey(leaveData?.WfhDays, sortType, 'date');
    setLeaveData((prev) => ({
      ...prev,
      WfhDays: sortedArray
    }));
  }, [sortType])

  const isCreditEnable = () => {
    return !!activeRequestType?.find(request => request?.spec_id === leaveData?.static_id)
  }

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.credit_used || 0;
    });
    return totalCreditUsed || 0;
  };

  const filterLeaveDays = (array) => {
    return array?.filter(value => !!value && Object.keys(value)?.includes('isAutoGenerated') ? !value?.isAutoGenerated : true);
  };

  const handleChangeAddRemoveLeaves = async (type, leaveIndex, leaveDetail) => {
    if (type === "remove") {
      setDeletedDays((prev) => ([...prev, leaveDetail]));
      let updatedLeaveDays = leaveData?.WfhDays?.filter((leave, index) => index !== leaveIndex);
      setLeaveData((prev) => ({
        ...prev,
        WfhDays: sortArrayByKey(updatedLeaveDays, "asc", "date"),
      }));
      setRemainingCount(prev => prev + 1);
      setTempAddNewLeaves([]);
    } else {
      try {
        const addLeavesData = tempAddNewLeaves?.map((dates) => getFormattedDate(new Date(dates), "DD/MM/YYYY"));
        if (addLeavesData?.length > 0) {
          let { inTime, outTime } = getDefaultInOutTime();
          let updatedLeaveDays = [
            ...leaveData?.WfhDays,
            ...addLeavesData?.map((date) => ({
              date: date,
              leave_duration: "full",
              credit_used: isFutureYearDate(renewalSettingData, date) ? 0 : 1,
              is_changed: false,
              // in_time: moment(getDefaultInOutTime()?.inTime).format("HH:mm:ss"),
              // out_time: moment(getDefaultInOutTime()?.outTime).format("HH:mm:ss"),
              in_time: inTime,
              out_time: outTime,
            }))];
          setLeaveData((prev) => ({
            ...prev,
            WfhDays: sortArrayByKey([...updatedLeaveDays], "asc", "date"),
          }));
          setRemainingCount(prev => prev - (addLeavesData?.length || 0));
          setTempAddNewLeaves([]);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleChangeSelectedDate = async (
    newSelectedDate,
    index,
    leaveDetail
  ) => {
    try {
      let updatedLeaveDays = filterLeaveDays(leaveData?.WfhDays)?.map((item, i) => i === index ? {
        date: getFormattedDate(new Date(newSelectedDate), "DD/MM/YYYY"),
        leave_duration: leaveDetail?.leave_duration,
        paid: leaveDetail?.paid ?? 0,
        unpaid: leaveDetail?.unpaid ?? 0,
        in_time: leaveDetail?.in_time ?? null,
        out_time: leaveDetail?.out_time ?? null,
        id: leaveDetail?.id,
        is_changed: true,
        rules: item?.rules ?? [],
        credit_used: isFutureYearDate(renewalSettingData, getFormattedDate(new Date(newSelectedDate), "DD/MM/YYYY")) ? 0 : item?.credit_used,
      } : {
        ...item
      });
      const updatedList = updatedLeaveDays?.map(leave => {
        return { ...leave, credit_used: isFutureYearDate(renewalSettingData, leave?.date) ? 0 : 1, }
      });
      setLeaveData((prev) => ({
        ...prev,
        WfhDays: sortArrayByKey(updatedList, "asc", "date"),
      }));
      setTempAddNewLeaves([]);

    } catch (error) {
      console.error(error);
    }
  };

  const handelTimeChange = (time, index, leaveDetail, field, date) => {
    let updatedErrors = { ...errors?.leave_days };
    if (!time) {
      updatedErrors[`${date}`] = { [field]: `Required` };
    } else {
      let inTime = field === "in_time" ? time : leaveDetail?.in_time;
      let outTime = field === "out_time" ? time : leaveDetail?.out_time;
      if (inTime >= outTime) {
        let value = moment(new Date(inTime)).format("hh:mm A");
        updatedErrors[`${date}`] = { 'out_time': `Please Enter check-out time after ${value}` };
        setIsLoading(prev => ({ ...prev, isTimeValid: true }))
      } else {
        delete updatedErrors[`${date}`];
        setIsLoading(prev => ({ ...prev, isTimeValid: false }))
      }
    }
    setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));

    const updatedLeaveDetails = {
      ...leaveData,
      WfhDays: leaveData?.WfhDays?.map(
        (item, i) => {
          if (i === index) {
            return { ...item, [field]: new Date(time), is_changed: true, };
          } else {
            return item;
          }
        }
      ),
    };
    setLeaveData(updatedLeaveDetails);
  }

  const isValidateData = () => {
    let isEmpty = !leaveData?.description || leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length === '' || errors?.description?.length;

    if (!errors?.description?.length && isEmpty) {
      setErrors((prev) => ({
        ...prev,
        description: 'Reason Required'
      }))
    } else {
      let reasonLength = leaveData?.description
        ?.replace(/&nbsp;/g, ' ')
        ?.replace(/<(.|\n)*?>/g, '')
        ?.trim()?.length;

      isEmpty = reasonLength < 50 || reasonLength > 500;
      setErrors((prev) => ({
        ...prev,
        description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
          : 'Reason Required'
      }))
    }
    setIsLoading((prev) => ({ ...prev, formButtonDisable: isEmpty }))
    return !isEmpty;
  }

  const handleSubmitLeaveApplication = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }))
      function convertObjectToFormData(obj) {
        const formData = new FormData();

        function findDeletedElements(array1, array2) {
          if (!array2?.length)
            return [];
          let deletedElements = [];
          array1?.filter(value => !!value)?.forEach(element => {
            let found = array2?.some(item => item?.name === element?.split('/').pop());
            if (!found) {
              deletedElements.push(element);
            }
          });
          return deletedElements;
        }

        const deletedElements = findDeletedElements(obj?.attachment_references, obj?.attachments);

        obj?.attachment_references?.forEach((attachment, index) => {
          if (!deletedElements.includes(attachment))
            formData.append(`attachment_references[${index}]`, attachment);
        });
        obj?.attachments?.forEach((attachment, index) => {
          if (!!attachment?.size) {
            formData.append('attachments', attachment);
          }
        });
        deletedImages?.forEach((attachment, index) => {
          formData.append(`delete_attachment[${index}]`, attachment?.preview);
        });
        formData.append(
          "static_id",
          obj.static_id
        );
        formData.append(
          "is_special_request_changed",
          obj.is_special_request_changed
        );
        formData.append(
          "is_edit",
          true
        );
        formData.append("description", obj.description);
        formData.append("apply_date", convertDateFromFormat(obj?.apply_date));
        obj.informed_authorities?.forEach((authority, index) => {
          const RequestedToUserDetails = organizationUserList?.filter((user) => user?.name === authority);
          formData.append(`informed_authorities[${index}]`, RequestedToUserDetails[0]?.id);
        });
        let filteredData = [...filterLeaveDays(obj?.WfhDays), ...filterUniqueLeaveData(autoGeneratedLeaveDays)];
        filteredData?.forEach((leaveDay, index) => {
          formData.append(
            `wfh_days[${index}][date]`,
            convertDateFromFormat(leaveDay?.date)
          );
          if (!!leaveDay?.id) {
            formData.append(
              `wfh_days[${index}][id]`, leaveDay?.id || null);
          }
          formData.append(
            `wfh_days[${index}][leave_duration]`,
            leaveDay.leave_duration
          );
          formData.append(
            `wfh_days[${index}][credit_used]`,
            leaveDay?.credit_used || 0
          );
          formData.append(
            `wfh_days[${index}][in_time]`,
            // moment(getFullDateFromTime(leaveDay?.in_time)).format("HH:mm") || null
            moment(leaveDay?.in_time).format("HH:mm")
          );
          formData.append(
            `wfh_days[${index}][out_time]`,
            // moment(getFullDateFromTime(leaveDay?.out_time)).format("HH:mm") || null
            moment(leaveDay?.out_time).format("HH:mm")
          );
          if (!!leaveDay?.id) {
            formData.append(
              `wfh_days[${index}][is_changed]`,
              leaveDay?.is_changed || false
            );
          }
        });

        deletedDays?.filter(value => !!value?.id)?.forEach((leaveDay, index) => {
          let updatedIndex = filteredData?.length + index;
          formData.append(
            `wfh_days[${updatedIndex}][is_deleted]`,
            true
          );
          formData.append(
            `wfh_days[${updatedIndex}][date]`,
            // leaveDay?.date
            convertDateFromFormat(leaveDay?.date, "YYYY-MM-DD", "DD/MM/YYYY")
          );
          if (!!leaveDay?.id) {
            formData.append(
              `wfh_days[${updatedIndex}][id]`, leaveDay?.id || null);
          }
          formData.append(
            `wfh_days[${updatedIndex}][leave_duration]`,
            leaveDay.leave_duration
          );
          formData.append(
            `wfh_days[${updatedIndex}][credit_used]`,
            leaveDay?.credit_used || 0
          );
          if (!!leaveDay?.id) {
            formData.append(
              `wfh_days[${updatedIndex}][is_changed]`,
              leaveDay?.is_changed || false
            );
          }
        });

        return formData;
      }
      if (isValidateData()) {
        const formData = convertObjectToFormData(leaveData);
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        const response = await axiosInstance.put(
          API.updateWfhApplicationById(params?.userId, params?.appId),
          formData,
          config
        );
        if (response.status === 200) {
          showAlert(response?.data.message);
          navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`)
        } else {
          showAlert(response?.response?.data?.message, "error");
        }
      }
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data?.message, "error");
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
    }
  };

  const handleChangeClick = () => {
    setTimeout(() => {
      setIsAppliedLeave(false);
    }, 0);
  }

  const handleBackClick = () => {
    navigate(-1);
  }

  const handleDeleteImage = async (file) => {
    if (!file?.size) {
      setDeletedImages(prev => [...prev, file])
    }
  }

  const handleChangeDescription = (value) => {
    let tempVal = value;
    const isEmpty = !tempVal || tempVal?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim() === '';
    let reasonLength = value?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))

    setLeaveData({
      ...leaveData,
      description: value,
    })
    setErrors((prev) => ({
      ...prev,
      description: !isEmpty ? '' : 'Reason Required'
    }))
  }

  const handleBlurDescription = (value) => {
    let reasonLength = leaveData?.description?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))

    setErrors((prev) => ({
      ...prev,
      description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
        : 'Reason Required'
    }))
  }

  const getReasonLength = () => {
    return leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
  }

  return (
    <>
      <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
        <Breadcrumb isBack={true} title={"My WFH Request"}
          pageTitle={'WFH Request'}
          isPathShow={false}
          onBackClick={() => handleBackClick()} />
        <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width={'100%'} sx={{ overflowY: 'auto' }}>
          <Box>
            <Box
              width={610}
              maxWidth="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius={1}
              sx={{
                padding: "16px !important",
                background: "#F7F7F7",
                marginBottom: "16px",
              }}
            >
              <Grid Item>
                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <Typography
                    color="dark.800"
                    fontSize={18}
                    fontWeight={600}
                    lineHeight="24px"
                    letterSpacing="0.15px"
                  >
                    {`${RequestedLeaveType?.name === "Come late" ? "Late Coming" : titleCase(RequestedLeaveType?.name)} Request`}
                  </Typography>
                  <span>
                    (Apply on  {getApplyDateTime(leaveData?.createdAt || leaveData?.apply_date)})
                  </span>
                </Grid>
                <Typography
                  color="dark.800"
                  fontSize={14}
                  fontWeight={400}
                  lineHeight="24px"
                  letterSpacing="0.15px"
                >
                  Request to:{" "}
                  {`${getFullName(RequestedToUserDetails)} (${RequestedToUserDetails?.email})`}
                </Typography>
              </Grid>
              <Button
                variant="text"
                onClick={handleChangeClick}
                sx={{ textTransform: 'none', }}
                disabled={isLoading?.formSubmitting}
              >Change</Button>
            </Box>

            <TableContainer
              component={Paper}
              sx={{ border: "1px solid #E0E0E0", boxShadow: "none", mb: 3 }}
            >
              <Table aria-label="simple table">
                <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                  <TableRow
                    sx={{
                      th: {
                        color: "dark.800",
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        padding: "6px 16px",
                        whiteSpace: 'nowrap',
                      },
                    }}
                  >
                    <TableCell>
                      <TableSortLabel
                        active={true}
                        direction={sortType}
                        onClick={() => setSortType((prev) => prev === 'asc' ? 'desc' : 'asc')}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ width: 115, maxWidth: 115, minWidth: 115 }}>
                      Day
                    </TableCell>
                    <TableCell sx={{ width: 115, maxWidth: 115, minWidth: 115 }}>
                      Check-in Time
                    </TableCell>
                    <TableCell sx={{ width: 115, maxWidth: 115, minWidth: 115 }}>
                      Check-out Time
                    </TableCell>
                    {/* {['leave early', 'come late'].includes(RequestedLeaveType?.name?.toLowerCase()) ?
                      <TableCell sx={{ width: 115, maxWidth: 115, minWidth: 115 }}>
                        Duration
                      </TableCell> : null} */}
                    <TableCell sx={{ width: 150, maxWidth: 150, minWidth: 150 }}>
                      Duration
                    </TableCell>
                    {isCreditEnable() ? <TableCell width={110}>Credit</TableCell> : null}
                    <TableCell width={90} align="center">
                      Remove
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveData?.WfhDays?.map((leaveDetail, index, leaveArray) => (
                    <TableRow
                      key={index}
                      sx={{
                        td: {
                          color: "dark.800",
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          padding: "7px 16px",
                          border: 0,
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        },
                      }}
                    >
                      <TableCell
                        sx={{ width: 160, maxWidth: 160, minWidth: 160 }}
                      >
                        <Grid Item className="calendar-date-picker"
                          sx={{
                            'input': {
                              width: '100%',
                              height: 30,
                              border: 'none !important',
                              boxShadow: 'none !important',
                              borderRadius: '0px',
                              paddingBottom: '6px',
                              paddingLeft: '28px',
                              borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                              margin: '0',
                              position: 'relative',
                              background: 'transparent',
                              color: 'dark.800',
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px'
                            },
                            'input:disabled': {
                              color: 'rgb(170, 170, 170)',
                              borderColor: 'rgba(118, 118, 118, 0.3) !important',
                            },
                            'input:disabled:hover': {
                              color: 'rgb(170, 170, 170)',
                              borderColor: 'rgba(118, 118, 118, 0.3) !important',
                            },
                            'input:hover': {
                              borderBottomColor: '#333333 !important',
                            },
                            'input:focus': {
                              borderBottomColor: '#047FE0 !important',
                            },
                            '.rmdp-container': {
                              minWidth: '100px',
                            },
                          }}
                        >
                          <div style={{ height: 1 }}>
                            <CalendarTodayIcon
                              sx={{
                                fontSize: 20,
                                marginBottom: '-5px',
                                marginLeft: '2px',
                                color: ((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated)) ? "rgb(170, 170, 170) !important" : "#3F5C76",
                              }}
                            />
                          </div>
                          <SingleDatePicker
                            extraDisableDates={leaveData?.WfhDays?.map(leaveDetail => leaveDetail?.date)}
                            value={leaveDetail?.date}
                            onChange={(value) =>
                              handleChangeSelectedDate(value, index, leaveDetail)
                            }
                            isMultiple={false}
                            disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell
                        color="dark.800"
                        fontWeight={400}
                        fontSize={14}
                        lineHeight="24px"
                        letterSpacing="0.15px"
                      >
                        {getDayName(leaveDetail?.date)}
                      </TableCell>
                      <TableCell>
                        <Grid Item
                          sx={{
                            '.rmdp-container': {
                              minWidth: { xs: '130px !important', xl: '170px !important' },
                            },
                            'input': {
                              width: '100%',
                              height: 30,
                              border: 'none !important',
                              boxShadow: 'none !important',
                              borderRadius: '0px',
                              paddingBottom: '6px',
                              paddingLeft: '28px',
                              borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                              margin: '0',
                              position: 'relative',
                              background: 'transparent',
                              color: 'dark.800',
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px'
                            },
                            'input:hover': {
                              borderBottomColor: '#333333 !important',
                            },
                            'input:focus': {
                              borderBottomColor: '#047FE0 !important',
                            },
                          }}
                        >
                          <div style={{ height: 1 }}>
                            <ScheduleIcon
                              sx={{
                                fontSize: 20,
                                color: '#3F5C76',
                                marginBottom: '-9px',
                                marginLeft: '1px',
                              }}
                            />
                          </div>
                          <TimePickerClock
                            value={leaveDetail?.in_time || null}
                            onChange={(time) => {
                              handelTimeChange(time, index, leaveDetail, "in_time", leaveDetail?.date)
                            }}
                          />
                          {/* {!!errors?.leave_days[index] ? <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className="error-arrow-top"></span><Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>{errors?.leave_days[index][`time-${index}`]}</Typography></div></div> : null} */}
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid Item
                          sx={{
                            '.rmdp-container': {
                              minWidth: { xs: '130px !important', xl: '170px !important' },
                            },
                            'input': {
                              width: '100%',
                              height: 30,
                              border: 'none !important',
                              boxShadow: 'none !important',
                              borderRadius: '0px',
                              paddingBottom: '6px',
                              paddingLeft: '28px',
                              borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                              margin: '0',
                              position: 'relative',
                              background: 'transparent',
                              color: 'dark.800',
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px'
                            },
                            'input:hover': {
                              borderBottomColor: '#333333 !important',
                            },
                            'input:focus': {
                              borderBottomColor: '#047FE0 !important',
                            },
                          }}
                        >
                          <div style={{ height: 1 }}>
                            <ScheduleIcon
                              sx={{
                                fontSize: 20,
                                color: '#3F5C76',
                                marginBottom: '-9px',
                                marginLeft: '1px',
                              }}
                            />
                          </div>
                          <TimePickerClock
                            value={leaveDetail?.out_time || null}
                            onChange={(time) => {
                              handelTimeChange(time, index, leaveDetail, "out_time", leaveDetail?.date)
                            }}
                          />
                          {/* {!!errors?.leave_days[index] ? <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className="error-arrow-top"></span><Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>{errors?.leave_days[index][`time-${index}`]}</Typography></div></div> : null} */}
                          {!!errors?.leave_days?.[`${leaveDetail?.date}`]?.out_time ? <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className="error-arrow-top"></span><Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>{errors?.leave_days?.[`${leaveDetail?.date}`]?.out_time}</Typography></div></div> : null}
                        </Grid>
                      </TableCell>
                      <TableCell sx={{ width: 150, maxWidth: 150, minWidth: 150 }} align="left"
                        color="dark.800"
                        fontWeight={400}
                        fontSize={14}
                        lineHeight="24px"
                        letterSpacing="0.15px"
                      >{getDurationFromDate(leaveDetail?.in_time, leaveDetail?.out_time)}</TableCell>
                      {isCreditEnable() ? <TableCell
                        width={110}
                        color="dark.800"
                        fontWeight={400}
                        fontSize={14}
                        lineHeight="24px"
                        letterSpacing="0.15px"
                      >
                        {!!leaveDetail?.credit_used
                          ? leaveDetail?.credit_used
                          : null}
                      </TableCell> : null}
                      <TableCell width={90} align="center">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="secondary"
                          sx={{ borderRadius: "4px" }}
                          disabled={leaveData?.WfhDays?.length <= 1 || leaveDetail?.canDelete === false || !!leaveDetail?.isAutoGenerated}
                          onClick={() =>
                            handleChangeAddRemoveLeaves(
                              "remove",
                              index,
                              leaveDetail
                            )
                          }
                        >
                          <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow
                    sx={{
                      td: {
                        padding: "4px 10px",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      },
                    }}
                  >
                    <TableCell colSpan={7}>
                      <MultipleDatePickerWithButton
                        extraDisableDates={leaveData?.WfhDays?.map(leaveDetail => leaveDetail?.date)}
                        onChange={(dates) => setTempAddNewLeaves(dates)}
                        onClose={() => handleChangeAddRemoveLeaves("add")}
                        selectedDates={tempAddNewLeaves}
                        setSelectedDates={setTempAddNewLeaves}
                      />
                    </TableCell>
                  </TableRow>
                  {
                    (isCreditEnable() && Number(getTotalCreditUsedCount(leaveData?.WfhDays)) > 0) ? <TableRow
                      sx={{
                        td: {
                          padding: "10px 16px",
                          border: 0,
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        },
                      }}
                    >
                      <TableCell colSpan={5}></TableCell>
                      <TableCell colSpan={2}>
                        <Typography
                          color="error.main"
                          fontWeight={700}
                          sx={{ marginLeft: "0px" }}
                        >
                          {getTotalCreditUsedCount(leaveData?.WfhDays)}{" "}
                          Credit Applied
                        </Typography>
                      </TableCell>
                    </TableRow> : null
                  }
                </TableBody>
                <TableFooter
                  sx={{ 'td': { padding: 0, } }}
                >
                  {isCreditEnable() && Number(getTotalCreditUsedCount(leaveData?.WfhDays)) > 0 ? <TableRow>
                    <TableCell colSpan={5}></TableCell>
                    <TableCell colSpan={2}>
                      <Grid
                        Item
                        width={200}
                        sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                      >
                        <Typography
                          color="dark.800"
                          variant="body2"
                          lineHeight="24px"
                          letterSpacing="0.17px"
                          fontWeight={600}
                        >
                          Remaining Credits: {remainingCount > 0 ? remainingCount : 0}
                        </Typography>
                      </Grid>
                    </TableCell>
                  </TableRow> : null}
                </TableFooter>
              </Table>
            </TableContainer>
            <Box mb={3}>
              <Typography
                variant="body2"
                fontWeight={400}
                sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
              >
                Reason*
              </Typography>
              <TextEditor
                value={leaveData.description}
                onChange={(value) =>
                  handleChangeDescription(value)
                }
                onBlur={(value) => handleBlurDescription(value)}
              />
              <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#FFF6DF', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className=""></span><Typography sx={{ color: "black", fontSize: '11px', fontWeight: 400, }}>{`${getReasonLength() ?? 0} / 500`}</Typography></div></div>
              {!!errors?.description?.length ? <Typography
                variant="body2"
                fontWeight={400}
                color={'error'}
                sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
              >
                {errors?.description}
              </Typography> : null}
            </Box>
            <Box mb={3}>
              <Grid Item display="flex" alignItems="center" mb={1}>
                <Typography
                  variant="body1"
                  color="dark.800"
                  fontWeight={400}
                  sx={{ lineHeight: "21px", letterSpacing: "0.15px" }}
                  mr={1}
                >
                  Attachments:
                </Typography>
                <Typography
                  color="dark.600"
                  fontSize={11}
                  fontWeight={400}
                  sx={{ lineHeight: "15px", letterSpacing: "0.17px" }}
                >
                  (Upto 10MB, upto 5 attachments)
                </Typography>
              </Grid>
              <Box>
                <FileDropZone
                  onDrop={(files) =>
                    setLeaveData({
                      ...leaveData,
                      attachments: files,
                    })
                  }
                  onDeleteImage={handleDeleteImage}
                  initialFiles={leaveData?.attachments}
                  fileURLs={leaveData?.attachment_references}
                />
              </Box>
            </Box>
            <Grid Item>
              <Button
                disabled={isLoading.formSubmitting || isLoading?.formButtonDisable || isLoading?.isTimeValid}
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  color: "white",
                  textTransform: "none",
                  marginRight: "20px",
                  boxShadow: "none",
                }}
                type="button"
                onClick={handleSubmitLeaveApplication}
                isButtonLoading={isLoading.formSubmitting}
              >
                Update
              </Button>
              <Button
                variant="text"
                color="secondary"
                size="large"
                sx={{ textTransform: "none" }}
                onClick={() => navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`)}
                disabled={isLoading.formSubmitting}
              >
                Cancel
              </Button>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditWFHRequestDetails;
