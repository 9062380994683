import { Grid, TableCell, TableRow } from '@mui/material'
import React from 'react'

const EmptyTable = ({ hover = true, colSpan = 6, message = "No Data Found !", height = 150 }) => {
    return (
        <TableRow hover={hover} tabIndex={-1}>
            <TableCell colSpan={colSpan} sx={{ textAlign: "center", padding: '0px !important' }}>
                <Grid bgcolor="lightGray" height={height} display="flex" alignItems="center" justifyContent="center" p={4}>
                    {message}
                </Grid>
            </TableCell>
        </TableRow>
    )
}

export default EmptyTable