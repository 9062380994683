import React, { createContext, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import { capitalOnlyFirstLatter } from "../utils";
import 'react-toastify/dist/ReactToastify.css';

const AlertContext = createContext();

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error("useAlert must be used within an AlertProvider");
    }
    return context;
};

export const AlertProvider = ({ children }) => {
    const showAlert = (newMessage, newSeverity = "success") => {
        toast(capitalOnlyFirstLatter(newMessage), { type: newSeverity });
    };

    return (
        <AlertContext.Provider value={showAlert}>
            <ToastContainer position="top-right" autoClose={3000} />
            {children}
        </AlertContext.Provider>
    );
};
