import React from "react";
import { Box, Grid } from "@mui/material";
import AppBar from "../../../../components/AppBar";
import ICONS from "../../../../constants/icons"

const OnBoardingWrapper = ({ children }) => {
  return (
    <Box
      display="flex"
      minHeight={"100vh"}
      sx={{ overflow: "hidden" }}
      bgcolor={"bgColor"}
    >
      <AppBar appBarType={"on-boarding"} />
      <Box
        sx={{ flexGrow: 1, marginTop: "62px" }}
        position="relative"
        display="flex"
      >
        <Grid container>
          <Grid
            item
            xs={12}
            lg={8}
            xl={7}
            bgcolor={"white"}
            height="100%"
            sx={{
              borderRight: "1px solid #E4E4E4",
            }}
            p={{ xs: 5, lg: 8 }}
            py={{ xs: 5, lg: 6 }}
            display="flex"
          >
            {children}
          </Grid>
          <Grid item lg={4} xl={5} display={{ xs: 'none', lg: 'block' }}>
            <Box
              sx={{
                position: "fixed",
                filter: "blur(18px)",
                bottom: 0,
                top: 0,
                right: "-20px",
              }}
              display="flex"
              alignItems="center"
            >
              <i style={{ height: "calc(100vh - 50vh)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBgRight}</i>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OnBoardingWrapper;
