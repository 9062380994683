import { Box, Grid, Modal, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import Button from '../Button';
import SingleDatePicker from '../SingleDatePicker';
import moment from 'moment';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { durationToWord, titleCase } from '../../utils';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';

const CustomDatePicker = ({ value, onChange, label, minDate, maxDate, onOpen, onClose, error = null, ...rest }) => {
    return <Grid
        Item
        flex={1}
        sx={{
            input: {
                width: "100%",
                height: 30,
                border: "none !important",
                boxShadow: "none !important",
                borderRadius: "0px",
                paddingBottom: "6px",
                borderBottom:
                    "1px solid rgba(0, 0, 0, 0.42) !important",
                margin: "0",
                position: "relative",
                background: "transparent",
                color: "dark.800",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
                letterSpacing: "0.15px",
                paddingLeft: '0px',
            },
            "input:hover": {
                borderBottomColor:
                    "#333333 !important",
            },
            "input:focus": {
                borderBottomColor:
                    "#047FE0 !important",
            },
            ".rmdp-container": {
                minWidth: "100px",
            },
        }}
    >
        <div style={{ position: 'absolute', right: '0px', top: '20px', marginTop: '0px' }}>
            <CalendarTodayIcon
                sx={{
                    fontSize: 20,
                    marginBottom: '-5px',
                    marginLeft: '2px',
                    color: 'rgba(0, 0, 0, 0.54)',
                }} />
        </div>
        <Typography
            id="demo-simple-select-standard-label"
            fontSize={12}
            fontWeight={400}
            color="dark.600"
            sx={{
                width: "100%",
                letterSpacing: "0.15px",
                marginTop: "-3px",
                marginBottom: "2px",
            }}
        >
            {titleCase(label)}
        </Typography>
        <SingleDatePicker
            value={value}
            onChange={onChange}
            isMultiple={false}
            onOpen={onOpen}
            minDate={minDate}
            maxDate={maxDate}
            onClose={onClose}
            {...rest}
        />
        {!!error ? <Typography sx={{ color: "red", fontSize: "12px" }}>{error}</Typography> : null}
    </Grid>
};

const CustomTimePicker = ({ value, onChange, label, onOpen, onClose, error = null, isMaxTime = false, ...rest }) => {
    let getMaxTime = () => {
        let value = moment(new Date()).format("hh:mm:ss");
        let [hh, mm, ss] = value.split(':').map(Number);
        return dayjs().set('hour', hh).set('minute', mm).set('second', ss);
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem label={titleCase(label)}
            sx={{
                '.MuiTypography-body2': {
                    fontSize: 12,
                    fontWeight: 400,
                    color: 'dark.600',
                    width: "100%",
                    letterSpacing: "0.15px",
                    marginTop: "-3px",
                    marginBottom: "2px",
                },
                '.MuiFormControl-root': {
                    margin: '0',
                },
                'fieldset': {
                    border: 'none',
                },
                '.MuiOutlinedInput-root': {
                    padding: '0px',
                },
                'input': {
                    width: "100%",
                    height: 30,
                    border: "none !important",
                    boxShadow: "none !important",
                    borderRadius: "0px",
                    paddingBottom: "6px",
                    borderBottom:
                        "1px solid rgba(0, 0, 0, 0.42) !important",
                    margin: "0",
                    position: "relative",
                    background: "transparent",
                    color: "dark.800",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    padding: '0px'
                },
                "input:hover": {
                    borderBottomColor:
                        "#333333 !important",
                },
                "input:focus": {
                    borderBottomColor:
                        "#047FE0 !important",
                },
            }}>
            <div style={{ position: 'absolute', right: '0px', top: '20px', marginTop: '0px' }}>
                <ScheduleIcon
                    sx={{
                        fontSize: 20,
                        color: 'rgba(0, 0, 0, 0.54)',
                        marginBottom: '-5px',
                        marginLeft: '2px',
                    }}
                />
            </div>
            <MobileTimePicker
                value={dayjs(value)}
                onChange={onChange}
                onOpen={onOpen}
                onClose={onClose}
                openTo="hours"
                maxTime={isMaxTime ? getMaxTime() : null}
                {...rest}
            />
            {!!error ? <Typography sx={{ color: "red", fontSize: "12px" }}>{error}</Typography> : null}
        </DemoItem>
    </LocalizationProvider>
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const LogoutModal = ({
    actionType = "logout",
    onApprove,
    onCancel,
    isShow = false,
    isButtonLoading = false,
    isShowCancelButton = false,
    errors,
    setErrors,
    logoutModelDetails,
    setLogoutModelDetails,
    title = "",
    loadingLogoutTime = false,
    totalWorking = null,
}) => {

    const hasError = (errors) => {
        let last_day_login = Object.values(errors?.last_day?.checkin || {}).some(error => error != null && error.trim() !== '');
        let last_day_logout = Object.values(errors?.last_day?.logout || {}).some(error => error != null && error.trim() !== '');
        let current_day_login = Object.values(errors?.current_day?.login || {}).some(error => error != null && error.trim() !== '');
        return last_day_login || last_day_logout || current_day_login;
    };

    const handleTimeValidation = (currentDateTime, minDateTime, type, logoutModelData) => {
        const minTime = moment(minDateTime, "DD/MM/YYYY hh:mm A");
        const formattedMinTime = minTime?.format("hh:mm A");
        const currentTime = moment(currentDateTime, "DD/MM/YYYY hh:mm A");
        const isValidTime = currentTime.isAfter(minTime);

        if (type === "checkin") {
            let lastDayCheckinTime = logoutModelData?.last_day?.checkin?.time;
            let lastDayLogoutTime = logoutModelData?.last_day?.logout?.time;
            let checkinDate = new Date(logoutModelData?.last_day?.checkin?.date);
            let logoutDate = new Date(logoutModelData?.last_day?.logout?.date);

            if (checkinDate < logoutDate) {
                if (lastDayLogoutTime) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        last_day: {
                            ...prevErrors.last_day,
                            logout: {
                                ...prevErrors.last_day?.logout,
                                time: null,
                            },
                            checkin: {
                                ...prevErrors.last_day?.checkin,
                                time: isValidTime ? null : `Please enter time after ${formattedMinTime}`
                            },
                        }
                    }));
                }
            } else if (logoutDate < checkinDate) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    last_day: {
                        ...prevErrors.last_day,
                        logout: {
                            ...prevErrors.last_day?.logout,
                            time: isValidTime ? null : `Please enter time after ${formattedMinTime}`,
                            date: `Please enter date after ${logoutDate?.toLocaleDateString()}`
                        },
                    }
                }));
            } else {
                let checkinTime = new Date(lastDayCheckinTime);
                let logoutTime = new Date(lastDayLogoutTime);

                const minTime = moment(lastDayCheckinTime, "DD/MM/YYYY hh:mm A");
                const formattedMinTimeForToday = minTime?.format("hh:mm A");

                setErrors((prevErrors) => ({
                    ...prevErrors,
                    last_day: {
                        ...prevErrors.last_day,
                        checkin: {
                            ...prevErrors.last_day?.checkin,
                            time: isValidTime ? null : `Please enter time after ${formattedMinTime}`
                        },
                        logout: {
                            ...prevErrors.last_day?.logout,
                            time: `${((logoutTime <= checkinTime) && (!!checkinTime && !!logoutTime)) ? `Please enter time after ${formattedMinTimeForToday}` : ""} `,
                            date: null
                        }
                    }
                }));
            }
        } else {
            let todayLoginTime = logoutModelData?.current_day?.login?.time;
            let lastLogoutTime = logoutModelData?.last_day?.logout?.time;
            if (!!logoutModelData?.last_day?.checkin?.date) {
                let checkinDate = new Date(logoutModelData?.last_day?.checkin?.date);
                let logoutDate = new Date(logoutModelData?.last_day?.logout?.date);
                if (logoutDate < checkinDate) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        last_day: {
                            ...prevErrors.last_day,
                            logout: {
                                ...prevErrors.last_day?.logout,
                                date: `Please enter date after ${logoutDate?.toLocaleDateString()}`,
                                time: ""
                            }
                        }
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        last_day: {
                            ...prevErrors.last_day,
                            logout: {
                                ...prevErrors.last_day?.logout,
                                date: null,
                                time: null
                            }
                        }
                    }));
                }
            }
            if (logoutModelData?.current_day?.login?.date < logoutModelData?.last_day?.logout?.date) {
                if (lastLogoutTime) {
                    const minTime = moment(lastLogoutTime, "DD/MM/YYYY hh:mm A");
                    const formattedMinTimeForToday = minTime?.format("hh:mm A");
                    let todayTime = new Date(todayLoginTime);
                    todayTime.setDate(todayTime.getDate() + 1);
                    let previousLogoutTime = new Date(lastLogoutTime);

                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        current_day: {
                            ...prevErrors.current_day,
                            login: {
                                ...prevErrors.current_day?.login,
                                time: `${((todayTime <= previousLogoutTime) && !!todayLoginTime) ? `Please enter time after ${formattedMinTimeForToday}` : ""} `
                            }
                        },
                        last_day: {
                            ...prevErrors.last_day,
                            logout: {
                                ...prevErrors.last_day?.logout,
                                time: isValidTime ? null : `Please enter time after ${formattedMinTime} `
                            }
                        }
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        current_day: {
                            ...prevErrors.current_day,
                            login: {
                                ...prevErrors.current_day?.login,
                                time: ""
                            }
                        },
                        last_day: {
                            ...prevErrors.last_day,
                            logout: {
                                ...prevErrors.last_day?.logout,
                                time: isValidTime ? null : `Please enter time after ${formattedMinTime} `
                            }
                        }
                    }));
                }
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    current_day: {
                        ...prevErrors.last_day,
                        login: {
                            ...prevErrors.current_day?.login,
                            time: ""
                        }
                    },
                    last_day: {
                        ...prevErrors.last_day,
                        logout: {
                            ...prevErrors.last_day?.logout,
                            time: isValidTime ? null : `Please enter time after ${formattedMinTime} `
                        }
                    }
                }));
            }
        }
    };

    const isValidDateFormat = (date) => {
        return new Date(date).toString() !== "Invalid Date";
    };

    const handleLastLogoutChange = (value, fieldType) => {
        let updatedData = { ...logoutModelDetails };

        if (fieldType === 'date') {
            let dateValidation = isValidDateFormat(value);
            if (dateValidation) {
                let newDateValue = new Date(value);
                const isoStringDate = newDateValue?.toISOString();

                let lastTime = logoutModelDetails?.last_day?.logout?.time;
                if (lastTime) {
                    let newTimeValue = new Date(lastTime);
                    newTimeValue.setDate(newDateValue.getDate());
                    newTimeValue.setMonth(newDateValue.getMonth());
                    newTimeValue.setFullYear(newDateValue.getFullYear());
                    updatedData.last_day.logout.time = newTimeValue;
                    handleTimeValidation(moment(newTimeValue).format("DD/MM/YYYY hh:mm A"), moment(logoutModelDetails?.date).format("DD/MM/YYYY hh:mm A"), "logout");
                }
                else {
                    updatedData.last_day.logout.time = null;
                }
                updatedData.last_day.logout.date = isoStringDate;
            }

            setErrors((prevErrors) => ({
                ...prevErrors,
                last_day: {
                    ...prevErrors.last_day,
                    logout: {
                        ...prevErrors.last_day?.logout,
                        date: isValidDateFormat(value) ? "" : 'Date cannot be blank',
                    }
                }
            }));
        } else if (fieldType === 'time') {
            let lastDate = logoutModelDetails?.last_day?.logout?.date;
            let [DD, MM, YYYY] = moment(lastDate).format("DD/MM/YYYY")?.split("/");
            let newTimeValue = new Date(value);
            newTimeValue = new Date(YYYY, MM - 1, DD, newTimeValue.getHours(), newTimeValue.getMinutes(), newTimeValue.getSeconds());
            updatedData.last_day.logout.time = isValidDateFormat(value) ? newTimeValue : null;
            setErrors((prevErrors) => ({
                ...prevErrors,
                last_day: {
                    ...prevErrors.last_day,
                    logout: {
                        ...prevErrors.last_day?.logout,
                        time: isValidDateFormat(value) ? "" : 'Time cannot be blank'
                    }
                }
            }));
        }

        setLogoutModelDetails(updatedData);
    };

    const handleLastCheckInChange = (value, fieldType) => {
        let updatedData = { ...logoutModelDetails };

        if (fieldType === 'date') {
            let dateValidation = isValidDateFormat(value);

            updatedData.last_day.checkin.date = isValidDateFormat(value) ? new Date(value) : null;
            if (dateValidation) {
                let newDateValue = new Date(value);

                let lastTime = logoutModelDetails?.last_day?.checkin?.time;
                if (lastTime) {
                    let newTimeValue = new Date(lastTime);
                    newTimeValue.setDate(newDateValue.getDate());
                    newTimeValue.setMonth(newDateValue.getMonth());
                    newTimeValue.setFullYear(newDateValue.getFullYear());
                    updatedData.last_day.checkin.time = newTimeValue;
                    handleTimeValidation(moment(newTimeValue).format("DD/MM/YYYY hh:mm A"), moment(logoutModelDetails?.date).format("DD/MM/YYYY hh:mm A"), "checkin", updatedData);
                }
                else {
                    updatedData.last_day.checkin.time = null;
                }
            }
            let checkinDate = new Date(updatedData?.last_day?.checkin?.date);
            let logoutDate = new Date(updatedData?.last_day?.logout?.date);
            setErrors((prevErrors) => ({
                ...prevErrors,
                last_day: {
                    ...prevErrors.last_day,
                    checkin: {
                        ...prevErrors.last_day?.checkin,
                        date: isValidDateFormat(value) ? "" : 'Date cannot be blank',
                    },
                    logout: {
                        ...prevErrors.last_day?.logout,
                        date: `${logoutDate < checkinDate ? `Please enter date after ${logoutDate?.toLocaleDateString()}` : ""}`
                    },
                }
            }));
        } else if (fieldType === 'time') {
            updatedData.last_day.checkin.time = isValidDateFormat(value) ? new Date(value) : null;;
            setErrors((prevErrors) => ({
                ...prevErrors,
                last_day: {
                    ...prevErrors.last_day,
                    checkin: {
                        ...prevErrors.last_day?.checkin,
                        time: isValidDateFormat(value) ? "" : 'Time cannot be blank'
                    }
                }
            }));
        }

        setLogoutModelDetails(updatedData);
    };

    const handleCurrentLoginChange = (value, fieldType) => {
        let updatedData = { ...logoutModelDetails };
        if (fieldType === 'time') {
            updatedData.current_day.login.time = isValidDateFormat(value) ? new Date(value) : null;
            setErrors((prevErrors) => ({
                ...prevErrors,
                current_day: {
                    ...prevErrors.current_day,
                    login: {
                        ...prevErrors.current_day?.login,
                        time: isValidDateFormat(value) ? "" : 'Time cannot be blank'
                    }
                }
            }));
        }
        setLogoutModelDetails(updatedData);
    };

    const handleValidateLoginChange = (logoutModelData) => {
        let todayLoginTime = logoutModelData?.current_day?.login?.time;
        let lastLogoutTime = logoutModelData?.last_day?.logout?.time;
        if (logoutModelData?.current_day?.login?.date < logoutModelData?.last_day?.logout?.date) {
            if (lastLogoutTime) {
                const minTime = moment(lastLogoutTime, "DD/MM/YYYY hh:mm A");
                const formattedMinTime = minTime?.format("hh:mm A");
                let todayTime = new Date(todayLoginTime);
                todayTime.setDate(todayTime.getDate() + 1);
                let previousLogoutTime = new Date(lastLogoutTime);

                setErrors((prevErrors) => ({
                    ...prevErrors,
                    current_day: {
                        ...prevErrors.current_day,
                        login: {
                            ...prevErrors.current_day?.login,
                            time: `${((todayTime <= previousLogoutTime) && !!todayLoginTime) ? `Please enter time after ${formattedMinTime}` : ""} `
                        }
                    }
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    current_day: {
                        ...prevErrors.current_day,
                        login: {
                            ...prevErrors.current_day?.login,
                            time: ""
                        }
                    }
                }));
            }
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                current_day: {
                    ...prevErrors.current_day,
                    login: {
                        ...prevErrors.current_day?.login,
                        time: ""
                    }
                }
            }));
        }
    }

    const getDefaultTime = (date, isNextDay = false) => {
        if (!date) return null;
        const dateObj = new Date(date);
        if (isNextDay) dateObj.setDate(dateObj.getDate() + 1);
        dateObj.setHours(12, 0, 0, 0);
        return dateObj;
    };

    return (
        <Modal
            open={isShow}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {actionType === "logout" ?
                    <Grid>
                        <Grid Item py={2} px={3} borderBottom={"1px solid #E2E4EC"}>
                            <Typography variant="h6" color="dark.800" fontSize={20} fontWeight={500} lineHeight="32px" letterSpacing="0.15px">{title}</Typography>
                        </Grid>

                        <Stack p={3} spacing={1} width={600} maxWidth={'100%'}>
                            <Typography>
                                Are you sure you want to <b>End your day</b> for today?
                            </Typography>
                            {loadingLogoutTime ?
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{ m: 2, borderRadius: "5px" }}
                                    width={"50%"}
                                    height={26}
                                /> :
                                totalWorking?.show ?
                                    <Stack display={"flex"} gap={"5px"} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"row"}>
                                        <Typography variant="p" color="error.main" fontSize={17} fontWeight={400} lineHeight="26px" letterSpacing="0.15px">You have spent <b>{durationToWord(totalWorking?.working_hrs)}</b></Typography>
                                    </Stack>
                                    : null}
                        </Stack>
                    </Grid> :
                    <Box>
                        <Grid>
                            <Grid Item py={2} px={3} borderBottom={"1px solid #E2E4EC"}>
                                <Typography variant="h6" color="dark.800" fontSize={20} fontWeight={500} lineHeight="32px" letterSpacing="0.15px">{title}</Typography>
                            </Grid>
                            <Stack p={3} spacing={3} width={600} maxWidth={'100%'}>
                                <Box >
                                    <Typography color={"dark.600"} fontSize={16} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.15px'}>Your logout activity for {moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")} is missing.</Typography>
                                    <Typography variant="p" color="dark.800" fontSize={16} fontWeight={400} lineHeight="26px" letterSpacing="0.15px"><span dangerouslySetInnerHTML={{ __html: logoutModelDetails?.msg }} /></Typography>
                                </Box>
                                {!!logoutModelDetails?.last_activity ? <Typography variant="p" color="dark.800" fontSize={16} fontWeight={400} lineHeight="26px" letterSpacing="0.15px">Back from <span dangerouslySetInnerHTML={{ __html: logoutModelDetails?.last_activity }} /> on</Typography> : null}
                                <Stack spacing={4}>
                                    {logoutModelDetails?.hasOwnProperty("last_day") ?
                                        <Box>
                                            <Stack spacing={4}>
                                                {(logoutModelDetails?.last_day?.checkin?.hasOwnProperty("date") || logoutModelDetails?.last_day?.checkin?.hasOwnProperty("time")) ?
                                                    <Box>
                                                        <Box display={"flex"} gap={3} sx={{ width: '424px', maxWidth: '100%' }}>
                                                            {logoutModelDetails?.last_day?.checkin?.hasOwnProperty("date") ?
                                                                <Grid Item width={'100%'} position={'relative'}>
                                                                    <CustomDatePicker
                                                                        value={logoutModelDetails?.last_day?.checkin?.date || null}
                                                                        onChange={(date) => handleLastCheckInChange(date, 'date')}
                                                                        label={"Check-in Date"}
                                                                        minDate={new Date(moment(logoutModelDetails?.date))}
                                                                        maxDate={new Date(moment(logoutModelDetails?.date).add(1, 'day'))}
                                                                        error={errors?.last_day?.checkin?.date}
                                                                    />
                                                                </Grid>
                                                                : null}
                                                            {logoutModelDetails?.last_day?.checkin?.hasOwnProperty("time") ?
                                                                <Grid Item width={'100%'} position={'relative'}>
                                                                    <CustomTimePicker
                                                                        value={logoutModelDetails?.last_day?.checkin?.time || null}
                                                                        onOpen={() => setLogoutModelDetails(prev => ({ ...prev, last_day: { ...prev?.last_day, checkin: { ...prev?.last_day?.checkin, time: prev?.last_day?.checkin?.time || getDefaultTime(logoutModelDetails?.date) } } }))}
                                                                        onChange={(time) => handleLastCheckInChange(time, 'time')}
                                                                        label={"Check-in Time"}
                                                                        error={errors?.last_day?.checkin?.time}
                                                                        onClose={() => handleTimeValidation(moment(logoutModelDetails?.last_day?.checkin?.time).format("DD/MM/YYYY hh:mm A"), moment(logoutModelDetails?.date).format("DD/MM/YYYY hh:mm A"), "checkin", logoutModelDetails)}
                                                                    // minTime={dayjs().set('hour', moment(logoutModelDetails?.date).format("hh")).set('minute', moment(logoutModelDetails?.date).add(1, 'minute').format("mm")).set('second', moment(logoutModelDetails?.date).format("ss"))}
                                                                    />
                                                                </Grid>
                                                                : null}
                                                        </Box>
                                                    </Box>
                                                    : null}
                                                {(logoutModelDetails?.last_day?.logout?.hasOwnProperty("date") || logoutModelDetails?.last_day?.logout?.hasOwnProperty("time")) ?
                                                    <Box>
                                                        <Box display={"flex"} gap={3} sx={{ width: '424px', maxWidth: '100%' }}>
                                                            {logoutModelDetails?.last_day?.logout?.hasOwnProperty("date") ?
                                                                <Grid Item width={'100%'} position={'relative'}>
                                                                    <CustomDatePicker
                                                                        value={logoutModelDetails?.last_day?.logout?.date || null}
                                                                        onChange={(date) => handleLastLogoutChange(date, 'date')}
                                                                        label={"Logout Date"}
                                                                        minDate={new Date(moment(logoutModelDetails?.date))}
                                                                        maxDate={new Date(moment(logoutModelDetails?.date).add(1, 'day'))}
                                                                        error={errors?.last_day?.logout?.date}
                                                                        onClose={() => handleTimeValidation(moment(logoutModelDetails?.last_day?.logout?.time).format("DD/MM/YYYY hh:mm A"), moment(logoutModelDetails?.last_day?.checkin?.time || logoutModelDetails?.date).format("DD/MM/YYYY hh:mm A"), "logout", logoutModelDetails)}
                                                                    />
                                                                </Grid>
                                                                : null}
                                                            {logoutModelDetails?.last_day?.logout?.hasOwnProperty("time") ?
                                                                <Grid Item width={'100%'} position={'relative'}>
                                                                    <CustomTimePicker
                                                                        value={logoutModelDetails?.last_day?.logout?.time || null}
                                                                        onOpen={() => setLogoutModelDetails(prev => ({ ...prev, last_day: { ...prev?.last_day, logout: { ...prev?.last_day?.logout, time: prev?.last_day?.logout?.time || getDefaultTime(logoutModelDetails?.date) } } }))}
                                                                        onChange={(time) => handleLastLogoutChange(time, 'time')}
                                                                        label={"Logout Time"}
                                                                        error={errors?.last_day?.logout?.time}
                                                                        onClose={() => handleTimeValidation(moment(logoutModelDetails?.last_day?.logout?.time).format("DD/MM/YYYY hh:mm A"), moment(logoutModelDetails?.last_day?.checkin?.time || logoutModelDetails?.date).format("DD/MM/YYYY hh:mm A"), "logout", logoutModelDetails)}
                                                                    // minTime={dayjs().set('hour', moment(logoutModelDetails?.date).format("hh")).set('minute', moment(logoutModelDetails?.date).add(1, 'minute').format("mm")).set('second', moment(logoutModelDetails?.date).format("ss"))}
                                                                    /></Grid> : null}
                                                        </Box>
                                                    </Box> : null}
                                            </Stack>
                                        </Box>
                                        : null}
                                    {logoutModelDetails?.hasOwnProperty("current_day") ?
                                        <Box>
                                            <Stack spacing={4}>
                                                {(logoutModelDetails?.current_day?.login?.hasOwnProperty("date") || logoutModelDetails?.current_day?.login?.hasOwnProperty("time")) ?
                                                    <Box>
                                                        <Box display={"flex"} gap={3} sx={{ width: '424px', maxWidth: '100%' }}>
                                                            {logoutModelDetails?.current_day?.login?.hasOwnProperty("time") ?
                                                                <Grid Item width={'100%'} position={'relative'}>
                                                                    <CustomTimePicker
                                                                        value={logoutModelDetails?.current_day?.login?.time || null}
                                                                        onOpen={() => setLogoutModelDetails(prev => ({ ...prev, current_day: { ...prev?.current_day, login: { ...prev?.current_day?.login, time: prev?.current_day?.login?.time || getDefaultTime(logoutModelDetails?.date) } } }))}
                                                                        onChange={(time) => handleCurrentLoginChange(time, 'time')}
                                                                        label={`Login Time for ${moment(new Date()).format("DD/MM/YYYY")} (Today)`}
                                                                        error={errors?.current_day?.login?.time}
                                                                        onClose={() => handleValidateLoginChange(logoutModelDetails)}
                                                                    // isMaxTime={true}
                                                                    />
                                                                </Grid>
                                                                : null}
                                                        </Box>
                                                    </Box>
                                                    : null}
                                            </Stack>
                                        </Box>
                                        : null}
                                </Stack>
                            </Stack>
                        </Grid>
                    </Box>}
                <Grid Item py={2} px={3} borderTop="1px solid #E2E4EC">
                    <Stack spacing={1} direction="row">
                        <Button
                            variant="contained"
                            size="medium"
                            fontWeight="500"
                            color={actionType === "logout" ? "error" : "primary"}
                            sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                            onClick={onApprove}
                            isButtonLoading={isButtonLoading}
                            disabled={isButtonLoading || hasError(errors)}
                        >
                            {actionType === "logout" ? "Logout" : "Submit"}
                        </Button>
                        {isShowCancelButton ?
                            <Button
                                variant="text"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                fontWeight="500"
                                onClick={onCancel}
                                disabled={isButtonLoading}
                            >
                                {actionType === "logout" ? "Cancel" : "Close"}
                            </Button> : null}
                    </Stack>
                </Grid>
            </Box>
        </Modal>
    )
}

export default LogoutModal;
