import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import Button from "../Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
};

const ConfirmationModal = ({ isOpen, title, deletableDataName, dataContentName, handleClose, onConfirm, onCancel, isButtonLoading = false, subList = {}, type = "" }) => {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid Item py={2} px={3} borderBottom="1px solid #E2E4EC">
            <Typography variant="h6">Delete {title}</Typography>
          </Grid>
          <Grid Item p={3}>
            <Stack spacing={2}>
              {type === 'holiday' ? <Typography
                color="dark.800"
                fontSize={16}
                fontWeight={400}
                sx={{ lineHeight: "26px" }}
              >
                Are you sure, You want to{" "}
                <span
                  style={{
                    color: "#D32F2F",
                    fontWeight: "700",
                  }}
                >
                  delete
                </span>{" the holiday of "}
                <b>{deletableDataName}</b> ?
              </Typography> :
                <Typography
                  color="dark.800"
                  fontSize={16}
                  fontWeight={400}
                  sx={{ lineHeight: "26px" }}
                >
                  Are you sure, You want to{" "}
                  <span
                    style={{
                      color: "#D32F2F",
                      fontWeight: "700",
                    }}
                  >
                    delete
                  </span>{" "}
                  <b>{deletableDataName}</b>{" "}{dataContentName} ?
                </Typography>}
              {Object.keys(subList)?.length ? <Typography
                color="dark.800"
                fontSize={16}
                fontWeight={400}
                sx={{ lineHeight: "26px" }}
              >
                {subList}
              </Typography> : null}
            </Stack>
          </Grid>
          <Grid Item py={2} px={3} borderTop="1px solid #E2E4EC">
            <Stack spacing={1} direction="row">
              <Button
                variant="contained"
                color="error"
                size="medium"
                fontWeight="500"
                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                onClick={onConfirm}
                isButtonLoading={isButtonLoading}
                disabled={isButtonLoading}
              >
                Delete
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                size="medium"
                fontWeight="500"
                onClick={onCancel}
                disabled={isButtonLoading}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default ConfirmationModal