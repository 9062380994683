import { Box } from '@mui/material';
import React, { useState } from 'react';
// import TimeTracking from './TimeTracking';
import { usePageTitle } from '../../../hook/useTitle';
import QuickActions from './QuickActions';
import UserSummary from './UserSummary';

const Dashboard = () => {
    const { setPageTitle } = usePageTitle();
    const [actionsCount, setActionsCount] = useState([]);

    setPageTitle("Dashboard");
    return (
        <Box
            className="organisation-dashboard-wrapper"
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                overflow: "auto"
            }}
            p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}
        >
            {/* <TimeTracking /> */}
            <QuickActions actionsCount={actionsCount} />
            <UserSummary setActionsCount={setActionsCount} />
        </Box>
    )
}

export default Dashboard;