/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ApplyLeaveDetails from "./ApplyLeaveDetails";
import ApplyLeave from "./ApplyLeave";
import API from "../../../../../axios/api";
import axiosInstance from "../../../../../axios";
import CircularLoader from "../../../../../components/CircularLoader";
import { Box, Typography } from "@mui/material";
import { usePageTitle } from "../../../../../hook/useTitle";
import { useDispatch, useSelector } from "react-redux";
import { getAllHolidayData } from "../../../../../redux/slices/leaveDataSlice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ApplyLeavePage = () => {
  let initialLeaveData = {
    user_leave_type_setting_id: null,
    informed_authorities: [],
    leave_days: [],
    attachments: [],
    description: "",
    apply_date: new Date(),
    club_leave_count: 0,
  };

  const { user: currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const [isAppliedLeave, setIsAppliedLeave] = useState(false);
  const [leaveData, setLeaveData] = useState(initialLeaveData);
  const [errors, setErrors] = useState({});
  const [leaveSnapshot, setLeaveSnapshot] = useState(null);
  const [userLeaveTypes, setUserLeaveTypes] = useState([]);
  const [organizationUserList, setOrganizationUserList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    leaveType: true,
    users: true,
    holiday: true,
    wfhDates: false
  });

  setPageTitle("Apply a Leave");

  useEffect(() => {
    if (currentUser?.id) {
      getUserLeaveTypeData();
      getUserSettingData();
      getUserLeaveBalance();
      getYears();
    }
  }, []);

  const getUserLeaveBalance = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, spanLoading: true }))
      const response = await axiosInstance.get(API.getLeaveBalance(currentUser?.id));
      if (response.status === 200) {
        setLeaveSnapshot(response?.data?.result);
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, spanLoading: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, spanLoading: false }))
    }
  };

  const getUserLeaveTypeData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveType: true }))
      const response = await axiosInstance.get(
        API.getUserLeaveTypeData(currentUser?.id)
      );
      if (response.status === 200) {
        setUserLeaveTypes(response?.data.results);
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveType: false }))
    }
  };

  const getUserSettingData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, users: true }))
      const response = await axiosInstance.get(API.organisationUserWithInformationAuthority);
      if (response.status === 200) {
        let reporting_authorities = response?.data?.data?.reporting_authorities;
        let second_reporting_authorities = response?.data?.data?.second_reporting_authorities;
        let defaultUsers = response?.data?.data?.defaultUsers;
        let users = [];
        if (reporting_authorities && reporting_authorities?.length > 0) {
          users.push(...reporting_authorities);
        }
        if (second_reporting_authorities && second_reporting_authorities?.length > 0) {
          users.push(...second_reporting_authorities);
        }
        if (users?.length <= 0) {
          users.push(...defaultUsers);
        }
        setOrganizationUserList(users || []);
        setIsLoading((prev) => ({ ...prev, users: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, users: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, users: false }))
    }
  };

  const getYears = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, holiday: true }))
      const response = await axiosInstance.get(
        `${API.getAllHolidaysYear(currentUser?.organization_id)}`
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.localeCompare(b?.year)
        );
        dispatch(getAllHolidayData({
          organization_id: currentUser?.organization_id,
          year_Id: sortedYear[0]?.id,
          user_id: currentUser?.id
        }))
      }
      setIsLoading((prev) => ({ ...prev, holiday: false }))
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, holiday: false }))
    }
  }

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <Box bgcolor="secondary"
        flex={1}
        overflow="auto"
        sx={{
          ".tabspanel > .MuiBox-root": {
            padding: "0px",
          }
        }}
      >
        <CustomTabPanel value={1} index={0} className="tabspanel">
        </CustomTabPanel>
        <CustomTabPanel value={1} index={1} className="tabspanel">
          <Box>
            {isLoading?.leaveType || isLoading?.users ?
              <CircularLoader height="500px" /> :
              <Box>
                {isAppliedLeave ? (
                  <ApplyLeaveDetails
                    leaveSnapshot={leaveSnapshot}
                    setIsAppliedLeave={setIsAppliedLeave}
                    setLeaveData={setLeaveData}
                    leaveData={leaveData}
                    setErrors={setErrors}
                    errors={errors}
                    userLeaveTypes={userLeaveTypes}
                    organizationUserList={organizationUserList}
                    currentUser={currentUser}
                  />
                ) : (
                  <ApplyLeave
                    leaveSnapshot={leaveSnapshot}
                    setIsAppliedLeave={setIsAppliedLeave}
                    setLeaveData={setLeaveData}
                    leaveData={leaveData}
                    setErrors={setErrors}
                    errors={errors}
                    userLeaveTypes={userLeaveTypes}
                    organizationUserList={organizationUserList}
                    currentUser={currentUser}
                  />
                )}
              </Box>}
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default ApplyLeavePage;
