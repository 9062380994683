import { Skeleton, TableCell, TableRow } from '@mui/material'
import React from 'react'

const TableRowSkeleton = ({ rows, cols }) => {
    return (Array.from({ length: rows }, (v, i) => i).map((row, rowIndex) => (
        <TableRow key={rowIndex}>
            {Array.from({ length: cols }, (v, i) => i).map((row, colIndex) => (
                <TableCell sx={{ padding: "12px 16px;" }} key={colIndex}>
                    <Skeleton animation="wave" />
                </TableCell>
            ))}
        </TableRow>
    )))
}

export default TableRowSkeleton