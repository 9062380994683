import { Badge } from '@mui/material'
import React from 'react'
import ICONS from '../../constants/icons'

const CustomBadge = ({ count = 0, showZero = false, color = "white", backgroundColor = "primary.main", ...rest }) => {
    return (
        <Badge
            showZero={showZero}
            badgeContent={count}
            sx={{
                "& .MuiBadge-badge": {
                    color,
                    backgroundColor
                }
            }}
            {...rest}
        >
            <i>{ICONS.BallIcon}</i>
        </Badge>
    )
}

export default CustomBadge