import React from 'react'
import { usePageTitle } from '../../../../hook/useTitle';

const Inventory = () => {
  const { setPageTitle } = usePageTitle();

  setPageTitle("Inventory");
  return (
    <>Inventory</>
  )
}

export default Inventory