import { GENERAL_USER_PAGES, ON_BOARDING_STATUS, SUPER_ADMIN_PAGE_LIST, USER_TYPES } from "../constants/default-values";
import URLS from "../routes/urls";
import secureLocalStorage from "react-secure-storage";

const useAuthentication = () => {
  const getCurrentUser = () => {
    try {
      const currentUser = secureLocalStorage.getItem("currentUser")?.length >= 0 ? JSON.parse(secureLocalStorage.getItem("currentUser")) : null;
      if (currentUser && Object.keys(currentUser).length) {
        return currentUser;
      }
    } catch (error) {
      console.error("Error retrieving current user:", error);
    }
    return null;
  };

  const isAuthenticated = () => !!getCurrentUser();

  const userRoles = {
    DEVELOPER: 'developer',
    SALES: 'sales',
    HR: 'hr',
    ADMINISTRATOR: 'administrator',
    SUPER_ADMIN: "super_admin",
  };

  function isHaveAccess(location) {
    const url = location?.pathname?.toLowerCase();
    const currentUser = getCurrentUser();
    const userRole = currentUser?.user_role?.toLowerCase();
    const userType = currentUser?.user_type?.toLowerCase();
    if (userType === userRoles.SUPER_ADMIN) {
      if (SUPER_ADMIN_PAGE_LIST?.includes(url) || url.split(("/").includes("admin"))) {
        return true;
      }
      else {
        return false;
      }
    } else if (userRole !== userRoles.ADMINISTRATOR && url?.split("/").includes("activity")) {
      return false;
    } else {
      if ((userRole === userRoles.HR || userRole === userRoles.ADMINISTRATOR) && !SUPER_ADMIN_PAGE_LIST?.includes(url)) {
        return true;
      }

      let isAccess = GENERAL_USER_PAGES.includes(url) || url?.split("/").includes("request");
      return isAccess;
    }
  }

  const defaultRoute = () => {
    const currentUser = getCurrentUser();
    let viewPages = [
      URLS.ViewMyLeaveApplication,
      URLS.ViewMyWfhApplication,
      URLS.OtherLeaveDetails,
      URLS.OtherWfhApplicationDetails
    ];
    let isViewPageInclude = viewPages.some(data => window.location.pathname.toLowerCase()?.includes(data))
    let isIncompleteOnboarding = currentUser ? currentUser?.organization_status !== ON_BOARDING_STATUS.completed : false;
    if (window.location.pathname !== URLS.Login && !isIncompleteOnboarding && window.location.pathname?.length > 2 && isViewPageInclude) {
      secureLocalStorage.setItem("redirectPath", window.location.pathname)
    }
    let path;

    if (!currentUser) {
      return URLS.Login;
    }

    const handleGetPathForAdmin = (userData) => {
      let isIncompleteOnboarding = userData?.organization_status !== ON_BOARDING_STATUS.completed;
      if (isIncompleteOnboarding && userData?.is_primary) {
        return `${URLS.onBoarding}/${ON_BOARDING_STATUS[userData?.organization_status]}`
      } else if (userData?.organization_status === "completed") {
        let redirectPath = secureLocalStorage.getItem("redirectPath");
        return redirectPath ? redirectPath : URLS.Dashboard;
      } else if (isIncompleteOnboarding && !userData?.is_primary) {
        return URLS.InCompleteOnBoarding;
      }
    };

    switch (currentUser.user_type) {
      case USER_TYPES.super_admin:
        path = URLS.AdminDashboard;
        break;
      case USER_TYPES.admin:
        path = handleGetPathForAdmin(currentUser);
        break;
      case USER_TYPES.manager:
        path = handleGetPathForAdmin(currentUser);
        break;
      case USER_TYPES.general:
        path = handleGetPathForAdmin(currentUser);
        break;
      default:
        path = window.location.pathname;
        break;
    }
    return path;
  };

  return { isAuthenticated, getCurrentUser, defaultRoute, isHaveAccess };
};

export default useAuthentication;
