import { Button } from '@mui/material';
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf';

const PDFViewer = (props) => {
    const { url: URL } = props;

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div className="App" >
            <div>
                <Document
                    file={URL}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onSourceError={(err) => console.error(err)}
                    onSourceSuccess={() => console.error("SUCCESS")}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={() => console.error("ERR")}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </div>
            <div>
                <p>
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                </p>
                {numPages > 1 ?
                    <>
                        <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                            Previous
                        </Button>
                        <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            Next
                        </Button>
                    </>
                    : null}
            </div>
        </div>
    );
}

export default PDFViewer;
