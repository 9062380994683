import React, { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Card, Container, Grid, Link, Stack, Tooltip, tooltipClasses } from "@mui/material";
import Button from "../../../components/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouteLink, useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Input";
import { useAlert } from "../../../hook/useAlert";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import { checkOrganisationUniqueName, handleCheckIsEmailUnique } from "../../../axios/service";
import { titleCase } from "../../../utils";
import { validateEmail } from "../../../utils/validation";
import { usePageTitle } from "../../../hook/useTitle";
import { styled } from "@mui/styles";

const AddOrganisation = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  const { setPageTitle } = usePageTitle();
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
  });

  setPageTitle("Add Organisations")

  const customEmailValidator = (emailValue) => {
    return emailValue?.trim() && validateEmail(emailValue?.trim());
  };

  const validationSchema = Yup.object({
    organisationName: Yup.string().required("Required"),
    emailAddresses: Yup.array().of(
      Yup.string()
        .test('is-valid-email', 'Invalid email address', customEmailValidator)
        .required("Required")
    ),
    names: Yup.array().of(Yup.string().required("Required")),
  });

  const formik = useFormik({
    initialValues: {
      organisationName: "",
      emailAddresses: [""],
      names: [""],
      is_admin: true,
    },
    validationSchema,
    onSubmit: async ({ organisationName, emailAddresses, names }) => {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
      const checkEmailExist = emailAddresses.filter(
        (email) => email.trim()?.length
      );
      if (
        !(await handleValidate({ organisationName, checkEmailExist, names }))
      ) {
        let formatedEmailObject = checkEmailExist.map((email, index) => {
          let admin_status = index === 0 ? "primary_admin" : "admin";
          let isAdmin_status = admin_status === "primary_admin" ? true : false;
          return {
            admin_status,
            email,
            name: names[index],
            is_admin: isAdmin_status
          };
        });
        let body = {
          name: organisationName,
          active: false,
          org_admins: formatedEmailObject,
        };
        try {
          const response = await axiosInstance.post(API.createOrganization, body);
          if (response.status === 200) {
            showAlert(response.data.message);
            setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
            navigate(URLS.AdminDashboard);
          } else {
            showAlert(response?.response?.data?.message, "error");
            setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
          }
        } catch (error) {
          console.error(error)
          setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
      } else {
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      }
    },
  });

  const handleValidate = async ({
    organisationName,
    checkEmailExist,
    names,
  }) => {
    let isError;
    if (!names?.length) {
      formik.setErrors({
        ...formik.errors,
        "names.0": "At least one full name is required",
      });
      isError = true;
    }

    const isExist = await handleCheckUniqueOrganisationName(organisationName);
    if (!checkEmailExist?.length) {
      formik.setErrors({
        ...formik.errors,
        "emailAddresses.0": "At least one email address is required",
      });
      isError = true;
    }
    if (isExist) {
      formik.setErrors({
        ...formik.errors,
        organisationName:
          "Organization name already exists. Please choose a unique name.",
      });
      isError = true;
    }
    return isError;
  };

  const handleEmailChange = (index, value) => {
    const updatedEmailAddresses = [...formik.values.emailAddresses];
    updatedEmailAddresses[index] = value;

    // Add an empty string to the end if the last element is not empty
    // if (value !== "" && index === updatedEmailAddresses.length - 1) {
    //   updatedEmailAddresses.push("");
    // }

    formik.setValues({
      ...formik.values,
      emailAddresses: updatedEmailAddresses,
    });
  };

  const handleNameChange = (index, value) => {
    const updatedNamesAddresses = [...formik.values.names];
    updatedNamesAddresses[index] = value;

    formik.setValues({
      ...formik.values,
      names: updatedNamesAddresses,
    });
  };

  const handleCheckUniqueOrganisationName = async (name) => {
    try {
      const { isExist } = await checkOrganisationUniqueName(name);
      if (isExist) {
        formik.setErrors({
          ...formik.errors,
          organisationName:
            "Organization name already exists. Please choose a unique name.",
        });
      }
      return isExist;
    } catch (error) {
      console.error(error);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#3F5C76',
      color: '#ffffff',
      maxWidth: 310,
      padding: 12,
    },
  }));

  return (
    <Box sx={{ marginTop: "-190px" }} position="relative" zIndex={1}>
      <Container>
        <Link
          component={RouteLink}
          to={URLS.AdminDashboard}
          display="inline-flex"
          alignItems="center"
          sx={{ textDecoration: "none", color: "white" }}
          fontFamily={"fontFamily"}
          p={0.5}
          mb={1.5}
        >
          <ChevronLeftIcon fontSize="small" sx={{ mr: 1 }} />
          Back to Organisations
        </Link>
        <Typography variant="h6" color="white" marginBottom="12px">
          Add Organisations
        </Typography>
        <Card
          sx={{ bgcolor: "bgColor", p: { xs: 2, sm: 3, md: 4 }, boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)' }}
          boxshadow={1}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Stack spacing={6}>
                  <Stack spacing={2} direction="row" pr={2}>
                    <Grid item xs={6}>
                      <Input
                        id="organisation-name"
                        name="organisationName" // Match the name with the initialValues key
                        label="Organisation name"
                        variant="standard"
                        placeholder="organisation name"
                        type="text"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          handleCheckUniqueOrganisationName(e.target.value);
                        }}
                        value={titleCase(formik.values.organisationName)}
                        error={
                          formik.touched.organisationName &&
                          Boolean(formik.errors.organisationName)
                        }
                        helperText={
                          formik.touched.organisationName &&
                          formik.errors.organisationName
                        }
                        // isUpperCase={true}
                        isCapitalizeFirstLatter={true}
                      />
                    </Grid>
                  </Stack>
                  {/* <Typography variant="body1">Invite admin(s)</Typography> */}

                  {formik.values.emailAddresses.map((email, index) => (
                    <Grid
                      key={index}
                      display="flex"
                      item
                      xs={12}
                      sx={{ width: "100%" }}
                    >
                      <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
                        <Grid item xs={6}>
                          <Input
                            id={`full-name-${index}`}
                            name={`names.${index}`}
                            label={
                              index === 0 ? "Full Name (PRIMARY)" : "Full Name"
                            }
                            variant="standard"
                            placeholder={
                              index === 0 ? "Full Name (PRIMARY)" : "Full Name"
                            }
                            type="text"
                            fullWidth
                            onChange={(e) =>
                              handleNameChange(index, e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={titleCase(formik.values.names[index])}
                            error={
                              formik.touched.names &&
                              formik.touched.names[index] &&
                              Boolean(formik.errors.names?.[index])
                            }
                            helperText={
                              formik.touched.names &&
                              formik.touched.names[index] &&
                              formik.errors.names?.[index]
                            }
                            isCapitalizeFirstLatter={true}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Input
                            id={`email-address-${index}`}
                            name={`emailAddresses.${index}`}
                            label={"Email address (PRIMARY)"}
                            variant="standard"
                            placeholder={"email address (PRIMARY)"}
                            type="email"
                            fullWidth
                            onChange={(e) =>
                              handleEmailChange(index, e.target.value)
                            }
                            onBlur={async (event) => {
                              formik.handleBlur(event);
                              const isEmailUnique = await handleCheckIsEmailUnique(event.target.value);
                              if (isEmailUnique) {
                                formik.setErrors({
                                  ...formik.errors,
                                  emailAddresses: ["Email is already exist"],
                                });
                              }
                            }}
                            value={formik.values.emailAddresses[index]}
                            error={
                              formik.touched.emailAddresses &&
                              formik.touched.emailAddresses[index] &&
                              Boolean(formik.errors.emailAddresses?.[index])
                            }
                            helperText={
                              formik.touched.emailAddresses &&
                              formik.touched.emailAddresses[index] &&
                              formik.errors.emailAddresses?.[index]
                            }
                          />
                          <Box>
                          <HtmlTooltip
                              arrow
                              title={
                                <React.Fragment>
                                  <Typography sx={{fontSize: '13px !important'}} textAlign={"center"}>Primary admin email</Typography>
                                </React.Fragment>
                              }
                            >
                              <Typography
                                display="inline-block"
                                color="primary"
                                fontSize={12}
                                sx={{ cursor: "pointer" }}
                              >
                                Help
                              </Typography>
                            </HtmlTooltip>
                          </Box>
                        </Grid>
                        {index !== formik.values.emailAddresses.length - 1 ? (
                          <Box sx={{ minWidth: "70px" }}>
                            <IconButton
                              size="small"
                              sx={{
                                width: "40px",
                                height: "40px",
                                ml: 4,
                                mt: 1,
                              }}
                              onClick={() => {
                                const updatedEmailAddresses = [
                                  ...formik.values.emailAddresses,
                                ];
                                updatedEmailAddresses.splice(index, 1);
                                formik.setValues({
                                  ...formik.values,
                                  emailAddresses: updatedEmailAddresses,
                                });
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        ) : null}
                      </Stack>
                    </Grid>
                  ))}

                  <Grid>
                    <Button
                      type="submit"
                      color="primary"
                      sx={{
                        boxshadow: 0,
                        color: "white",
                        fontSize: "15px",
                        textTransform: "none",
                        mr: 2.4,
                        "&:hover": { boxshadow: 0 },
                      }}
                      disabled={
                        !(
                          formik.values.organisationName &&
                          formik.values.emailAddresses[0]?.length &&
                          !Object.keys(formik.errors).length
                        )
                      }
                      isButtonLoading={isLoading?.formSubmitting}
                    >
                      Add Organisation
                    </Button>
                    <Button
                      variant="text"
                      color="secondary"
                      sx={{ textTransform: "none" }}
                      onClick={() => navigate(URLS.AdminDashboard)}
                      disabled={isLoading?.formSubmitting}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
    </Box>
  );
};

export default AddOrganisation;
