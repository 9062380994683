import React, { useState } from "react";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import URLS from "../../routes/urls";
import PublicFooter from "../../components/PublicFooter";
import Input from "../../components/Input";
import { useAlert } from "../../hook/useAlert";
import { useFormik } from "formik";
import * as Yup from "yup";
import API from "../../axios/api";
import axiosInstance from "../../axios";
import ICONS from "../../constants/icons";
import { usePageTitle } from "../../hook/useTitle";

const ForgotPassword = () => {
  const showAlert = useAlert();
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post(API.forgotPassword, values);
        if (response.status === 200) {
          showAlert(response.data.message);
          navigate({ pathname: URLS.Login });
        } else {
          showAlert(response?.response?.data?.message, "error");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
        showAlert(error?.response?.data?.message, "error");
        setIsLoading(false);
      }
    },
  });

  setPageTitle("Forget Password");

  return (
    <Box
      sx={{ minHeight: "100vh", position: "relative", background: "#FAFBFF" }}
    >
      <Box
        sx={{
          position: "absolute",
          filter: "blur(18px)",
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <i style={{ height: "calc(100vh - 175px)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBg}</i>
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        position="relative"
        zIndex="1"
        sx={{ minHeight: "100vh" }}
      >
        <Grid sx={{ flex: 1, display: "flex", paddingTop: "50px" }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          // sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <i style={{display: 'block',}}>{ICONS.Logo}</i>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <Box
                sx={{
                  maxWidth: { xs: 400, lg: 500 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "50%",
                  },
                  width: "100%",
                  margin: "20px auto 55px",
                  boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)',
                  borderRadius: "14px",
                  background: "white",
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: "100%" }}
                  boxShadow={1}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={4} alignItems="flex-start">
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color="secondary"
                      >
                        Forget Password
                      </Typography>
                      <Input
                        name="email"
                        id="user-email"
                        label="Email address"
                        variant="standard"
                        type="email"
                        placeholder={"email address"}
                        sx={{ width: "100%" }}
                        {...formik.getFieldProps("email")}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <Grid sx={{ width: "100%" }}>
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          fontSize="16px"
                          color="text.primary"
                          mb={"24px"}
                        >
                          Click on the Submit, We’ll send you the link for reset
                          password.
                        </Typography>
                        <Button
                          type="submit"
                          size="large"
                          sx={{ color: "#ffffff", width: "100%", boxShadow: 'none !important' }}
                          isButtonLoading={isLoading}
                        >
                          Submit
                        </Button>
                      </Grid>
                      <Grid
                        className="text-center"
                        sx={{ width: "100%", textAlign: "center" }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="400"
                          fontSize="16px"
                          color="text.primary"
                        >
                          Back to
                          <Link
                            variant="body2"
                            component={RouterLink}
                            to={URLS.Login}
                            color="primary"
                            fontWeight="bold"
                            sx={{
                              textDecoration: "none",
                              cursor: "pointer",
                              margin: "0px 5px",
                            }}
                          >
                            Login
                          </Link>
                        </Typography>
                      </Grid>
                    </Stack>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <PublicFooter />
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
