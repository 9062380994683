import React, { useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material';
import Button from '../../components/Button';
import PublicFooter from '../../components/PublicFooter';
import Input from '../../components/Input';
import { useAlert } from '../../hook/useAlert';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import URLS from '../../routes/urls';
import ICONS from '../../constants/icons';
import { usePageTitle } from '../../hook/useTitle';

const ResetPassword = () => {
    const showAlert = useAlert();
    const navigate = useNavigate();
    let { reset_password_token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { setPageTitle } = usePageTitle();

    setPageTitle("Reset Password");
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
            pwdResetToken: reset_password_token,
        },
        validationSchema: Yup.object({
            password: Yup.string().min(8, "password must be at least 8 characters").required('Required'),
            confirm_password: Yup.string().min(8, "password must be at least 8 characters").oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const response = await axiosInstance.post(API.resetPassword, values);
                if (response.status === 200) {
                    setIsLoading(false);
                    showAlert(response.data.message);
                    navigate({ pathname: URLS.Login }, {
                        replace: true
                    });
                } else {
                    setIsLoading(false);
                    showAlert(response.data.message, 'error');
                    navigate({ pathname: URLS.Login }, {
                        replace: true
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
            }
        },
    });
    return (
        <Box sx={{ minHeight: '100vh', position: 'relative', background: '#FAFBFF' }}>
            <Box sx={{ position: 'absolute', filter: 'blur(18px)', bottom: 0, top: 0, display: 'flex', alignItems: 'center' }}>
                <i style={{ height: "calc(100vh - 175px)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBg}</i>
            </Box>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                position="relative"
                zIndex="1"
                sx={{ minHeight: '100vh' }}>
                <Grid sx={{ flex: 1, display: 'flex', paddingTop: '50px' }}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    // sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
                    >
                        <Grid item>
                        </Grid>
                        <Grid item sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    maxWidth: { xs: 400, lg: 500 },
                                    '& > *': {
                                        flexGrow: 1,
                                        flexBasis: '50%'
                                    },
                                    width: '100%',
                                    margin: '55px auto',
                                    boxShadow: 1,
                                    borderRadius: '4px',
                                    border: '1px solid #F0F0F0',
                                    background: 'white',
                                }}
                            >
                                <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: '100%' }} boxShadow={1}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Stack spacing={4} alignItems="flex-start">
                                            <Typography variant="h5" fontWeight="bold" color="secondary">Reset Password</Typography>
                                            <Input
                                                id="user-email"
                                                name="passowrd"
                                                label="Password"
                                                variant="standard"
                                                type="password"
                                                sx={{ width: '100%' }}
                                                {...formik.getFieldProps('password')}
                                                error={formik.touched.password && Boolean(formik.errors.password)}
                                                helperText={formik.touched.password && formik.errors.password}
                                            />
                                            <Input
                                                id="user-email"
                                                name="confirm_password"
                                                label="Confirm Password"
                                                variant="standard"
                                                type="password"
                                                sx={{ width: '100%' }}
                                                {...formik.getFieldProps('confirm_password')}
                                                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                            />
                                            <Button
                                                type="submit"
                                                sx={{ color: '#ffffff', width: '100%' }}
                                                isButtonLoading={isLoading}
                                            >Reset</Button>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <PublicFooter />
            </Grid>
        </Box>
    )
}

export default ResetPassword;