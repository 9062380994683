// src/bugsnag.js
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

if (process.env.NODE_ENV === 'production') {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()]
    })
    BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY });
};

export default Bugsnag;
