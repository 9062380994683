import React from 'react'
import { usePageTitle } from '../../../hook/useTitle';

const Projects = () => {
    const { setPageTitle } = usePageTitle();

    setPageTitle("Projects");
    return (
        <>Projects</>
    )
}

export default Projects;