/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { HOLIDAYS } from "../../../constants/default-values";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import useAuthentication from "../../../hook/useAuthentication";
import CircularLoader from "../../../components/CircularLoader";
import { getDayAndMonth, getFormattedDate, handleSortOfficeHoliday, isDashedYearBasedOnMonth, titleCase } from "../../../utils";
import { usePageTitle } from "../../../hook/useTitle";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.isRequired,
  value: PropTypes.isRequired,
};

const Holidays = () => {
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const [selectedYear, setSelectedYear] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [tabValue, setTabValue] = useState(Object.keys(HOLIDAYS)[0]);
  const [years, setYears] = useState([]);
  const [holidayData, setHolidayData] = useState({});
  const [festivalHolidays, setFestivalHolidays] = useState([]);
  const [optionalFestivalHolidays, setOptionalFestivalHolidays] = useState([]);
  const [officeHolidays, setOfficeHolidays] = useState([]);
  const [optionalOfficeHolidays, setOptionalOfficeHolidays] = useState([]);
  const [loading, setLoading] = useState({
    dateLoading: true,
    tableDataLoading: false,
  });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);

  setPageTitle("Holidays");
  useEffect(() => {
    if (currentUser?.organization_id) getAllHolidayYears();
  }, []);

  useEffect(() => {
    setSortType("asc");
    setPage(0);
    setRowPerPage(10);
    getDataAccordingPagination();
  }, [tabValue]);

  useEffect(() => {
    if (currentUser?.organization_id && years?.length && !!selectedYear?.id)
      fetchHolidaysData();
  }, [selectedYear]);

  useEffect(() => {
    getDataAccordingPagination();
  }, [page, rowPerPage]);

  const getDataAccordingPagination = () => {
    let dataToPaginate;
    let setDataFunction;

    if (tabValue === Object.keys(HOLIDAYS)[0]) {
      dataToPaginate = optionalOfficeHolidays;
      setDataFunction = setOfficeHolidays;
    } else {
      dataToPaginate = optionalFestivalHolidays;
      setDataFunction = setFestivalHolidays;
    }

    if (dataToPaginate && dataToPaginate.length) {
      const startIdx = page * rowPerPage;
      const endIdx = Math.min(startIdx + rowPerPage, dataToPaginate.length);
      const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
      updatedPageData.sort(function (a, b) {
        if (sortType === "asc")
          return new Date(a.date) - new Date(b.date);
        else return new Date(b.date) - new Date(a.date);
      });
      setDataFunction(updatedPageData);
    } else {
      // Handle case when there is no data to paginate
      setDataFunction([]);
    }
  };


  const getAllHolidayYears = async () => {
    try {
      setLoading((prev) => ({ ...prev, dateLoading: true }));
      const response = await axiosInstance.get(
        API.getAllHolidaysYear(currentUser.organization_id)
      );
      if (response.status === 200) {
        let sortedYear = response?.data?.result?.sort((a, b) =>
          a?.year?.localeCompare(b?.year)
        );
        setYears(sortedYear);
        let activeYear = sortedYear?.find(yearData => yearData?.isActiveYear);
        setSelectedYear(activeYear);
      }
      setLoading((prev) => ({ ...prev, dateLoading: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading((prev) => ({ ...prev, dateLoading: false }));
    }
  };

  const handleSortFestivalHolidays = (response) => {
    let sortedArray = response?.slice()
    return sortedArray?.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    })
  }

  const fetchHolidaysData = async () => {
    try {
      setLoading((prev) => ({ ...prev, tableDataLoading: true }));
      const response = await axiosInstance.get(
        API.getAllUserHolidays(currentUser.organization_id, selectedYear?.id)
      );
      if (response.status === 200) {
        let responseData = response?.data?.data;
        let sortedOfficeHoliday = handleSortOfficeHoliday(responseData);
        let sortedFestivalHoliday = handleSortFestivalHolidays(responseData?.festival_holidays);
        setHolidayData(responseData);
        setOfficeHolidays(sortedOfficeHoliday?.slice(0, rowPerPage));
        setOptionalOfficeHolidays(sortedOfficeHoliday);
        setFestivalHolidays(sortedFestivalHoliday)
        setOptionalFestivalHolidays(sortedFestivalHoliday)
      } else {
        setFestivalHolidays([]);
        setOptionalFestivalHolidays([]);
        setOfficeHolidays([]);
        setOptionalOfficeHolidays([]);
      }
      setLoading((prev) => ({ ...prev, tableDataLoading: false }));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading((prev) => ({ ...prev, tableDataLoading: false }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSortClick = (sortTypeString) => {
    setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
    if (tabValue === Object.keys(HOLIDAYS)[0]) {
      if (officeHolidays?.length) {
        let holidays = officeHolidays.slice();
        holidays.sort(function (a, b) {
          if (sortTypeString === "asc")
            return new Date(b.date) - new Date(a.date);
          else return new Date(a.date) - new Date(b.date);
        });
        setOfficeHolidays(holidays);
      }
    } else {
      if (festivalHolidays?.length) {
        let holidays = festivalHolidays.slice();
        holidays.sort(function (a, b) {
          if (sortTypeString === "asc")
            return new Date(b.date) - new Date(a.date);
          else return new Date(a.date) - new Date(b.date);
        });
        setFestivalHolidays(holidays);
      }
    }
  };

  const getTabArray = (isOptional = false) => {
    if (isOptional)
      return tabValue === Object.keys(HOLIDAYS)[1]
        ? optionalFestivalHolidays
        : optionalOfficeHolidays;
    return tabValue === Object.keys(HOLIDAYS)[1]
      ? festivalHolidays
      : officeHolidays;
  };

  return loading.dateLoading ? (
    <CircularLoader />
  ) : (
    <Box width="100%" display="flex" flexDirection="column" overflow="hidden">
      <Box px={2} py={1} borderBottom="1px solid #E0E0E0" bgcolor="white">
        <Typography
          variant="h2"
          color="secondary"
          fontSize={18}
          fontWeight={600}
          sx={{ lineHeight: "27px", letterSpacing: "0.15px" }}
        >
          Holidays
        </Typography>
      </Box>
      <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" overflow="auto">
        <Stack spacing={3}>
          <Box container justifyContent="space-between" alignItems="center">
            <Grid Item width={265} maxWidth={265}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Select Year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedYear}
                  onChange={(e) => handleYearChange(e)}
                  label="Select Year"
                  sx={{
                    ".MuiSelect-select": {
                      fontSize: 14,
                      fontWeight: 400,
                      color: "dark.800",
                    },
                  }}
                >
                  {years?.map((yearData) => (
                    <MenuItem value={yearData}>
                      {isDashedYearBasedOnMonth(
                        holidayData?.renewal_month,
                        yearData?.year
                      )?.yearDashedFormat}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {Object.entries(HOLIDAYS)?.map((holiday, index) => (
                  <Tab label={holiday[1]} value={holiday[0]} />
                ))}
              </Tabs>
            </Box>
            <Box
              bgcolor="secondary"
              sx={{
                ".tabs-detail > .MuiBox-root": {
                  padding: "0px",
                },
              }}
            >
              {Object.keys(HOLIDAYS)?.map((holidayType, index) => (
                <Box>
                  <CustomTabPanel
                    value={tabValue}
                    index={holidayType}
                    className="tabs-detail"
                  >
                    <Box>
                      <Typography
                        mb={1}
                        color="dark.800"
                        fontWeight={500}
                        fontSize={16}
                        sx={{ lineHeight: "24px", letterSpacing: "0.17px" }}
                        key={index}
                      >{`${getTabArray(true)?.length || 0} ${HOLIDAYS[holidayType]
                        }s`}</Typography>
                      <Paper
                        sx={{
                          border: "1px solid #E0E0E0",
                          borderRadius: "3px",
                          boxShadow: 'none',
                        }}>
                        <TableContainer>
                          <Table>
                            <TableHead
                              sx={{
                                th: {
                                  color: "dark.800",
                                  fontWeight: 500,
                                  fontSize: 14,
                                  lineHeight: "24px",
                                  letterSpacing: "0.17px",
                                  padding: "5px 16px",
                                  textAlign: "left",
                                  background: "rgba(245, 248, 250, 1)",
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell sx={{
                                  width: '150px',
                                  maxWidth: '150px',
                                  minWidth: '150px',
                                }}>
                                  <TableSortLabel
                                    active={true}
                                    direction={sortType}
                                    onClick={() => handleSortClick(sortType)}
                                  >
                                    Date
                                  </TableSortLabel>
                                </TableCell>
                                {holidayType === Object.keys(HOLIDAYS)[1] ? (
                                  <TableCell sx={{
                                    width: '190px',
                                    maxWidth: '190px',
                                    minWidth: '190px',
                                  }}>Day</TableCell>
                                ) : null}
                                <TableCell>Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                "tr:hover": {
                                  background: "rgba(247, 247, 247, 1)",
                                },
                                td: {
                                  color: "dark.800",
                                  fontWeight: 400,
                                  fontSize: 14,
                                  lineHeight: "20px",
                                  letterSpacing: "0.17px",
                                  padding: "7px 16px",
                                  textAlign: "left",
                                  height: "45px",
                                },
                              }}
                            >
                              {loading.tableDataLoading ? (
                                <CircularLoader
                                  variant="table"
                                  rows={rowPerPage}
                                  cols={
                                    holidayType === Object.keys(HOLIDAYS)[1]
                                      ? 3
                                      : 2
                                  }
                                />
                              ) : getTabArray()?.length ? (
                                getTabArray()?.map((holidayData, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{getFormattedDate(holidayData?.date)}</TableCell>
                                    {holidayType ===
                                      Object.keys(HOLIDAYS)[1] ? (
                                      <TableCell>
                                        {getDayAndMonth(
                                          holidayData?.date,
                                          holidayData?.name,
                                          true
                                        )}
                                      </TableCell>
                                    ) : null}
                                    {holidayType ===
                                      Object.keys(HOLIDAYS)[1] ? (
                                      <TableCell>{titleCase(holidayData?.name)}</TableCell>
                                    ) : (
                                      <TableCell>
                                        {getDayAndMonth(holidayData?.date, holidayData?.name)}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow
                                  sx={{
                                    "&:hover": {
                                      background: "transparent !important",
                                    },
                                  }}
                                >
                                  <TableCell
                                    colSpan={
                                      holidayType === Object.keys(HOLIDAYS)[1]
                                        ? 3
                                        : 2
                                    }
                                    align="center"
                                    sx={{ padding: "12px 16px;", border: 'none !important', }}
                                  >
                                    <Typography
                                      color={"dark.800"}
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                      fontSize={14}
                                      sx={{ opacity: "1", height: "200px" }}
                                    >
                                      No records found.
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {getTabArray(true)?.length ? (
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={getTabArray(true)?.length}
                            rowsPerPage={rowPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        ) : null}
                      </Paper>
                    </Box>
                  </CustomTabPanel>
                </Box>
              ))}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Holidays;
