import { Box, Chip, Divider, IconButton, List, ListItemAvatar, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import React, { useState, useEffect, memo } from 'react';
import CustomBadge from '../Badge';
import BackgroundLetterAvatars from '../BackgroundLetterAvatars';
import { capitalOnlyFirstLatter, getApplicationNumber, titleCase } from '../../utils';
import { Visibility } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { addAllNotifications, addNotification, getAllNotifications, markNotificationAsRead, readAllNotification } from '../../redux/slices/notificationSlice';
import { useNavigate } from 'react-router-dom';
import URLS from '../../routes/urls';
import socket from '../../socket';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';

const NotificationPopup = memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.user);
    const { notifications, totalUnReadCount } = useSelector((state) => state?.notification);
    const [anchorElNotification, setAnchorElNotification] = useState(null);
    const [params, setParams] = useState({
        limit: 5,
        offSet: 0,
    });
    const [hasMore, setHasMore] = useState(true);

    const userId = currentUser?.id;

    useEffect(() => {
        if (!userId) return;

        socket.emit('joinRoom', userId);

        // Listen for 'notification' events
        socket.on('notification', (data) => {
            let res = data;
            if (res?.data?.length > 0 && res?.count > 0) {
                dispatch(addAllNotifications(res));
            } else if (!!res?.id) {
                dispatch(addNotification(res));
            }
        });

        // Handle connection errors
        socket.on('connect_error', (err) => {
            console.error('Connection error:', err);
        });

        // Handle connection success
        socket.on('connect', () => {
            console.log('Successfully connected to the server');
            // Join the user's specific room
            socket.emit('joinRoom', userId);
        });

        // Cleanup function to disconnect the socket when the component unmounts
        return () => {
            socket.off('notification');
            socket.off('connect_error');
            socket.off('connect');
            console.log('Socket event listeners removed');
        };
    }, [userId, dispatch]);


    const fetchMoreNotifications = () => {
        let updatedOffset = params.limit + params.offSet
        setParams((prev) => ({
            ...prev,
            offSet: prev.offSet + prev.limit,
        }));
        dispatch(getAllNotifications({ userId: currentUser?.id, limit: params.limit, offSet: updatedOffset }))
            .then((res) => {
                if (res.payload.count <= notifications?.length) {
                    setHasMore(false);
                }
            });
    };

    const handleViewNotification = (data) => {
        dispatch(markNotificationAsRead(data?.id));
        handleClose();
        // if (!data?.msg?.includes("deleted")) {
        if (data?.category === "leaves" && data?.sub_category === "my_leave") {
            navigate(`${URLS.ViewMyLeaveApplication}/${data?.application_user_id}/${data?.application_id}`);
        } else if (data?.category === "WFH" && data?.sub_category === "my_wfh") {
            navigate(`${URLS.ViewMyWfhApplication}/${data?.application_user_id}/${data?.application_id}`);
        } else if (data?.category === "availability_change" && data?.sub_category === "my_wfh") {
            navigate(`${URLS.ViewMyWfhApplication}/${data?.application_user_id}/${data?.application_id}`);
        } else if (data?.category === "availability_change" && data?.sub_category === "other_wfh") {
            navigate(`${URLS.OtherWfhApplicationDetails}/${data?.application_user_id}/${data?.application_id}`);
        } else if (data?.category === "leaves" && data?.sub_category === "other_leave") {
            navigate(`${URLS.OtherLeaveDetails}/${data?.application_user_id}/${data?.application_id}`);
        } else if (data?.category === "WFH" && data?.sub_category === "other_wfh") {
            navigate(`${URLS.OtherWfhApplicationDetails}/${data?.application_user_id}/${data?.application_id}`);
        }
        // }
    };

    const handleReadNotification = (data) => {
        dispatch(markNotificationAsRead(data?.id));
    };

    const handleClick = (event) => {
        setAnchorElNotification(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElNotification(null);
    };

    const handleClickViewAll = () => {
        setAnchorElNotification(null);
        navigate(`${URLS.AllNotifications}`);
    };

    const handleReadAll = () => {
        dispatch(readAllNotification(currentUser?.id));
        handleClose();
    };

    return (
        <div>
            <IconButton
                size="large"
                aria-label="show new notifications"
                
                sx={{ height: "44px", width: "44px", color: "#262626" }}
                onClick={handleClick}
            >
                <CustomBadge count={totalUnReadCount || 0} />
            </IconButton>
            <Popover
                open={Boolean(anchorElNotification)}
                anchorEl={anchorElNotification}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{
                    background: 'white', px: 2, py: 1,
                }}>
                    <Typography color="secondary" fontSize={16} fontWeight={600} lineHeight="22px" variant="h4">
                        Notifications
                    </Typography>
                    <Box>
                        {notifications?.length ?
                            <IconButton
                                color='primary'
                                sx={{
                                    width: "100%",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    p: 1,
                                    borderRadius: 1,
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    fontSize: 14,
                                    gap: 1,
                                }}
                                onClick={() => handleReadAll()}
                            >
                                {titleCase("Read All")}
                            </IconButton>
                            : null}
                    </Box>
                </Box>
                <Divider />
                <Box style={{ minWidth: '500px', maxWidth: '500px', overflow: "hidden" }}
                    sx={{
                        '.infinite-scroll-component': {
                            maxHeight: '440px',
                            'hr:last-child': {
                                border: 'none',
                            }
                        }
                    }}
                >
                    <InfiniteScroll
                        dataLength={notifications.length} // Total number of notifications loaded so far
                        next={fetchMoreNotifications}
                        hasMore={hasMore}
                        maxHeight={notifications.length > 0 ? '532px' : "300px"}
                        style={{
                            scrollBehavior: "smooth"
                        }}
                        endMessage={notifications.length === totalUnReadCount && notifications?.length > 0 ?
                            <Typography style={{ textAlign: 'center', margin: "20px" }}>
                                All notifications have been loaded.
                            </Typography> : null}
                    >
                        {notifications?.length ? notifications?.map((data, index) => (
                            <React.Fragment key={index}>
                                <List sx={{
                                    padding: '0',
                                    bgcolor: data?.isMarkRead ? "rgba(0, 0, 0, 0.04)" : "tranparant",
                                    cursor: 'pointer'
                                }}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        position: 'relative',
                                    }}>
                                        <Box sx={{
                                            width: "16px",
                                            height: "16px",
                                            position: 'absolute',
                                            top: 0,
                                            left: '2px',
                                            bottom: 0,
                                            margin: 'auto',
                                        }}>
                                            {!data?.read ?
                                                <IconButton onClick={() => handleReadNotification(data)} >
                                                    <Box sx={{
                                                        width: "6px",
                                                        height: "6px",
                                                        borderRadius: "50px",
                                                        backgroundColor: "primary.main",
                                                        alignSelf: 'center'
                                                    }}></Box>
                                                </IconButton>
                                                : <Box sx={{
                                                    width: "16px",
                                                    height: "16px",
                                                }}></Box>}
                                        </Box>
                                        <Box sx={{
                                            width: "100% !important"
                                        }}>
                                            <ListItemButton onClick={() => handleViewNotification(data)} alignItems="center" sx={{ padding: '14px 12px 14px 22px', }} >
                                                <ListItemAvatar>
                                                    <BackgroundLetterAvatars
                                                        sx={{
                                                            fontSize: '100%',
                                                        }}
                                                        user={{
                                                            name: data?.application_user?.name,
                                                            profile_img: data?.application_user?.profile_img
                                                        }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={capitalOnlyFirstLatter(data?.msg)}
                                                    primaryTypographyProps={{
                                                        fontSize: 14,
                                                        color: '#262626',
                                                        lineHeight: '21px',
                                                        fontWeight: '400',
                                                        marginBottom: '4px',
                                                    }}
                                                    secondary={moment(data?.createdAt).format("DD/MM/YYYY hh:mm A")}
                                                    secondaryTypographyProps={{
                                                        fontSize: 11,
                                                        color: 'secondary',
                                                        lineHeight: '13px',
                                                        fontWeight: '500',
                                                    }}
                                                    sx={{ m: 0, pr: 1.5, }}
                                                />
                                                {!!data?.application_num ?
                                                    <Chip
                                                        sx={{ alignSelf: 'center', marginRight: '0px', width: "auto" }}
                                                        label={getApplicationNumber(data?.application_num)}
                                                        color='secondary'
                                                        size="small"
                                                        variant='filled'
                                                    /> : null}
                                            </ListItemButton>
                                        </Box>
                                    </Box>
                                </List>
                                <Divider />
                            </React.Fragment>
                        )) : <Box
                            sx={{
                                m: 1,
                                textAlign: "center",
                            }}>
                            <Typography>No notification found.</Typography>
                        </Box>}
                    </InfiniteScroll>
                </Box>
                {notifications?.length ?
                    <IconButton sx={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        bgcolor: 'white',
                        borderRadius: 0,
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 15,
                        gap: 1,
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
                    }}
                        onClick={() => handleClickViewAll()}
                    >
                        <Visibility color="secondary" mr={1} sx={{ verticalAlign: 'top', width: '18px', height: '18px' }} />
                        <Typography color="#262626" fontWeight={400} fontSize={14} lineHeight="21px">View All</Typography>
                    </IconButton>
                    : null}
            </Popover>
        </div>
    )
});

export default NotificationPopup;
