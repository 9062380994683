import { Box, Grid } from '@mui/material';
import React from 'react'
import PublicFooter from '../../components/PublicFooter';
import ICONS from '../../constants/icons';
import { usePageTitle } from '../../hook/useTitle';

const TermsAndConditions = () => {
    const { setPageTitle } = usePageTitle();
    setPageTitle("Terms and Conditions");
    return (
        <Box
            sx={{ minHeight: "100vh", position: "relative", background: "#FAFBFF" }}
        >
            <Box
                sx={{
                    position: "absolute",
                    filter: "blur(18px)",
                    bottom: 0,
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <i style={{ height: "calc(100vh - 175px)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBg}</i>
            </Box>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                position="relative"
                zIndex="1"
                sx={{ minHeight: "100vh" }}
            >
                <Grid sx={{ flex: 1, display: "flex", paddingTop: "50px" }}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    // sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
                    >
                        <h1>Terms and Conditions</h1>
                    </Grid>
                </Grid>
                <PublicFooter />
            </Grid>
        </Box>
    )
}

export default TermsAndConditions;
