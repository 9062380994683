import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { getFullName } from '../../utils';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let backgroundColor = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        backgroundColor += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    const rgb = backgroundColor.substring(1).match(/.{2}/g).map(x => parseInt(x, 16));
    const brightness = (rgb[0] * 0.299) + (rgb[1] * 0.587) + (rgb[2] * 0.114);

    // Determine text backgroundColor based on brightness
    const color = (brightness > 180) ? '#000000' : '#ffffff'; // Black for lighter backgrounds, white for darker

    return { color, backgroundColor };
}

function stringAvatar(name) {
    let words = name?.split(" ");
    if (words.length === 1)
        return {
            children: `${words?.[0]?.[0]?.toUpperCase() || ""}`,
        }
    else
        return {
            children: `${words?.[0]?.[0]?.toUpperCase() || ""}${words?.[words?.length - 1]?.[0]?.toUpperCase() || ""}`,
        };
}

export default function BackgroundLetterAvatars({ user, src = null, sx, ...rest }) {
    return (!!user?.profile_img || !!user?.profileImage ?
        <Avatar
            alt={getFullName(user)}
            src={!!src ? src : (user?.profile_img || user?.profileImage)}
            sx={[{
                width: 40,
                height: 40,
                "img": {
                    objectFit: "cover",
                    maxHeight: '100%',
                    maxWidth: '100%',
                }
            }, sx]}
            {...rest}
        /> :
        <Avatar {...stringAvatar(getFullName(user))} sx={{
            fontSize: "90%",
            backgroundColor: stringToColor(getFullName(user)).backgroundColor,
            color: stringToColor(getFullName(user)).color,
            ...sx
        }} {...rest} />
    );
}