import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import Button from '../Button'

const EmptyPage = ({ isButtonShow = true, height = 250, buttontext = "Back", onClick, title = "No data found.", buttonVariant = "contained", buttonColor = "primary", buttonStyle = {} }) => {
    return (
        <Grid bgcolor="#F7F7F7" height={height} width={"100%"} display="flex" alignItems="center" justifyContent="center" p={4}>
            <Stack spacing={2.5} sx={{ textAlign: "center", alignItems: 'center' }}>
                <Typography fontSize={14} fontWeight={400} color="text.dark" width="100%" sx={{ lineHeight: '20px', letterSpacing: '0.17px' }}>
                    {title}
                </Typography>
                {isButtonShow ?
                    <Button
                        variant={buttonVariant}
                        color={buttonColor}
                        size="medium"
                        sx={{ textTransform: 'none', boxShadow: 'none !important', ...buttonStyle, }}
                        onClick={onClick}
                    >
                        {buttontext}
                    </Button>
                    : null}
            </Stack>
        </Grid>
    )
}

export default EmptyPage