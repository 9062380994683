import React from 'react'
import { titleCase } from '../../utils'
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';


const UserRoleChip = ({ label, size = "small", ...rest }) => {
    return (
        <Chip
            avatar={<Avatar ></Avatar>}
            label={titleCase(label) || "-"}
            size={size}
            {...rest}
            sx={{
                'svg':{
                    color: 'white'
                }
            }}
        />
    )
}

export default UserRoleChip