import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material'
import React from 'react'
import BackgroundLetterAvatars from '../BackgroundLetterAvatars'

const AutocompleteSelect = ({ options, value, onChange, onBlur, label, variant = "standard", name, id, ...rest }) => {
    return (
        <Autocomplete
            variant="standard"
            disablePortal
            options={options}
            renderInput={(params) =>
                <TextField
                    name
                    id
                    {...params}
                    label={label}
                    variant={variant}
                />}
            value={value}
            onChange={(e, newValue) => onChange({
                ...e,
                target: {
                    name: name,
                    value: newValue,
                }

            })}
            onBlur={onBlur}
            autoHighlight
            sx={{
                m: 0,
                "&:focus": {
                    boxShadow: "none",
                    outline: "none",
                },
                '.MuiInputBase-input': {
                    display: 'flex',
                    alignItems: 'center',
                    background: 'transparent !important',
                    outline: 'none !important',
                    boxShadow: 'none !important',
                    borderRadius: '0 !important',
                },
                '.MuiAvatar-circular': {
                    display: 'none',
                },
                '.MuiBox-root p': {
                    color: 'dark.800',
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                },
                '.MuiSelect-select .MuiBox-root': {
                    display: 'flex',
                    overflow: 'hidden',
                },
                '.MuiSelect-select .MuiBox-root p:not(:last-child)': {
                    marginRight: '5px'
                },
                '.MuiSelect-select .MuiBox-root p:last-child': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            }}
            getOptionLabel={(option) => `${option?.name} (${option?.email})`}
            renderOption={(props, user) => (
                <MenuItem
                    {...props}
                    value={user?.id}
                    sx={{ padding: "8px 12px", display: "flex" }}
                >
                    <BackgroundLetterAvatars
                        user={user}
                        sx={{
                            width: "32px",
                            height: "32px",
                            marginRight: "8px",
                            fontSize: "16px",
                            // background: "white",
                        }}
                    />
                    <Box>
                        <Typography
                            color="dark.800"
                            fontSize={15}
                            fontWeight={500}
                            lineHeight="24px"
                            letterSpacing="0.17px"
                        >
                            {user?.name}
                        </Typography>
                        <Typography
                            color="rgba(63, 92, 118, 0.9)"
                            fontSize={12}
                            fontWeight={400}
                            lineHeight="15px"
                        >
                            {user?.email}
                        </Typography>
                    </Box>
                </MenuItem>
            )}
            {...rest}
        />
    )
}

export default AutocompleteSelect