/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import styled from "@emotion/styled";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import CircularLoader from "../../../components/CircularLoader";
import useAuthentication from "../../../hook/useAuthentication";
import { usePageTitle } from "../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";
import { useAlert } from "../../../hook/useAlert";
import { PROFILE_IMAGE_SIZE, VALID_IMAGE_TYPE } from "../../../constants/default-values";
import { getMaxSize, titleCase } from "../../../utils";
import { setUserDetails } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { Link as RouteLink } from "react-router-dom";
import URLS from "../../../routes/urls";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const Profile = () => {
    const { getCurrentUser } = useAuthentication();
    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const showAlert = useAlert();
    const { setPageTitle } = usePageTitle();
    const [user, setUser] = useState({});
    const [userData, setUserData] = useState({});
    const [constantValues, setConstantValues] = useState({
        userRolesData: [],
        workTypeData: [],
        userTypeData: [],
    });
    const [isChanged, setIsChanged] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
    });
    const [optionalImage, setOptionalImage] = useState(null);

    setPageTitle("My Profile");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading((prev) => ({ ...prev, pageLoading: true }));
            const [
                userDataResponse,
                workTypesResponse,
                userTypesResponse,
                userRolesResponse,
            ] = await Promise.all([
                axiosInstance.get(API.fetchUserDetails(currentUser?.id)),
                axiosInstance.get(API.getWorkTypesByOrganisationId(currentUser?.organization_id)),
                axiosInstance.get(API.getUserTypes),
                axiosInstance.get(API.organisationUserRoles(currentUser?.organization_id)),
            ]);

            if (userDataResponse.status === 200) {
                setUserData(userDataResponse.data.data);
                setUser(userDataResponse.data.data);
                const previewProfileImage = userDataResponse.data.data?.profile_img;
                if (previewProfileImage) {
                    setPreviewImage(previewProfileImage);
                    setOptionalImage(previewProfileImage)
                }
            }

            if (userRolesResponse.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    userRolesData: userRolesResponse.data.data.map(item => ({
                        ...item,
                        isVisible: true,
                        isSelected: true,
                    })),
                }));
            }

            if (workTypesResponse.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    workTypeData: workTypesResponse.data.data,
                }));
            }

            if (userTypesResponse.status === 200) {
                setConstantValues((prev) => ({
                    ...prev,
                    userTypeData: userTypesResponse.data.data.map(item => ({
                        ...item,
                        isVisible: true,
                    })),
                }));
            }

            setLoading((prev) => ({ ...prev, pageLoading: false }));
        } catch (error) {
            console.error(error);
            setLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const handleChangeProfileImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (VALID_IMAGE_TYPE.includes(file.type)) {
                if (file.size <= PROFILE_IMAGE_SIZE) {
                    const previewURL = URL.createObjectURL(file);
                    setPreviewImage(previewURL);

                    setUserData((prev) => ({
                        ...prev,
                        profile_img: file
                    }));

                    setIsChanged((prev) => ({
                        ...prev,
                        profile_img: true
                    }));
                } else {
                    showAlert(`File size exceeds ${getMaxSize(PROFILE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
                    setUserData((prev) => ({
                        ...prev,
                        profile_img: optionalImage
                    }));
                }
            } else {
                showAlert('Only JPEG and PNG file types are allowed.', "error");
                setUserData((prev) => ({
                    ...prev,
                    profile_img: optionalImage
                }));
            }
        }
    };

    const handleRemoveProfileImage = () => {
        setUserData((prev) => ({
            ...prev,
            profile_img: null
        }));
        setIsChanged((prev) => ({ ...prev, is_remove_profile: true }));
        setPreviewImage(null);
    }

    const handleCancel = () => {
        setUserData((prev) => ({
            ...prev,
            profile_img: optionalImage
        }));
        setIsChanged((prev) => ({ is_remove_profile: false, profile_img: false }));
        setPreviewImage(optionalImage);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading((prev) => ({ ...prev, formSubmitting: true }));
            let formData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            if (isChanged?.profile_img && userData?.profile_img?.size > 0) {
                formData.append("profile_img", userData?.profile_img);
            }
            if (isChanged?.is_remove_profile) {
                formData.append("is_changed", user?.profile_img);
            }
            if (userData?.profile_img?.size > 0 && !!user?.profile_img && user?.profile_img !== userData?.profile_img) {
                formData.append("is_changed", user?.profile_img);
            }
            const response = await axiosInstance.put(API.updateUserProfile(currentUser?.id), formData, config);
            if (response.status === 200) {
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
                showAlert(response.data.message);
                let updatedUserData = {
                    ...currentUser,
                    name: userData?.name
                };
                if (!!response?.data?.profile_img) {
                    updatedUserData['profile_img'] = response?.data?.profile_img;
                } else if ((isChanged?.profile_img || isChanged?.is_remove_profile) && response?.data?.profile_img === null) {
                    updatedUserData['profile_img'] = null;
                }
                setOptionalImage(previewImage);
                setUserData(updatedUserData);
                setUser(updatedUserData);
                dispatch(setUserDetails({ user: updatedUserData }));
                setIsChanged((prev) => ({ is_remove_profile: false, profile_img: false }));
            } else {
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
                setIsChanged((prev) => ({ ...prev, profile_img: false }));
                showAlert(response?.response?.data?.message, "error");
            }
        } catch (error) {
            console.error(error);
            showAlert(error?.response?.data?.message || "Something went wrong", "error");
            setLoading((prev) => ({ ...prev, formSubmitting: false }));
        }
    }

    const getWorkType = (id) => {
        return constantValues?.workTypeData?.find((item) => item?.id === id)
    }

    const getUserType = (id) => {
        return constantValues?.userTypeData?.find((item) => item?.id === id)
    }

    const getUserRole = (id) => {
        return constantValues?.userRolesData?.find((item) => item?.id === id)
    }

    return (
        <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
            <Box px={2} py={1} borderBottom="1px solid #E0E0E0" bgcolor="white">
                <Typography
                    variant="h2"
                    color="dark.800"
                    fontSize={20}
                    fontWeight={400}
                    sx={{ lineHeight: "28px", letterSpacing: "0.15px" }}
                >
                    My Profile
                </Typography>
            </Box>
            <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" flex={1} overflow="auto">
                <Stack spacing={3}>
                    <Box container justifyContent="space-between" alignItems="center">
                        {loading.pageLoading ? <CircularLoader height="500px" /> :
                            <form className="step-form-1">
                                <Grid mb={4}>
                                    <Grid display="flex" alignItems="center">
                                        <Box
                                            mr={2.5}
                                            sx={{
                                                height: "64px",
                                                width: "64px",
                                                background: "rgba(63, 92, 118, 0.09)",
                                                borderRadius: "50%",
                                                overflow: "hidden",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Box
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                sx={{ height: "100%", width: "100%" }}
                                            >
                                                <BackgroundLetterAvatars
                                                    user={userData}
                                                    src={previewImage}
                                                    sx={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: 0,
                                                        fontSize: "135%",
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: 1,
                                        }}>
                                            {previewImage === optionalImage ? <Button
                                                component="label"
                                                variant="text"
                                                color="primary"
                                                fontSize={13}
                                                sx={{ textTransform: "none" }}
                                            >
                                                Change
                                                <VisuallyHiddenInput
                                                    name="profile_img"
                                                    type="file"
                                                    onChange={handleChangeProfileImage}
                                                    accept="image/*"
                                                />
                                            </Button> : null}
                                            {isChanged?.profile_img || isChanged?.is_remove_profile ?
                                                <Button
                                                    component="label"
                                                    color="primary"
                                                    fontSize={13}
                                                    sx={{ textTransform: "none", color: "white" }}
                                                    onClick={handleSubmit}
                                                    isButtonLoading={loading?.formSubmitting}
                                                >
                                                    Update
                                                </Button> :
                                                previewImage
                                                    ? <Button
                                                        component="label"
                                                        variant="text"
                                                        color="error"
                                                        fontSize={13}
                                                        sx={{ textTransform: "none" }}
                                                        onClick={handleRemoveProfileImage}
                                                    >
                                                        Remove
                                                    </Button> : null}
                                            {isChanged?.profile_img || isChanged?.is_remove_profile ? <Button
                                                component="label"
                                                variant="text"
                                                color="error"
                                                fontSize={13}
                                                sx={{ textTransform: "none" }}
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button> : null}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid mb={4}>
                                    <Typography color="secondary.main" fontSize={18} fontWeight={500} lineHeight="27px" mb="2px">{titleCase(userData?.name)}</Typography>
                                    <Typography color="secondary.main" fontSize={12} fontWeight={500} lineHeight="18px" variant="span" display="block">{userData?.email}</Typography>
                                </Grid>
                                <Grid container width={780} maxWidth="100%">
                                    <Grid Item pr={2} sm={4} mb={4}>
                                        <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>User Type</Typography>
                                        <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{titleCase(getUserType(userData?.current_user_type_id)?.name)}</Typography>
                                    </Grid>
                                    <Grid Item pr={2} sm={4} mb={4}>
                                        <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>User Role</Typography>
                                        <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{getUserRole(userData?.current_user_role_id)?.name}</Typography>
                                    </Grid>
                                    <Grid Item pr={2} sm={4} mb={4}>
                                        <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Work Type</Typography>
                                        <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{getWorkType(userData?.org_work_type_setting_id)?.name}</Typography>
                                    </Grid>
                                    {userData?.is_dedicated_developer ?
                                        <Grid Item pr={2} sm={4} mb={4}>
                                            <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Dedicated Developer?</Typography>
                                            <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{userData?.is_dedicated_developer ? "Yes" : "No"}</Typography>
                                        </Grid> : null}
                                    {userData?.is_admin ?
                                        <Grid Item pr={2} sm={4} mb={4}>
                                            <Typography color="dark.500" fontSize={12} fontWeight={500} lineHeight="18px" letterSpacing="0.17px" mb={1}>Access to Admin Panel</Typography>
                                            <Typography color="dark.800" fontSize={16} fontWeight={500} lineHeight="16px" letterSpacing="0.17px" display="block" variant='span'>{userData?.is_admin ? "Yes" : "No"}</Typography>
                                        </Grid> : null}
                                </Grid>
                                <Link
                                    component={RouteLink}
                                    to={URLS.ChangePassword}
                                    color="primary"
                                    variant='subtitle2'
                                    sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                                >
                                    Change Password?
                                </Link>
                            </form>
                        }
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};

export default Profile;
