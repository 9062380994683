import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import URLS from '../../routes/urls'
import { usePageTitle } from '../../hook/useTitle'

const AccessDenied = () => {
    const navigate = useNavigate();
    const { setPageTitle } = usePageTitle();
    setPageTitle("Access Denied");

    return (
        <Grid bgcolor="lightGray" height={250} width={"100%"} display="flex" alignItems="center" justifyContent="center" p={4}>
            <Stack spacing={2.5} sx={{ textAlign: "center", alignItems: 'center' }}>
                <Typography fontSize={25} fontWeight={"bold"} color="text.dark" width="100%" sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                    Access Denied
                </Typography>
                <Typography fontSize={16} fontWeight={400} color="text.dark" width="100%" sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>
                    You don't have the necessary permissions to view this page.
                </Typography>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size="medium"
                    sx={{ textTransform: 'none', boxShadow: 'none !important', color: "white" }}
                    onClick={() => navigate({ pathname: URLS.Dashboard }, {
                        replace: true
                    })}>
                    Home
                </Button>
            </Stack>
        </Grid>
    )
}

export default AccessDenied;
