import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import URLS from '../../routes/urls';

let data = [];

const initialState = {
    notifications: [...data],
    totalUnReadCount: data.filter((value) => value.read).length || 0,
    notificationLoading: true,
};

export const markNotificationAsRead = createAsyncThunk(
    'notification/markNotificationAsRead',
    async (notificationId, thunkAPI) => {
        try {
            const response = await axiosInstance.put(API.readNotification(notificationId));
            if (response.status === 200) {
                return { id: notificationId, is_read: true };
            } else {
                return { id: notificationId, is_read: false };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const readAllNotification = createAsyncThunk(
    'notification/readAllNotification',
    async (userId, thunkAPI) => {
        try {
            const response = await axiosInstance.put(`${API.readNotification(0)}`);
            if (response.status === 200) {
                return { is_read: true };
            } else {
                return { is_read: true };
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAllNotifications = createAsyncThunk(
    'notification/getAllNotifications',
    async ({ userId, limit, offSet }, thunkAPI) => {
        try {
            const response = await axiosInstance.get(API.getAllNotifications(userId, limit, offSet));
            return response?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification: (state, action) => {
            let updatedNotification = state.notifications?.map((value) => value);
            updatedNotification.unshift(action.payload);
            const uniqueNotifications = Array.from(new Map(updatedNotification.map(item => [item.id, item])).values());
            state.totalUnReadCount += 1;
            state.notifications = uniqueNotifications;
        },
        addAllNotifications: (state, action) => {
            if (window.location.pathname !== URLS.AllNotifications) {
                state.totalUnReadCount = action.payload?.count;
                state.notifications = action.payload?.data;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(markNotificationAsRead.fulfilled, (state, action) => {
            const id = action.payload.id;
            const is_read = action.payload.is_read;
            if (is_read) {
                let updatedData = state.notifications.map(proxy => {
                    return { ...proxy, read: proxy?.id === id ? true : proxy.read };
                });
                state.notifications = updatedData.filter((value) => !value.read);
                state.totalUnReadCount -= 1;
            }

        });
        builder.addCase(readAllNotification.fulfilled, (state, action) => {
            if (action.payload.is_read) {
                state.notifications = [];
                state.totalUnReadCount = 0;
            }
        });
        builder.addCase(getAllNotifications.fulfilled, (state, action) => {
            let allData = state.notifications.map(proxy => {
                return { ...proxy, };
            });
            let uniqueNotifications = Array.from(new Map([...allData, ...action?.payload?.data].map(item => [item.id, item])).values());
            state.notifications = uniqueNotifications;
            state.totalUnReadCount = action.payload.count;
            state.notificationLoading = false;
        })
        builder.addCase(getAllNotifications.rejected, (state, action) => {
            state.notificationLoading = false;
        });
    },
});

export const { addNotification, addAllNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
