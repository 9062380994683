import React from 'react';
import { Switch as MUISwitch } from '@mui/material';

const Switch = ({ name, id, label, checked, onChange, size = 'small', disabled = false, ...rest }) => {
    return (
        <MUISwitch
            name={name}
            id={id}
            size={size}
            checked={checked}
            onChange={onChange}
            color="primary"
            inputProps={{ 'aria-label': label }}
            disabled={disabled}
            {...rest}
        />
    );
};

export default Switch;
