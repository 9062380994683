import moment from "moment";
import React, { useEffect, useRef } from "react";
import MultiDatePicker from "react-multi-date-picker";
import { isDashedYearBasedOnMonth } from "../../utils";
import { WEEKDAYS } from "../../constants/default-values";
import { useSelector } from "react-redux";
import useAuthentication from "../../hook/useAuthentication";

const SingleDatePicker = ({
  value,
  onChange,
  ignoreDates = [],
  isMultiple = false,
  disabled = false,
  className,
  onOpen,
  renewal_month = null,
  year = null,
  isOnlyAllowCurrentYearDates = false,
  style,
  extraDisableDates = [],
  ...rest
}) => {
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();

  const { ignoreLeaveDates } = useSelector((state) => state?.leaveData);
  let ignoreDatesArray = [];
  if (ignoreDates?.length) {
    ignoreDatesArray = ignoreDates;
  } else {
    ignoreDatesArray = ignoreLeaveDates;
  }
  if (extraDisableDates?.length) {
    ignoreDatesArray = Array.from(new Set([...extraDisableDates, ...ignoreLeaveDates]));
  }
  const inputRef = useRef(null);
  useEffect(() => {
    const handleKeyPress = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keypress', handleKeyPress);
      inputElement.addEventListener('keydown', handleKeyPress);
      inputElement.addEventListener('keyup', handleKeyPress);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keypress', handleKeyPress);
        inputElement.removeEventListener('keydown', handleKeyPress);
        inputElement.removeEventListener('keyup', handleKeyPress);
      }
    };
  }, []);

  return (
    <>
      <MultiDatePicker
        style={style}
        ref={inputRef}
        onOpen={onOpen}
        disabled={disabled}
        value={value}
        onChange={onChange}
        multiple={isMultiple}
        format="DD/MM/YYYY"
        weekDays={WEEKDAYS}
        headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
        mapDays={({ date }) => {
          // let isPastDate = moment(new Date(date)).add(1, 'd').isBefore(new Date());
          let isPastDate = moment(new Date(date)).isBefore(currentUser?.organization_created_date || "01/01/2024");

          let isIgnoredDate = ignoreDatesArray?.some((ignoreDate) => {
            let date1 = moment(ignoreDate, 'DD/MM/YYYY').format("DD/MM/YYYY");
            let date2 = moment(new Date(date)).format("DD/MM/YYYY");
            return date1 === date2;
          })
          if (isOnlyAllowCurrentYearDates) {
            let isPastDate = moment(new Date(date)).isBefore(new Date(isDashedYearBasedOnMonth(renewal_month, year, "MM/DD/YYYY")?.setting_start_date));
            let isFutureDate = moment(new Date(date)).subtract("d", 1).isAfter(new Date(isDashedYearBasedOnMonth(renewal_month, year, "MM/DD/YYYY")?.setting_end_date));
            if (isPastDate || isFutureDate || isIgnoredDate)
              return {
                disabled: true,
                style: { color: "#ccc" },
              };
          } else {
            if (isIgnoredDate || isPastDate) {
              return {
                disabled: true,
                style: { color: "#ccc" },
              };
            }
          }
        }}
        {...rest}
        className={"test-test"}
      />
    </>
  );
};

export default SingleDatePicker;
