import { DISPOSABLE_DOMAIN } from "../constants/default-values";

export const titleCase = (str, key = null) => {
  const txt = str?.replace(/_/g, " ")?.replace(/([A-Z])/g, "$1");
  const text = txt
    ?.split(" ")
    ?.map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
  if (key === "And") {
    return text.replace("And", "&");
  }
  return text;
};

function isDisposableEmail(email) {
  const domain = email.split('@')[1];
  return !DISPOSABLE_DOMAIN.includes(domain);
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email))
    return isDisposableEmail(email)
  else return false;
};

export const requireField = (
  value,
  fieldName,
  errorMsg = "",
  checkemail = true
) => {
  if (fieldName === "email" && checkemail) {
    if (!value?.trim())
      return errorMsg
        ? errorMsg
        : `Please enter ${titleCase(fieldName)} address`;
    else if (!validateEmail(value?.trim()))
      return errorMsg ? errorMsg : `Invalid email address`;
  } else if (fieldName === "name") {
    if (!value?.trim()) return errorMsg ? errorMsg : `Required`;
    else if (value === "exists")
      return errorMsg
        ? errorMsg
        : `Organisation name is aleady exist, choose another name.`;
  } else {
    if (!value?.toString()?.trim()?.length)
      return errorMsg ? errorMsg : `Required`;
  }
};

export const validateTLD = () => {
  return test('is-valid-tld', 'Invalid TLD', (value) => {
    if (!value)
      return true;

    const allTLDs = [
      'com', 'net', 'org', 'edu', 'gov', 'mil', 'int', 'aero', 'biz', 'coop',
      'info', 'museum', 'name', 'pro', 'jobs', 'mobi', 'travel', 'asia', 'cat',
      'coop', 'museum', 'tel', 'arpa', 'root', 'eu', 'us', 'uk', 'ca', 'au', 'nz',
    ];
    const subdomainParts = value.split('.');

    return allTLDs.includes(subdomainParts[subdomainParts.length - 1].toLowerCase());
  })
}

export function validateTime(userDateTime, validationDateTime) {
  function parseDateTime(dateTimeStr) {
    const [datePart, timePart] = dateTimeStr.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [time, modifier] = timePart.split(' ');

    let [hours, minutes] = time.split(':').map(Number);
    if (modifier?.toLowerCase() === 'pm' && hours !== 12) {
      hours += 12;
    }
    if (modifier?.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }

    return new Date(year, month - 1, day, hours, minutes);
  }

  const userDate = parseDateTime(userDateTime);
  const validationDate = parseDateTime(validationDateTime);

  return userDate.getTime() > validationDate.getTime();
}
